import React from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";

function AddRelaunchMission({
  setAddRelaunchMissionModalFromChild,
  getRelaunchMissionByMissionId,
  mission,
}) {
  const [title, setTitle] = React.useState();
  const [date, setDate] = React.useState();
  const [description, setDescription] = React.useState();

  const initFormErrors = {
    title: [""],
    date: [""],
    description: [""],
  };
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  function sendData() {
    axios
      .post(`${baseURL}/relaunchMission/create/${mission}`, {
        title: title,
        date: date,
        description: description,
        mission: mission,
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getRelaunchMissionByMissionId();
        setAddRelaunchMissionModalFromChild("");
      })
      .catch((error) => {
        console.log(error);
        setFormErrors(error.response.data);
      });
  }
  return (
    <div>
      <div
        className="modal fade show"
        id="relaunchMision_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row mb-3 col-md-12">
                <label for="title" className="col-md-3 col-form-label">
                  Titre
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Titre"
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.title}</span>
                  </div>
                </div>
              </div>
              <div className="row mb-3 col-md-12">
                <label for="date" className="col-md-3 col-form-label">
                  Date
                </label>
                <div className="col-md-9">
                  <input
                    type="date"
                    className="form-control"
                    id="date"
                    placeholder="Date"
                    onChange={(e) => setDate(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.date}</span>
                  </div>
                </div>
              </div>
              <div className="row mb-3 col-md-12">
                <label for="description" className="col-md-3 col-form-label">
                  Description
                </label>
                <div className="col-md-9">
                  <textarea
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder="Description"
                    rows="4"
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {formerrors.description}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setAddRelaunchMissionModalFromChild("")}
              >
                Annuler
              </button>
              <button
                type="button"
                onClick={sendData}
                className="btn btn-primary"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default AddRelaunchMission;
