import React, { useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import AddRole from "../../components/role/addRole";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { baseURL } from "../../config/config";

const CreateUser = () => {
  const [roles, setRoles] = React.useState();
  const [addRoleModal, setaddRoleModal] = React.useState();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [role, setRole] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [avatar, setAvatar] = React.useState();
  const [socialSecurityNumber, setSocialSecurityNumber] = React.useState("");

  const history = useHistory();

  const initFormErrors = {
    name: [""],
    email: [""],
    phone: [""],
    password: [""],
    confirmPassword: [""],
    address: [""],
    socialSecurityNumber: [""],
  };
  const [formerrors, setFormErrors] = React.useState(initFormErrors);

  function getRoles() {
    axios.get(`${baseURL}/roles`).then((resp) => {
      if (resp.data.error) return;
      setRoles(resp.data.data);
    });
  }

  useEffect(() => {
    getRoles();
  }, []);

  function sendData() {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("avatar", avatar);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("role_id", role);
    formData.append("phone", phone);
    formData.append("password", password);
    formData.append("confirm_password", confirmPassword);
    formData.append("nirpp", socialSecurityNumber);

    axios
      .post(`${baseURL}/users/create`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setTimeout(() => {
          history.push("/users");
        }, 2400);
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }

  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Ajouter un Utilisateur</h1>
          </div>
          <div className="col-md-4">
            <Link to="/users">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2"
              >
                Retour
              </button>
            </Link>
          </div>
        </div>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"}>
                <i className="bi bi-house-door"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/users"}>Utilisateurs</Link>
            </li>
            <li className="breadcrumb-item active">Ajouter un Utilisateur</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12"></div>
          <div className="card">
            <div className="card-body pt-3">
              <form className="row g-3">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nom"
                    onChange={(e) => setName(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.name}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Numéro de securité social "
                    onChange={(e) => setSocialSecurityNumber(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.nirpp}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group mb-3">
                    <select
                      id="inputState"
                      className="form-select"
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <option selected>Sectionner un rôle ...</option>
                      {roles != null &&
                        roles.map((role, key) => {
                          return <option value={role.id}>{role.name}</option>;
                        })}
                    </select>
                    <span className="input-group-text p-0" id="basic-addon1">
                      <button
                        type="button"
                        className="btn btn-"
                        onClick={() =>
                          setaddRoleModal(
                            <AddRole
                              setAddRoleModalFromChild={setaddRoleModal}
                              setRolesList={setRoles}
                            />
                          )
                        }
                      >
                        <i className="bi bi-plus-lg"></i>
                      </button>
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Adresse"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.address}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Téléphone"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.phone}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.email}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Mot de Passe"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.password}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirmation Mot de Passe"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {formerrors.confirm_password}
                    </span>
                  </div>
                </div>

                <div className="col-md-4">
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setAvatar(e.target.files[0])}
                  />
                </div>

                <div className="text-end">
                  <Link to="/users">
                    <button
                      type="button"
                      className="btn btn-outline-secondary  me-2"
                    >
                      Annuler
                    </button>
                  </Link>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={sendData}
                  >
                    Enregistrer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {addRoleModal}
      </section>
    </div>
  );
};
export default CreateUser;
