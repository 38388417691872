import React from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";

function EditMission({
  setAddMissionModalFromChild,
  managers,
  missionId,
  getMissionByCompanyId,
  specification,
}) {
  const [title, setTitle] = React.useState();
  const [description, setDescription] = React.useState();
  const [period, setPeriod] = React.useState();
  const [startDate, setStartDate] = React.useState();
  const [manager, setManager] = React.useState();
  const [company, setcompany] = React.useState();
  const [companies, setcompanies] = React.useState();
  const [allmanagers, setallmanagers] = React.useState(managers);
  const initFormErrors = {
    title: [""],
    description: [""],
    period: [""],
    startDate: [""],
    manager: [""],
    company: [""],
  };
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  async function getMissionById() {
    axios.get(`${baseURL}/missions/${missionId}`).then((resp) => {
      if (resp.data.error) return;
      const data = resp.data.data;
      setTitle(data.title);
      setDescription(data.description);
      setPeriod(data.period);
      setStartDate(data.startDate);
      setManager(data.manager);
      setcompany(data.company);
      if (specification) {
        getallManagerByCompanyId(company.id);
      }
    });
  }
  async function getallManagerByCompanyId(companyId) {
    if (companyId === undefined) return;
    axios
      .get(`${baseURL}/managers/${companyId}`)
      .then((response) => {
        if (response.data.error) return;
        setallmanagers(response.data.data);
      })
      .catch((err) => {});
  }
  async function getAllCompany() {
    axios.get(`${baseURL}/companies/`).then((response) => {
      if (response.data.error) return;
      setcompanies(response.data.data);
    });
  }
  React.useEffect(() => {
    getMissionById();
    getAllCompany();
  }, [missionId]);
  React.useEffect(() => {
    getallManagerByCompanyId(company);
  }, [company]);

  function sendData() {
    let data = [];
    if (!specification) {
      data = {
        title: title,
        description: description,
        period: period,
        startDate: startDate,
        manager: manager,
      };
    } else {
      data = {
        title: title,
        description: description,
        period: period,
        startDate: startDate,
        manager: manager,
        company: company,
      };
    }

    axios
      .put(`${baseURL}/missions/${missionId}`, {
        data,
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getMissionByCompanyId();
        setAddMissionModalFromChild("");
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }

  return (
    <div>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col-md-12 mt-2">
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  placeholder="Titre"
                  value={title != null && title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.title}</span>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  placeholder="Description"
                  value={description != null && description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.description}</span>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <input
                  type="text"
                  className="form-control"
                  id="period"
                  placeholder="Periode de travail"
                  value={period != null && period}
                  onChange={(e) => setPeriod(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.period}</span>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <input
                  type="date"
                  className="form-control"
                  id="startDate"
                  placeholder="Date début de mission"
                  value={startDate != null && startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.startDate}</span>
                </div>
              </div>
              {specification ? (
                <div className="col-md-12 mt-2">
                  <select
                    id="companyId"
                    value={company != null && company}
                    onChange={(e) => {
                      setcompany(e.target.value);
                      setManager(null);
                    }}
                    className="form-select"
                  >
                    <option>Sélectionner un company</option>
                    {companies != null &&
                      companies.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {`${item.name} /${item.owner}`}
                          </option>
                        );
                      })}
                  </select>
                  <div className="input-error">
                    <span className="text-danger">{formerrors.company}</span>
                  </div>
                </div>
              ) : null}

              <div className="col-md-12 mt-2">
                {console.log(manager)}
                <select
                  id="managerId"
                  value={manager != null && manager._id}
                  onChange={(e) => {
                    setManager(e.target.value);
                  }}
                  className="form-select"
                >
                  <option selected>Sélectionner un résponsable</option>
                  {managers != null &&
                    managers.map((item, index) => {
                      return (
                        <option key={index} value={item._id}>
                          {`${item.fname} ${item.lname}`}
                        </option>
                      );
                    })}
                </select>
                <div className="input-error">
                  <span className="text-danger">{formerrors.manager}</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setAddMissionModalFromChild("")}
              >
                Annuler
              </button>
              <button
                type="button"
                onClick={sendData}
                className="btn btn-primary"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default EditMission;
