import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { SupplierContext } from "../../pages/supplier/CreateSupplier"; 
import SupplierFormStep3 from "./SupplierFormStep3";
import ConfirmSupplier from "./ConfirmSupplier";
import CreateCompanyComponent from "../supplier_component/CreateCompanyComponent";
import { baseURL } from "../../config/config";
const initFormErrors = {
  job: [""],
  salary: [""],
  typeOfContract: [""],
  startDate: [""],
  companyname:[""],
};
const SupplierFormStep4 = ({ setChildComponent }) => {
  const msg = "Ce champ est obligatoire";
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const { newSupplier, setNewSupplier } = useContext(SupplierContext);
   const [addCompanyModal, setaddCompanyModal] = React.useState();
const [company, setCompany] = React.useState();

 function getCompany() {
   axios.get(`${baseURL}/companies`).then((resp) => {
     if (resp.data.error) return;
     setCompany(resp.data.data);
   });
 }
  useEffect(() => {
    getCompany();
  }, []);


  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
    if (newSupplier.job == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        job: msg,
      }));
    }
    if (newSupplier.salary == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        salary: msg,
      }));
    }
    if (newSupplier.typeOfContract == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        typeOfContract: msg,
      }));
    }
    if (newSupplier.startDate == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        startDate: msg,
      }));
    }
     if (newSupplier.companyname == "") {
       error = true;
       setErrorMessage((prevState) => ({
         ...prevState,
         companyname: msg,
       }));
     }

    if (error === false) {
      setChildComponent(<ConfirmSupplier setChildComponent={setChildComponent} />);
    }
  }
  return (
    <div>
      <div className="card">
        <div className="card-body pt-5">
          <form className="row g-3">
            <div className="row">
              <div className="col-md-6 pb-3">
                <label>Poste *</label>
                <input
                  type="text"
                  value={newSupplier.job}
                  onChange={(e) => {
                    setNewSupplier((prevState) => ({
                      ...prevState,
                      job: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.job}</span>
                </div>
              </div>

              <div className="col-md-6 pb-3">
                <label>Salaire *</label>
                <input
                  type="number"
                  value={newSupplier.salary}
                  onChange={(e) => {
                    setNewSupplier((prevState) => ({
                      ...prevState,
                      salary: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.salary}</span>
                </div>
              </div>

              <div className="col-md-6">
                <label>Type de contrat *</label>
                <select
                  className="form-select"
                  value={newSupplier.typeOfContract}
                  onChange={(e) => {
                    setNewSupplier((prevState) => ({
                      ...prevState,
                      typeOfContract: e.target.value,
                    }));
                  }}
                  name="typeOfContract"
                >
                  <option value="" selected>
                    Sélectionner le type de contrat
                  </option>
                  <option value="cdd">CDD</option>
                  <option value="cdi">CDI</option>
                  <option value="internship">INTERNSHIP</option>
                </select>

                <div className="input-error">
                  <span className="text-danger">
                    {errorMessage.typeOfContract}
                  </span>
                </div>
              </div>

              <div className="col-md-6 pb-3">
                <div className="box">
                  <div className="date-picker">
                    <label>Date debut de travail *</label>
                    <input
                      value={newSupplier.startDate}
                      className="form-control"
                      onChange={(e) => {
                        setNewSupplier((prevState) => ({
                          ...prevState,
                          startDate: e.target.value,
                        }));
                      }}
                      type="date"
                      id="datePicker"
                      required
                    />
                  </div>
                </div>
                <div className="input-error">
                  <span className="text-danger"> {errorMessage.startDate}</span>
                </div>
              </div>
              <div className="col-md-6 ">
                <label>Nom du société *</label>
                <div className="input-group mb-3">
                  <select
                    id="inputState"
                    onChange={(e) => {
                      const selectedCompany = JSON.parse(e.target.value);
                      setNewSupplier((prevState) => ({
                        ...prevState,
                        company: selectedCompany.id,
                        companyname: selectedCompany.name,
                      }));
                    }}
                    className="form-select"
                  >
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.type}</span>
                    </div>
                    <option selected></option>
                    {company != null &&
                      company.map((company, key) => {
                        return (
                          <option value={JSON.stringify(company)}>
                            {company.name}
                          </option>
                        );
                      })}
                  </select>
                  <span className="input-group-text p-0" id="basic-addon1">
                    <button
                      type="button"
                      className="btn btn-"
                      onClick={() =>
                        setaddCompanyModal(
                          <CreateCompanyComponent
                            setAddCompanyModalFromChild={setaddCompanyModal}
                          />
                        )
                      }
                    >
                      <i className="bi bi-plus-lg"></i>
                    </button>
                  </span>
                </div>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.company}</span>
                </div>
              </div>
            </div>
            <div className="text-end">
              <button
                type="button"
                className="btn btn-outline-secondary  me-2"
                onClick={() => {
                  setChildComponent(
                    <SupplierFormStep3 setChildComponent={setChildComponent} />
                  );
                }}
              >
                Retour
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={validate}
              >
                Continuer
              </button>
            </div>
          </form>
          {addCompanyModal}
        </div>
      </div>
    </div>
  );
};

export default SupplierFormStep4;
