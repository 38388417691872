import axios from "axios";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { baseURL } from "../../config/config";
import DataTable from "react-data-table-component";
import EmptyData from "../../components/emptyData";
import swal from "sweetalert";
import AddRelaunchMission from "../../components/relaunchMission/createRelaunchMission";
import EditRelaunchMission from "../../components/relaunchMission/editRelaunchMission";
import CreateProcessMission from "../../components/mission/createProcessMission";
import "./detailsMission.css";
import MissionEmployees from "../../components/mission/employees";
import { useHistory } from "react-router";
const MissionDetails = () => {
  const history = useHistory();
  const [mission, setMission] = React.useState();
  const [relaunchMission, setRelaunchMission] = React.useState([]);
  const [relaunchMissionData, setRelaunchMissionData] = React.useState();
  const [addProcessMissionModal, setaddProcessMissionModal] = React.useState();
  const [addRelaunchMissionModal, setAddRelaunchMissionModal] =
    React.useState();

  const { missionId } = useParams();
  async function getMissionById() {
    if (missionId === undefined) return;
    axios.get(`${baseURL}/missions/${missionId}`).then((response) => {
      if (response.data.error) return;
      setMission(response.data.data);
    });
  }

  useEffect(() => {
    getMissionById();
    getRelaunchMissionByMissionId();
  }, []);

  async function getRelaunchMissionByMissionId() {
    axios
      .get(`${baseURL}/relaunchMission/relaunchMissions/${missionId}`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setRelaunchMission(respData);
      });
  }

  async function enableDisableRelaunchMission(relaunchMissionId, isEnabled) {
    let message =
      isEnabled == "yes"
        ? "voulez vous Annuler ce rappel?"
        : "Voulez vous Valider ce rappel?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(
            `${baseURL}/relaunchMission/toggleStatus/${relaunchMissionId}`,
            {}
          )
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getRelaunchMissionByMissionId();
            }
          });
      }
    });
  }

  const columnsRelaunchMission = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "5%",
      sortable: true,
    },
    {
      name: "Titre",
      selector: (row) => <div className="col-12">{row.title}</div>,
      width: "25%",
      sortable: true,
    },
    {
      name: "Description",
      width: "25%",
      cell: (row) => (
        <div>
          <div className="text">{row.description}</div>
        </div>
      ),
    },
    {
      name: "Date",
      selector: (row) => row.date,
      width: "25%",
      sortable: true,
    },
    {
      name: "Actions",
      width: "19%",
      cell: (row) => (
        <div className="row col-12">
          <button
            className="col-3 btn text-primary me-2"
            onClick={() =>
              setAddRelaunchMissionModal(
                <EditRelaunchMission
                  setAddRelaunchMissionModalFromChild={
                    setAddRelaunchMissionModal
                  }
                  getRelaunchMissionByMissionId={getRelaunchMissionByMissionId}
                  relaunchMissionId={row._id}
                />
              )
            }
          >
            <i className="bi bi-pen"></i>
          </button>
          <button
            className="col-3 btn text-primary me-2 btn"
            onClick={() => {
              enableDisableRelaunchMission(row._id, row.isEnabled);
            }}
          >
            {row.isEnabled == "yes" ? (
              <i className="text-success bi bi-check2-square"></i>
            ) : (
              <i className="text-danger bi bi-x-square"></i>
            )}
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (relaunchMission.length > 0) {
      setRelaunchMissionData(
        <DataTable
          columns={columnsRelaunchMission}
          data={relaunchMission}
          pagination
        />
      );
    } else {
      setRelaunchMissionData(<EmptyData />);
    }
  }, [relaunchMission]);

  return (
    <div>
      <div className="pagetitle row">
        <div className="row">
          <div className="col-md-8">
            <h1>Détails de la mission</h1>
          </div>
          <div className="col-md-4">
            <button
              onClick={history.goBack}
              type="button"
              className="btn btn-outline-secondary mb-2 float-end  me-2"
            >
              Retour
            </button>
          </div>
        </div>

        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home </Link>
            </li>
          </ol>
        </nav>
      </div>

      <section className="section profile">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body pt-3">
                <div className="row col-md-12">
                  <div className="row col-md-12 flex">
                    <div className="row">
                      <div className="col-lg-7 col-md-7 label">Titre</div>
                      <div className="col-lg-5 col-md-5">
                        {mission != null && mission.title}
                      </div>
                    </div>
                  </div>

                  <div className="row col-md-12 flex">
                    <div className="row">
                      <div className="col-lg-7 col-md-7 label">
                        Date début de mission
                      </div>
                      <div className="col-lg-5 col-md-5">
                        {mission != null && mission.startDate}
                      </div>
                    </div>
                  </div>

                  <div className="row col-md-12 flex">
                    <div className="row">
                      <div className="col-lg-7 col-md-7 label">Période</div>
                      <div className="col-lg-5 col-md-5">
                        {mission != null && mission.period}(Jours)
                      </div>
                    </div>
                  </div>

                  <div className="row col-md-12 flex">
                    <div className="row">
                      <div className="col-lg-7 col-md-7 label">Description</div>
                      <div className="col-lg-5 col-md-5">
                        {mission != null && mission.description}
                      </div>
                    </div>
                  </div>
                  <div className="row col-md-12 flex">
                    <div className="row">
                      <div className="col-lg-7 col-md-7 label">Responsable</div>
                      <div className="col-lg-5 col-md-5">
                        {mission != null && mission.manager.fname}{" "}
                        {mission != null && mission.manager.lname}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <ul className="timeline" id="timeline">
                  {mission != null &&
                    JSON.parse(mission.steps).map((item, key) => {
                      return (
                        <li className="li complete" key={key}>
                          <div className="status">
                            <h6 className="mt-3"> {item} </h6>
                          </div>
                        </li>
                      );
                    })}
                  <li className="li ">
                    <div className="status">
                      <button
                        type="button"
                        className="add-new"
                        onClick={() =>
                          setaddProcessMissionModal(
                            <CreateProcessMission
                              setAddProcessMissionModalFromChild={
                                setaddProcessMissionModal
                              }
                              updateMissionDetails={getMissionById}
                              steps={JSON.parse(mission.steps)}
                              missionId={missionId}
                            />
                          )
                        }
                      >
                        <i className="bi bi-plus-lg"></i>
                      </button>
                      <h6 className="mt-3"> Ajouter processe</h6>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-1 me-2 float-end">
              <Link
                to={`/missions/process/${missionId}`}
                className="btn btn-primary btn-sm btn-rounded text-sm"
              >
                Process
              </Link>
            </div>
            {mission && <MissionEmployees mission={mission} />}
            <div className="row  mt-3">
              <h5 className="fw-bold mb-3 col-md-8 col-12">
                Liste des rappels missions
              </h5>
              <div className="col-md-4 col-12">
                <button
                  type="button"
                  className="btn btn-primary btn-sm btn-rounded float-end text-sm mb-2"
                  onClick={() =>
                    setAddRelaunchMissionModal(
                      <AddRelaunchMission
                        setAddRelaunchMissionModalFromChild={
                          setAddRelaunchMissionModal
                        }
                        getRelaunchMissionByMissionId={
                          getRelaunchMissionByMissionId
                        }
                        mission={mission.id}
                      />
                    )
                  }
                >
                  <i className="bi bi-calendar-date"></i>
                  Créer un rappel
                </button>
              </div>
            </div>
            <div className="col-md-12">{relaunchMissionData}</div>
            {addProcessMissionModal}
          </div>
          {addRelaunchMissionModal}
        </div>
      </section>
    </div>
  );
};

export default MissionDetails;
