import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL, MatchingServer } from "../../config/config";
const initFormErrors = {
  name: [""],
  etape: [""],
};

function AddEventModal({
  info,
  getWorkDays,
  SetEventModalFromChild,
  employeeData,
}) {
  const donnes = info.event
    ? {
        title: info.event.title,
        mission: info.event._def.extendedProps.mission,
        type: info.event._def.extendedProps.type,
        hours: info.event._def.extendedProps.hour,
        date: info.event.startStr,
      }
    : {
        title: "",
        mission: "",
        type: "",
        hours: "",
        date: info.date,
      };
  const [title, setTitle] = useState(donnes.title);
  const [date, setDate] = useState(donnes.date);
  const [type, setType] = useState(donnes.type);
  const [hour, setHour] = useState(donnes.hours);
  const [formerrors, setFormErrors] = useState(initFormErrors);
  const [mission, setMission] = useState(donnes.mission);
  const [missions, setMissions] = useState();

  async function getMissionByEmplyeeId() {
    if (employeeData === null) return;
    axios
      .get(`${baseURL}/missionEmployees/missions/${employeeData}`)
      .then((response) => {
        if (response.data.error) return;
        setMissions(response.data.data);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    console.log(donnes);
  }, [donnes]);

  function addWorkDay() {
    axios
      .post(`${MatchingServer}/workdays/create`, {
        employee: employeeData.toString(),
        title: title,
        date: date,
        type: type,
        hour: hour,
        mission: mission,
      })
      .then((response) => {
        if (response.data.error) return;
        getWorkDays();
        SetEventModalFromChild("");
      });
  }
  function updateWorkDay() {
    let taskid = info.event ? info.event.extendedProps.id : null;
    axios
      .post(`${MatchingServer}/workdays/update/${taskid}`, {
        title: title,
        employee: employeeData.toString(),
        date: date,
        type: type,
        hour: hour.toString(),
        mission: mission.toString(),
      })
      .then((response) => {
        if (response.data.error) return;
        getWorkDays();
        SetEventModalFromChild("");
      });
  }
  function validate() {
    let errorsArray = {};
    let error = false;

    if (title === "") {
      error = true;
      errorsArray.title = "Ce champs est obligatoire";
    }
    if (date === "") {
      error = true;
      errorsArray.date = "Ce champs est obligatoire";
    }
    if (type === "") {
      error = true;
      errorsArray.type = "Ce champs est obligatoire";
    }
    if (hour === "") {
      error = true;
      errorsArray.hour = "Ce champs est obligatoire";
    }
    if (mission === "") {
      error = true;
      errorsArray.hour = "Ce champs est obligatoire";
    }
    if (!error) {
      if (info.event) {
        updateWorkDay();
      } else {
        addWorkDay();
      }
      // updateWorkDay();
    }
    // setMessageErreur(errorsArray);
    setFormErrors(errorsArray);
  }
  useEffect(() => {
    getMissionByEmplyeeId();
  }, []);

  return (
    <div>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="d-flex fw-bold justify-content-center mb-5">
                {info.event ? "Modifier Tache" : "Ajouter Tache"}
              </h3>
              <div className="row">
                <label>Titre :</label>
                <div className="col-md-10 mb-3">
                  <input
                    type="text"
                    name="eventName"
                    className="form-control"
                    value={title}
                    placeholder="Nom de l'evenement"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger"> {formerrors.title}</span>
                  </div>
                </div>
                <label>Mission :</label>
                <div className="col-md-10 mb-3">
                  <select
                    id="eventMission"
                    className="form-select"
                    defaultValue={donnes.mission.title}
                    onChange={(e) => setMission(e.target.value)}
                  >
                    <option selected disabled>
                      Sélectionner Mission
                    </option>
                    {missions != null &&
                      missions.map((mission, index) => {
                        if (info.event) {
                          if (
                            mission.mission.startDate <=
                            info.event.start.toGMTString()
                          ) {
                            return (
                              <>
                                <option key={index} value={mission.mission._id}>
                                  {mission.mission.title}
                                </option>
                              </>
                            );
                          }
                        } else {
                          if (mission.mission.startDate <= info.dateStr) {
                            return (
                              <>
                                <option key={index} value={mission.mission._id}>
                                  {mission.mission.title}
                                </option>
                              </>
                            );
                          }
                        }
                      })}
                  </select>
                  <div className="input-error">
                    <span className="text-danger"> {formerrors.mission}</span>
                  </div>
                </div>
                <label>Type :</label>
                <div className="col-md-10 mb-3">
                  <select
                    id="eventType"
                    defaultValue={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    className="form-select "
                  >
                    <option selected>Sélectionner un Type</option>
                    <option>travaile</option>
                    <option>ferie</option>
                    <option>maladie</option>
                  </select>

                  <div className="input-error">
                    <span className="text-danger"> {formerrors.type}</span>
                  </div>
                </div>
                <label>Nombre d'heures :</label>
                <div className="col-md-10 mb-3">
                  <input
                    type="number"
                    name="eventName"
                    className="form-control"
                    placeholder="Nommbre d'heurs"
                    defaultValue={donnes.hours}
                    onChange={(e) => setHour(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger"> {formerrors.hour}</span>
                  </div>
                </div>
              </div>
              <div className="modal-footer mt-4">
                <button
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    SetEventModalFromChild("");
                  }}
                >
                  Annuler
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={validate}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}
export default AddEventModal;
