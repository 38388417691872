import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import EditManager from "./updateManager";
import DeleteManager from "./deleteManager";
import AddManager from "./createManager";
import { baseURL } from "../../config/config";

const ManagersComponent = ({ company_id, getManagerById }) => {
  const [managers, setManagers] = React.useState([]);
  const [addManagerModal, setAddManagerModal] = React.useState();
  const { companyId } = useParams();

  function getManagerByCompanyId() {
    if (company_id === undefined) return;
    axios
      .get(`${baseURL}/managers/${company_id}`)
      .then((response) => {
        if (response.data.error) return;
        setManagers(response.data.data);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getManagerByCompanyId();
  }, []);
  return (
    <div className="section">
      <div className="row mt-3">
        <div className="col-lg-4">
          <div className="card" style={{ minHeight: 200 }}>
            <div className="card-body mt-4">
              <div className="text-center">
                <button
                  type="button"
                  onClick={() =>
                    setAddManagerModal(
                      <AddManager
                        setAddManagerModalFromChild={setAddManagerModal}
                        getManagerByCompanyId={getManagerByCompanyId}
                        companyId={companyId}
                      />
                    )
                  }
                  className="btn btn-rounded me-1 text-sm"
                >
                  <img src="../../assets/img/new_manager.png" width="150" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {managers.map((manager, key) => {
          return (
            <div className="col-lg-4" key={key}>
              <div className="card manager-card" style={{ minHeight: 200 }}>
                <div className="card-body mt-4">
                  <p>
                    <i className="ri-user-2-fill color-jcit"></i>{" "}
                    {manager != null && manager.lname}{" "}
                    {manager != null && manager.fname}
                  </p>
                  <a
                    href={`tel:+${manager != null && manager.phone}`}
                    className="color-jcit "
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Appeler"
                  >
                    <p>
                      <i className="bi-telephone-fill me-1 color-jcit"></i>{" "}
                      {manager != null && manager.phone}
                    </p>
                  </a>
                  <label>
                    <i className="bi bi-envelope-fill color-jcit"> </i>Email :
                  </label>
                  <a
                    href={`mailto:${manager != null && manager.email}`}
                    className="color-jcit "
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Envoyer mail"
                  >
                    <p>{manager != null && manager.email}</p>
                  </a>
                  <label>Désignation :</label>
                  <p>{manager != null && manager.designation}</p>
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    className="btn text-primary me-2 col-md-2"
                    onClick={() =>
                      setAddManagerModal(
                        <EditManager
                          setAddManagerModalFromChild={setAddManagerModal}
                          getManagerByCompanyId={getManagerByCompanyId}
                          managerId={manager._id}
                        />
                      )
                    }
                  >
                    <i className="bi bi-pen"></i>
                  </button>
                  <button
                    type="button"
                    className="btn text-danger col-md-2"
                    onClick={() =>
                      setAddManagerModal(
                        <DeleteManager
                          setAddManagerModalFromChild={setAddManagerModal}
                          getManagerByCompanyId={getManagerByCompanyId}
                          managerId={manager._id}
                        />
                      )
                    }
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {addManagerModal}
    </div>
  );
};

export default ManagersComponent;
