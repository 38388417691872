import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";

function EditTypes({ setAddTypesModalFromChild, getAllTypes, typesId }) {
  const [types, setTypes] = useState({ types: "", id: "" });

  React.useEffect(() => {
    axios.get(`${baseURL}/candidatTypes/${typesId}`).then((resp) => {
      if (resp.data.error) return;
      setTypes(resp.data.data);
    });
  }, []);

  function sendData() {
    axios
      .put(`${baseURL}/candidatTypes/${typesId}`, { name: types })
      .then((resp) => {
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getAllTypes();
        setAddTypesModalFromChild("");
      });
  }

  return (
    <div>
      <div
        className="modal fade show"
        id="role_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <input
                type="text"
                className="form-control"
                id="role_name"
                value={types.name}
                placeholder="veuillez saisir le type du candidat"
                onChange={(e) => setTypes(e.target.value)}
                required
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setAddTypesModalFromChild("")}
              >
                Annuler
              </button>
              <button
                type="button"
                onClick={sendData}
                className="btn btn-primary"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default EditTypes;
