import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/loader";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyData from "../../components/emptyData";

const Supplier = () => {
  const [supplier, setSupplier] = React.useState([]);
  const [data, setData] = React.useState();
  const [hasMore, setHasMore] = React.useState(true);
  const [loadedSuppliers, setloadedSuppliers] = React.useState([]);

  async function getAllSupplier() {
    axios.get(`${baseURL}/employees/suppliers/all`).then((response) => {
      if (response.data.error) return;
      let respData = response.data.data;
      setSupplier(response.data.data);
      setloadedSuppliers(respData.slice(0, loadedSuppliers.length + 6));
    });
  }

  useEffect(() => {
    setData(<Loader />);
    getAllSupplier();
  }, []);

  useEffect(() => {}, []);
  function getLoadedSupplierList() {
    let loadedSupplierLength = 0;
    if (supplier.length - loadedSuppliers.length < 6 && supplier.length > 0) {
      loadedSupplierLength = supplier.length;
      setHasMore(false);
    } else {
      loadedSupplierLength = loadedSuppliers.length + 6;
    }
    setloadedSuppliers(supplier.slice(0, loadedSupplierLength));
  }
  useEffect(() => {
    console.log(supplier.length === 0);
    if (supplier.length === 0) {
      setData(<EmptyData />);
    } else {
      setData("");
    }
  }, [supplier]);
  async function enableDisable(supplierId, isEnabled) {
    let message =
      isEnabled == "yes"
        ? "voulez vous Désactiver ce fournisseur?"
        : "Voulez vous Activer ce fournisseur?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/employees/toggleStatus/${supplierId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllSupplier();
            }
          });
      }
    });
  }
  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Les fournisseurs</h1>
          </div>
          <div className="col-md-4">
            <Link to="/">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2"
              >
                Retour
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">fournisseur</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4">
            <Link to="/supplier/create">
              <button
                type="button"
                className="btn btn-primary btn-rounded float-end text-sm"
              >
                <i className="bi bi-plus-lg"></i> Ajouter un fournisseur
              </button>
            </Link>
          </div>
        </div>
      </div>
      <section className="section">
        <InfiniteScroll
          dataLength={loadedSuppliers.length}
          next={getLoadedSupplierList}
          hasMore={hasMore}
          loader={data}
        >
          <div className="row">
            {loadedSuppliers.map((supplier, key) => {
              return (
                <div className="col-md-6" key={key}>
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 mt-2 mb-3">
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <Link
                                className="color-jcit "
                                to={`/employees/suppliers/details/${supplier.id}`}
                              >
                                <div className="fw-bold">
                                  {supplier.fname} {supplier.lname}
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-6 d-flex justify-content-end">
                              <Link to={`/supplier/edit/${supplier.id}`}>
                                <button
                                  type="button"
                                  className="btn text-primary"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Modifier fournisseur"
                                >
                                  <i className="bi bi-pen"></i>
                                </button>
                              </Link>
                              <button
                                className="col-3 btn text-primary me-2 btn"
                                onClick={() => {
                                  enableDisable(
                                    supplier.id,
                                    supplier.isEnabled
                                  );
                                }}
                              >
                                {supplier.isEnabled == "yes" ? (
                                  <i
                                    className="text-success bi bi-toggle2-on"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Désactiver fournisseur"
                                  ></i>
                                ) : (
                                  <i
                                    className="text-danger bi bi-toggle2-off"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Activer fournisseur"
                                  ></i>
                                )}
                              </button>
                              <Link
                                className="btn text-default "
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Profile fournisseur"
                                to={`/employees/suppliers/details/${supplier.id}`}
                              >
                                <i className="ri-eye-fill"></i>
                              </Link>
                            </div>
                            <hr></hr>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <a
                              className="avatar avatar-xxl mb-3"
                              data-abc="true"
                            >
                              {supplier.isEnabled == "yes" ? (
                                <span className="avatar avatar-xxl status-success text_active ">
                                  <img src={supplier.avatar} />
                                  Actif
                                </span>
                              ) : (
                                <span className="avatar1 avatar-xxl1 status-failed text_inactif ">
                                  <img src={supplier.avatar} />
                                  Inactif
                                </span>
                              )}
                            </a>
                          </div>

                          <div className="col-md-6">
                            {" "}
                            <div className="text-light fs-12 mb-3 ">
                              <div className="mb-1">
                                <a
                                  href={`mailto:${supplier.email}`}
                                  className="color-jcit "
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Envoyer mail"
                                >
                                  {" "}
                                  <i className="bi bi-envelope-fill"></i>{" "}
                                  {supplier.email}
                                </a>
                              </div>
                              <div className="mb-1">
                                <a
                                  href={`tel:+${supplier.phone}`}
                                  className="color-jcit "
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Appeler"
                                >
                                  {" "}
                                  <i className="bi bi-telephone-fill"></i>{" "}
                                  {supplier.phone}
                                </a>
                              </div>
                              <div className="mb-1">
                                <i className="bi bi-geo-alt  me-1"></i>
                                <span>{supplier.address}</span>
                              </div>
                              <div className="mb-1">
                                <i className="bi bi-briefcase me-1"></i>
                                <span>
                                  {supplier.job} ({supplier.experience}ans)
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
      </section>
    </div>
  );
};

export default Supplier;
