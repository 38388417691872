import React, { useContext, useState } from "react";

import EmployeesFormStep3 from "./EmployeesFormStep3";
import ConfirmEmp from "./ConfirmEmp";
import { EmployeeContext } from "../../pages/employee/createEmployees";

const initFormErrors = {
  job: [""],
  salary: [""],
  typeOfContract: [""],
  startDate: [""],
};

const EmployeesFormStep4 = ({ setChildComponent }) => {
  const msg = "Ce champ est obligatoire";
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const { newEmployee, setNewEmployee } = useContext(EmployeeContext);

  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
    if (newEmployee.job == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        job: msg,
      }));
    }
    if (newEmployee.salary == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        salary: msg,
      }));
    }
    if (newEmployee.typeOfContract == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        typeOfContract: msg,
      }));
    }
    if (newEmployee.startDate == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        startDate: msg,
      }));
    }

    if (error === false) {
      setChildComponent(<ConfirmEmp setChildComponent={setChildComponent} />);
    }
  }
  return (
    <div>
      <div className="card">
        <div className="card-body pt-5">
          <form className="row g-3">
            <div className="row">
              <div className="col-md-6 pb-3">
                <label>Poste *</label>
                <input
                  type="text"
                  value={newEmployee.job}
                  onChange={(e) => {
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      job: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.job}</span>
                </div>
              </div>

              <div className="col-md-6 pb-3">
                <label>Salaire *</label>
                <input
                  type="number"
                  value={newEmployee.salary}
                  onChange={(e) => {
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      salary: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.salary}</span>
                </div>
              </div>

              <div className="col-md-6">
                <label>Type de contrat *</label>
                <select
                  className="form-select"
                  value={newEmployee.typeOfContract}
                  onChange={(e) => {
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      typeOfContract: e.target.value,
                    }));
                  }}
                  name="typeOfContract"
                >
                  <option value="" selected>
                    Sélectionner le type de contrat
                  </option>
                  <option value="cdd">CDD</option>
                  <option value="cdi">CDI</option>
                  <option value="internship">INTERNSHIP</option>
                </select>

                <div className="input-error">
                  <span className="text-danger">
                    {errorMessage.typeOfContract}
                  </span>
                </div>
              </div>

              <div className="col-md-6">
                <div className="box">
                  <div className="date-picker">
                    <label>Date debut de travail *</label>
                    <input
                      value={newEmployee.startDate}
                      className="form-control"
                      onChange={(e) => {
                        setNewEmployee((prevState) => ({
                          ...prevState,
                          startDate: e.target.value,
                        }));
                      }}
                      type="date"
                      id="datePicker"
                      required
                    />
                  </div>
                </div>
                <div className="input-error">
                  <span className="text-danger"> {errorMessage.startDate}</span>
                </div>
              </div>
            </div>
            <div className="text-end">
              <button
                type="button"
                className="btn btn-outline-secondary  me-2"
                onClick={() => {
                  setChildComponent(
                    <EmployeesFormStep3 setChildComponent={setChildComponent} />
                  );
                }}
              >
                Retour
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={validate}
              >
                Continuer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmployeesFormStep4;
