import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AddTypes from "../condidatesTypes/AddTypes";
import CandidatsFormStep2 from "./CandidatsFormStep2";
import Confirm from "./Confirm";
import { CandidatContext } from "../../pages/candidat/CreateCandidats";
import { baseURL } from "../../config/config";
import Select from "react-select";

const initFormErrors = {
  diplome: [""],
  annéeGraduation: [""],
  type: [""],
  langues: [""],
  cv: [""],
  skills: [""],
  expérience: [""],
};

const CandidatsFormStep3 = ({ setChildComponent }) => {
  const msg = "Ce champ est obligatoire";
  const [types, setTypes] = React.useState([]);
  const [addTypesModal, setaddTypesModal] = React.useState();
  const [errorMessage, setErrorMessage] = useState(initFormErrors);

  function getTypes() {
    axios.get(`${baseURL}/candidatTypes`).then((resp) => {
      if (resp.data.error) return;
      resp.data.data.map((item, index) => {
        return setTypes((prevState) => [
          ...prevState,
          { value: JSON.stringify(item), label: item.name },
        ]);
      });
    });
  }

  const { newCandidat, setNewCandidat } = useContext(CandidatContext);

  useEffect(() => {
    getTypes();
  }, []);
  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
    if (newCandidat.expérience == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        expérience: msg,
      }));
    }
    if (newCandidat.skills == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        skills: msg,
      }));
    }
    if (newCandidat.langues == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        langues: msg,
      }));
    }
    if (newCandidat.diplome == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        diplome: msg,
      }));
    }

    if (newCandidat.type == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        type: msg,
      }));
    }

    if (error === false) {
      setChildComponent(<Confirm setChildComponent={setChildComponent} />);
    }
  }

  const languesOptions = [
    { value: "français", label: "Français" },
    { value: "english", label: "Anglais" },
    { value: "arabic", label: "Arabe" },
    { value: "spanish", label: "Espagnole" },
    { value: "deutsh", label: "Allemand" },
    { value: "Italien", label: "Italien" },
  ];

  return (
    <div>
      <div className="progress" style={{ height: "10px" }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: "90%" }}
          aria-valuenow="90"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="card">
        <div className="card-body pt-5">
          <form className="row g-3">
            <div className="row">
              <div className="col-md-6">
                <label>Expérience *</label>
                <input
                  type="text"
                  value={newCandidat.experience}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      experience: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.experience}</span>
                </div>
              </div>

              <div className="col-md-6">
                <label>
                  Compétences *{" "}
                  <span className="textSkills">separation par ( ; )</span>
                </label>
                <input
                  type="text"
                  value={newCandidat.skills}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      skills: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <label> </label>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.skills}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label>langues *</label>
                <Select
                  options={languesOptions}
                  isMulti
                  onChange={(e) => {
                    let langues = [];
                    e.forEach((lang, key) => {
                      langues.push(lang.value);
                    });
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      langues: JSON.stringify(langues),
                    }));
                  }}
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.langues}</span>
                </div>
              </div>

              <div className="col-md-6 pb-3">
                <label>Dernier diplome obtenue *</label>
                <input
                  type="text"
                  value={newCandidat.diplome}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      diplome: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.diplome}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label>Année de diplome *</label>
                <input
                  type="number"
                  value={newCandidat.diplomeDate}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      diplomeDate: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">
                    {errorMessage.diplomeDate}
                  </span>
                </div>
              </div>

              <div className="col-md-6">
                <label>Type de candidat *</label>
                <div className="row">
                  <Select
                    options={types}
                    className="col-md-11"
                    isSearchable
                    onChange={(e) => {
                      const selectedType = JSON.parse(e.value);
                      setNewCandidat((prevState) => ({
                        ...prevState,
                        type: selectedType.id,
                        typename: selectedType.name,
                      }));
                    }}
                  />
                  <span
                    className="col-md-1 input-group-text p-0"
                    id="basic-addon1"
                  >
                    <button
                      type="button"
                      className="btn btn-"
                      onClick={() =>
                        setaddTypesModal(
                          <AddTypes
                            setAddTypesModalFromChild={setaddTypesModal}
                            setTypesList={setTypes}
                          />
                        )
                      }
                    >
                      <i className="bi bi-plus-lg"></i>
                    </button>
                  </span>
                </div>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.type}</span>
                </div>
              </div>

              <div className="col-md-6">
                <span>Upload CV *</span>

                <div className="custom-file">
                  <input
                    type="file"
                    onChange={(e) => {
                      setNewCandidat((prevState) => ({
                        ...prevState,
                        cv: e.target.files[0],
                      }));
                    }}
                    className="form-control"
                  />
                </div>

                <div className="input-error">
                  <span className="text-danger">{errorMessage.cv}</span>
                </div>
              </div>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-outline-secondary  me-2"
                  onClick={() => {
                    setChildComponent(
                      <CandidatsFormStep2
                        setChildComponent={setChildComponent}
                      />
                    );
                  }}
                >
                  Retour
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={validate}
                >
                  Continuer
                </button>
              </div>
            </div>
          </form>
          {addTypesModal}
        </div>
      </div>
    </div>
  );
};

export default CandidatsFormStep3;
