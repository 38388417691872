import axios from "axios";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { baseURL } from "../../config/config";
import ManagersComponent from "../../components/manager/managerDetails";
import CompanyDetailsComponent from "../../components/company/companyDetailsComponent";
import DataTable from "react-data-table-component";
import EmptyData from "../../components/emptyData";
import swal from "sweetalert";
import CreateMission from "../../components/mission/createMission";
import EditMission from "../../components/mission/updateMission";
const CompanyDetails = () => {
  const [supplier, setSupplier] = React.useState([]);
  const [company, setCompany] = React.useState([]);
  const [childComponent, setChildComponent] = React.useState();
  const [activeDetails, setActiveDetails] = React.useState("active");
  const [activeDManagers, setActiveDManagers] = React.useState("");
  const [data, setData] = React.useState([]);
  const { companyId } = useParams();
  const [mission, setMission] = React.useState([]);
  const [managers, setManagers] = React.useState([]);
  const [missionData, setMissionData] = React.useState([]);
  const [addMissionModal, setAddMissionModal] = React.useState();

  async function getAllCompany() {
    if (companyId === undefined) return;
    axios.get(`${baseURL}/companies/${companyId}`).then((response) => {
      if (response.data.error) return;
      setCompany(response.data.data);
    });
  }

  function getManagerByCompanyId() {
    if (companyId === undefined) return;
    axios
      .get(`${baseURL}/managers/${companyId}`)
      .then((response) => {
        if (response.data.error) return;
        setManagers(response.data.data);
      })
      .catch((err) => {});
  }
  useEffect(() => {
    getMissionByCompanyId();
  }, [addMissionModal]);
  useEffect(() => {
    getAllCompany();
    getSupplierByCompanyId();
    getMissionByCompanyId();
    getManagerByCompanyId();
  }, []);
  useEffect(() => {
    getMissionByCompanyId();
  }, [addMissionModal]);
  async function getSupplierByCompanyId() {
    if (companyId === undefined) return;
    axios
      .get(`${baseURL}/employees/suppliers/company/${companyId}`)
      .then((response) => {
        if (response.data.error) return;
        setSupplier(response.data.data);
      })
      .catch((err) => {});
  }

  async function getMissionByCompanyId() {
    if (companyId === undefined) return;
    axios
      .get(`${baseURL}/missions/company/${companyId}`)
      .then((response) => {
        if (response.data.error) return;
        setMission(response.data.data);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    if (activeDetails === "active") {
      setChildComponent(<CompanyDetailsComponent />);
    } else if (activeDManagers === "active") {
      <ManagersComponent company_id={companyId} />;
    }
  }, [company]);

  useEffect(() => {
    if (supplier == null) return;
    if (supplier.length > 0) {
      setData(<DataTable columns={columns} data={supplier} pagination />);
    } else {
      setData(<EmptyData />);
    }
  }, [supplier]);

  useEffect(() => {
    if (mission == null) return;
    if (mission.length > 0) {
      setMissionData(
        <DataTable columns={columnsMission} data={mission} pagination />
      );
    } else {
      setMissionData(<EmptyData />);
    }
  }, [mission]);

  async function enableDisableMission(missionId, isEnabled) {
    let message =
      isEnabled == "yes"
        ? "voulez vous Désactiver cette mission?"
        : "Voulez vous Activer cette mission?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/missions/toggleStatus/${missionId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getMissionByCompanyId();
            }
          });
      }
    });
  }

  async function enableDisable(supplierId, isEnabled) {
    let message =
      isEnabled == "yes"
        ? "voulez vous désactiver ce fournisseur?"
        : "Voulez vous activer ce fournisseur?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/employees/toggleStatus/${supplierId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getSupplierByCompanyId();
            }
          });
      }
    });
  }

  const columns = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "4%",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <div>
          <Link
            to={`/employees/suppliers/details/${row.id}`}
            className="color-jcit"
          >
            {" "}
            <img
              src={row.avatar}
              className="rounded-circle"
              width="35"
              height="35"
            />{" "}
            {row.lname} {row.fname}
          </Link>
        </div>
      ),
      width: "15%",
      sortable: true,
    },

    {
      name: "Contact",
      selector: (row) => (
        <div>
          <div className=" col-md-12">
            <p className="p-0 m-0 col-md-12">
              <a
                href={`mailto:${row.email}`}
                className="color-jcit "
                data-toggle="tooltip"
                data-placement="top"
                title="Envoyer mail"
              >
                {" "}
                <i className="bi bi-envelope-fill"></i> {row.email}
              </a>
            </p>
            <p className="p-0 m-0 col-md-12">
              {" "}
              <a
                href={`tel:+${row.phone}`}
                className="color-jcit "
                data-toggle="tooltip"
                data-placement="top"
                title="Appeler"
              >
                <i className="bi bi-telephone-fill"></i> {row.phone}
              </a>
            </p>
          </div>
        </div>
      ),
      width: "18%",
      sortable: true,
    },
    {
      name: "Adresse",
      selector: (row) => (
        <div>
          <div>{row.address}</div>
          {row.city.name}
        </div>
      ),
      width: "15%",
      sortable: true,
    },
    {
      name: "type de contrat",
      selector: (row) => (
        <div>
          <div>{row.typeOfContract}</div>
        </div>
      ),
      width: "15%",
      sortable: true,
    },
    {
      name: "Expérience",
      selector: (row) => (
        <div>
          <div>{row.experience} ans</div>
        </div>
      ),
      width: "15%",
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="row col-12">
          <Link
            className="col-3 btn text-primary me-2"
            to={`/supplier/edit/${row.id}`}
          >
            <i className="bi bi-pen"></i>
          </Link>
          <button
            className="col-3 btn text-primary me-2 btn"
            onClick={() => {
              enableDisable(row.id, row.isEnabled);
            }}
          >
            {row.isEnabled == "yes" ? (
              <i className="text-success bi bi-toggle2-on"></i>
            ) : (
              <i className="text-danger bi bi-toggle2-off"></i>
            )}
          </button>
          <Link
            className="col-3 btn text-primary me-2"
            to={`/employees/suppliers/details/${row.id}`}
          >
            <i className="ri-arrow-right-s-fill"></i>
          </Link>
        </div>
      ),
    },
  ];

  const columnsMission = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "4%",
      sortable: true,
    },

    {
      name: "Titre",
      selector: (row) => (
        <div>
          <div>{row.title}</div>
        </div>
      ),
      width: "18%",
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (
        <div className="widthDescriptionMission">
          <div>{row.description}</div>
        </div>
      ),
      width: "10rem",
      sortable: true,
    },
    {
      name: "Date debut de mission",
      selector: (row) => (
        <div>
          <div>{row.startDate}</div>
        </div>
      ),
      width: "18%",
      sortable: true,
    },
    {
      name: "Période",
      selector: (row) => (
        <div>
          <div>{row.period}</div>
        </div>
      ),
      width: "15%",
      sortable: true,
    },
    {
      name: "Résponsable",
      selector: (row) => (
        <div>
          <div>
            {row.manager != null && row.manager.fname}{" "}
            {row.manager != null && row.manager.lname}
          </div>
        </div>
      ),
      width: "13%",
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="row col-12">
          <button
            className="col-3 btn text-primary me-2"
            onClick={() =>
              setAddMissionModal(
                <EditMission
                  setAddMissionModalFromChild={setAddMissionModal}
                  managers={managers}
                  missionId={row._id}
                  getMissionByCompanyId={getMissionByCompanyId}
                />
              )
            }
          >
            <i className="bi bi-pen"></i>
          </button>
          <button
            className="col-3 btn text-primary me-2 btn"
            onClick={() => {
              enableDisableMission(row._id, row.isEnabled);
            }}
          >
            {row.isEnabled == "yes" ? (
              <i className="text-success bi bi-toggle2-on"></i>
            ) : (
              <i className="text-danger bi bi-toggle2-off"></i>
            )}
          </button>
          <Link
            className="col-3 btn text-primary me-2"
            to={`/missions/details/${row._id}`}
          >
            <i className="ri-arrow-right-s-fill"></i>
          </Link>
        </div>
      ),
      width: "15%",
    },
  ];

  return (
    <div>
      <div className="pagetitle row">
        <div className="col-md-8">
          <h1>Détails du Société</h1>
        </div>
        <div className="col-md-4">
          <Link to="/company">
            <button
              type="button"
              className="btn btn-outline-secondary mb-2 float-end  me-2"
            >
              Retour
            </button>
          </Link>
        </div>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/company">Les Sociétés</Link>
            </li>
          </ol>
        </nav>
      </div>

      <section className="section profile">
        <div className="row">
          <div className="col-xl-4">
            <div className="card">
              <div className="card-body profile-card pt-4 d-flex flex-column align-items-center text-uppercase ">
                <img
                  src={company != null && company.logo}
                  alt="Profile"
                  className="rounded-circle"
                />
                <h2>{company != null && company.name}</h2>
                <div className="row text-lowercase col-md-8 mt-4 flex">
                  <div className="row">
                    <div className="col-md-1 label ">
                      <i className="bi-telephone-fill me-1"></i>
                    </div>
                    <div className="col-md-10">
                      {company != null && company.phone}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-1 label ">
                      <i className="bi-envelope-fill me-1"></i>
                    </div>
                    <div className="col-md-10">
                      {company != null && company.email}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-1 label">
                      <i className=" bi bi-globe me-1"></i>
                    </div>
                    <div className="col-md-10">
                      {company != null && company.website}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-1 label">
                      <i className="bi bi-geo-alt-fill me-1"></i>
                    </div>
                    <div className="col-md-10">
                      {company != null && company.address}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-8">
            <div className="card">
              <div className="card-body pt-3">
                <div className="row">
                  <ul className="nav nav-tabs nav-tabs-bordered col-10">
                    <li className="nav-item">
                      <button
                        className={`nav-link ${activeDetails}`}
                        data-bs-toggle="tab"
                        data-bs-target="#profile-overview"
                        onClick={() => {
                          setActiveDetails("active");
                          setActiveDManagers("");
                          setChildComponent(<CompanyDetailsComponent />);
                        }}
                      >
                        Details
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link ${activeDManagers}`}
                        data-bs-toggle="tab"
                        data-bs-target="#profile-overview"
                        onClick={() => {
                          setActiveDetails("");
                          setActiveDManagers("active");
                          setChildComponent(
                            <ManagersComponent company_id={companyId} />
                          );
                        }}
                      >
                        Responsables
                      </button>
                    </li>
                  </ul>
                </div>
                {childComponent}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="row">
        <div className="col-md-12 row mb-3">
          <div className="col-md-8">
            <h5 className="fw-bold mb-3">Liste des missions</h5>
          </div>
          <div className="col-md-4">
            <button
              type="button"
              className="btn btn-primary btn-rounded float-end text-sm"
              onClick={() =>
                setAddMissionModal(
                  <CreateMission
                    setAddMissionModalFromChild={setAddMissionModal}
                    companyId={companyId}
                    managers={managers}
                  />
                )
              }
            >
              <i className="bi bi-plus-lg"></i> Ajouter une mission
            </button>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">{missionData}</div>
          </div>
        </div>
        {addMissionModal}
      </div>
      <div className="row">
        <h5 className="fw-bold mb-3">Liste des fournisseurs</h5>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">{data}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
