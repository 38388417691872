import axios from "axios";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MatchingServer, baseURL } from "../../config/config";
import DataTable from "react-data-table-component";
import EmptyData from "../../components/emptyData";
import swal from "sweetalert";
import "../../pages/mission/detailsMission.css";

function MissionEmployeesList({ missionId }) {
  const [data, setData] = React.useState();
  const [steps, setSteps] = React.useState();

  const [missionEmployees, setMissionEmployees] = React.useState([]);

  const columnsMissionEmployees = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "5%",
      sortable: true,
    },
    {
      name: "Nom",
      selector: (row) => (
        <div className="col-12">
          <Link
            to={`/employees/details/${row.employe.employee._id}`}
            className="color-jcit"
          >
            {" "}
            <img
              src={row.avatar}
              className="rounded-circle"
              width="35"
              height="35"
            />{" "}
            {row.employe.employee.fname} {row.employe.employee.lname}
          </Link>
        </div>
      ),
      width: "15%",
      sortable: true,
    },
    {
      name: "Contact",
      width: "18%",
      cell: (row) => (
        <div>
          <div className="email">
            <p>
              <a
                href={`mailto:${row.employe.employee.email}`}
                className="color-jcit "
                data-toggle="tooltip"
                data-placement="top"
                title="Envoyer mail"
              >
                {" "}
                <i className="bi bi-envelope"></i> {row.employe.employee.email}
              </a>
            </p>
          </div>
          <div className="phone">
            <p>
              <a
                href={`tel:+${row.employe.employee.phone}`}
                className="color-jcit "
                data-toggle="tooltip"
                data-placement="top"
                title="Appeler"
              >
                <i className="bi bi-phone"></i>
                {row.employe.employee.phone}
              </a>
            </p>
          </div>
        </div>
      ),
    },
    {
      name: "Adresse",
      selector: (row) => row.employe.employee.address,
      width: "17%",
      sortable: true,
    },

    {
      name: "poste",
      selector: (row) => row.employe.employee.job,
      width: "13%",
      sortable: true,
    },
    {
      name: "Nbr Heurs",
      selector: (row) => row.hours + " Heurs",
      width: "10%",
      sortable: true,
    },
    {
      name: "Statut",
      width: "15%",
      cell: (row) => (
        <div className="row col-12">
          <select
            value={row.employe.status}
            className="form-select form-select-sm"
            onChange={(e) =>
              changeStatus(e.target.value, row.employe.employee._id)
            }
          >
            <option value="waiting">Attente de Confirmation</option>
            <option value="in_progress">Encours d'entretien</option>
            <option value="Rejected">Rejeté</option>
            <option value="Validated">Validé</option>
          </select>
        </div>
      ),
    },

    {
      name: "Actions",
      width: "10%",
      cell: (row) => (
        <div className="row col-12">
          <button
            className="col-3 btn text-primary me-2 btn"
            onClick={() => {
              enableDisableMissionEmployee(
                row.employe.employee._id,
                row.employe.employee.isEnabled
              );
            }}
          >
            {row.employe.employee.isEnabled === "yes" ? (
              <i className="text-success bi bi-toggle2-on"></i>
            ) : (
              <i className="text-danger bi bi-toggle2-off"></i>
            )}
          </button>
        </div>
      ),
    },
  ];
  function getMissionById() {
    if (missionId === undefined) return;
    axios.get(`${baseURL}/missions/${missionId}`).then((response) => {
      if (response.data.error) return;
      setSteps(JSON.parse(response.data.data.steps));
    });
  }

  function changeStatus(status, id) {
    let step = "";
    if (status === "Rejected") {
      step = "Rejeter";
    } else {
      if (status === "Validated") {
        step = "Affecter";
      } else {
        if (status === "waiting") {
          step = "0";
        } else {
          if (status === "in_progress") {
            step = steps[0] ? steps[0] : "";
          }
        }
      }
    }
    axios
      .post(
        `${baseURL}/missionEmployees/affectEmployeeToMissionProcess/${id}`,
        {
          status: status,
          step: step,
        }
      )
      .then((resp) => {
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getEmployeeByMissionId();
      })
      .catch((error) => {});
  }

  useEffect(() => {
    if (missionEmployees.length > 0) {
      setData(
        <DataTable
          columns={columnsMissionEmployees}
          data={missionEmployees}
          pagination
        />
      );
    } else {
      setData(<EmptyData />);
    }
  }, [missionEmployees]);

  function getEmployeeByMissionId() {
    if (missionId === null) return;
    axios
      .get(`${MatchingServer}/workdays/missiondays/${missionId}`)
      .then((res) => {
        if (res.data.error) return;
        setMissionEmployees(res.data.data);
      })

      .catch((err) => {
        console.log(err);
      });
  }
  async function enableDisableMissionEmployee(missionEmployeeId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver cet employé?"
        : "Voulez vous Activer cet employé?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(
            `${baseURL}/missionEmployees/toggleStatus/${missionEmployeeId}`,
            {}
          )
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getEmployeeByMissionId();
            }
          });
      }
    });
  }

  useEffect(() => {
    getEmployeeByMissionId();
    getMissionById();
  }, []);
  return (
    <>
      <div className="col-md-12">{data}</div>
    </>
  );
}

export default MissionEmployeesList;
