import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CreateProcess from "../../components/process_interview_component/CreateProcess";
import axios from "axios";
import { baseURL } from "../../config/config";
import swal from "sweetalert";
import UpdateProcess from "../../components/process_interview_component/UpdateProcess";
import Loader from "../../components/loader";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyData from "../../components/emptyData";

const Process = () => {
  const [addProcessModal, setaddProcessModal] = React.useState();
  const [process, setProcess] = React.useState([]);
  const [data, setData] = React.useState();
  const [hasMore, setHasMore] = React.useState(true);
  const [loadedProcess, setloadedProcess] = React.useState([]);

  async function getAllProcess() {
    axios
      .get(`${baseURL}/process/`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setProcess(response.data.data);
        setloadedProcess(respData.slice(0, loadedProcess.length + 21));
      })
      .catch((err) => {});
  }

  useEffect(() => {
    console.log(process.length === 0);
    if (process.length === 0) {
      setData(<EmptyData />);
    } else {
      if (process.length > 0) {
        setData("");
      }
    }
  }, [process]);

  useEffect(() => {
    setData(<Loader />);
    getAllProcess();
  }, []);

  async function enableDisable(processId, isEnabled) {
    let message =
      isEnabled == "yes"
        ? "voulez vous Désactiver ce process?"
        : "Voulez vous Activer ce process?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/process/toggleStatus/${processId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllProcess();
            }
          });
      }
    });
  }
  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Les processes d'entretien</h1>
          </div>
          <div className="col-md-4">
            <Link to="/">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2"
              >
                Retour
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">processes</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4">
            <button
              type="button"
              className="btn btn-primary btn-rounded float-end text-sm"
              onClick={() =>
                setaddProcessModal(
                  <CreateProcess
                    setAddProcessModalFromChild={setaddProcessModal}
                    getAllProcess={getAllProcess}
                  />
                )
              }
            >
              <i className="bi bi-plus-lg"></i> Ajouter un processe
            </button>
          </div>
        </div>
      </div>
      <section className="section ">
        <InfiniteScroll
          dataLength={loadedProcess.length}
          next={loadedProcess}
          hasMore={hasMore}
          loader={data}
        >
          <div className="row  mt-5">
            {loadedProcess.map((item, key) => {
              return (
                <div className="col-md-4 mb-3">
                  <div className="col-md-12 mt-2 mb-3 accordion-item">
                    <div className="row ">
                      <div className="col-md-6">
                        <Link
                          to={`/entretien/${item.id}`}
                          className="col-md-4 ms-3 mt-3 fw-bold style_process_title color-jcit"
                        >
                          {item.name}
                        </Link>
                      </div>
                      <div className="col-md-6 d-flex justify-content-end">
                        {" "}
                        <Link to={`/entretien/${item.id}`}>
                          {" "}
                          <button
                            type="button"
                            className="btn text-success"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Détaills process"
                          >
                            <i className="bi bi-bar-chart"></i>
                          </button>
                        </Link>
                        <button
                          type="button"
                          className="btn text-primary"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Modifier process"
                          onClick={() =>
                            setaddProcessModal(
                              <UpdateProcess
                                setAddProcessModalFromChild={setaddProcessModal}
                                getAllProcess={getAllProcess}
                                processId={item.id}
                              />
                            )
                          }
                        >
                          <i className="bi bi-pen"></i>
                        </button>{" "}
                        <button
                          className="col-3 btn text-primary me-2 btn"
                          onClick={() => {
                            enableDisable(item.id, item.isEnabled);
                          }}
                        >
                          {item.isEnabled == "yes" ? (
                            <i
                              className="text-success bi bi-toggle2-on"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Désactiver process"
                            ></i>
                          ) : (
                            <i
                              className="text-danger bi bi-toggle2-off"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Activer process"
                            ></i>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
      </section>
      {addProcessModal}
    </div>
  );
};

export default Process;
{
  /* <div className="col-md-4 mb-3" key={key}>
                  <div
                    className="accordion process_accordion"
                    id={`process-${key}`}
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id={`headingOne-${key}`}>
                        <div className="row">
                          <Link
                            to={`/entretien/${item.id}`}
                            className="col-md-4 ms-3 mt-3 fw-bold style_process_title color-jcit"
                          >
                            {item.name}
                          </Link>

                        <div className="col-md-7 ">
                          <div className="row d-flex justify-content-end me-1">
                            <div className="col-md-2">
                              {" "}
                              <Link to={`/entretien/${item.id}`}>
                                {" "}
                                <button
                                  type="button"
                                  className="btn text-success"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Détaills process"
                                >
                                  <i className="bi bi-bar-chart"></i>
                                </button>
                              </Link>
                            </div>
                            <div className="col-md-2">
                              {" "}
                              <button
                                type="button"
                                className="btn text-primary"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Modifier process"
                                onClick={() =>
                                  setaddProcessModal(
                                    <UpdateProcess
                                      setAddProcessModalFromChild={
                                        setaddProcessModal
                                      }
                                      getAllProcess={getAllProcess}
                                      processId={item.id}
                                    />
                                  )
                                }
                              >
                                <i className="bi bi-pen"></i>
                              </button>
                            </div>
                            <div className="col-md-2">
                              {" "}
                              <button
                                className="col-3 btn text-primary me-2 btn"
                                onClick={() => {
                                  enableDisable(item.id, item.isEnabled);
                                }}
                              >
                                {item.isEnabled == "yes" ? (
                                  <i
                                    className="text-success bi bi-toggle2-on"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Désactiver process"
                                  ></i>
                                ) : (
                                  <i
                                    className="text-danger bi bi-toggle2-off"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Activer process"
                                  ></i>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </h2>
                    <div
                      id={`collapseOne-${key}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`headingOne-${key}`}
                      data-bs-parent={`#process-${key}`}
                    >
                      <div className="accordion-body ">
                        <div className="row mt-3">
                          {item.steps.length != null
                            ? JSON.parse(item.steps).map((step, key) => {
                                return (
                                  <div key={key} className="col-md-12">
                                    {step}
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                            </div> */
}
