import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import axios from "axios";
import { baseURL } from "../config/config";
import { Link } from "react-router-dom";

const Home = () => {
  const { user, setUser } = useContext(UserContext);
  const [company, setCompany] = React.useState([]);
  const [process, setProcess] = React.useState([]);
  const [mission, setMission] = React.useState([]);
  async function getAllProcess() {
    axios
      .get(`${baseURL}/process/`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setProcess(respData);
      })
      .catch((err) => {});
  }
  async function getAllCompany() {
    axios
      .get(`${baseURL}/companies`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setCompany(respData);
      })
      .catch((err) => {});
  }
  async function getAllMission() {
    axios
      .get(`${baseURL}/missions/allMissions`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setMission(respData);
      })
      .catch((err) => {});
  }
  useEffect(() => {
    getAllCompany();
    getAllProcess();
    getAllMission();
  }, []);

  return (
    <div>
      <section>
        <h1> Bonjour {user && user.name}</h1>
        <h4 className="color-jcit">Les processes d'entretien</h4>
        <div className="row">
          {process.map((item, key) => {
            if (key < 7) {
              return (
                <div className="col-md-3">
                  <div className="card p-3 fw-bold style_process_title  text-center">
                    <Link className="color-jcit" to={`/entretien/${item.id}`}>
                      {item.name}
                    </Link>
                  </div>
                </div>
              );
            }
            if (key === 7) {
              return (
                <>
                  <div className="col-md-3">
                    <div className=" card-style">
                      <div className="row">
                        <div className="col-md-8">
                          <Link className="nav-link collapsed" to="/process">
                            <button
                              type="button"
                              className="btn ms-2 color-white"
                            >
                              Tout les processes
                            </button>
                          </Link>
                        </div>
                        <div className="col-md-4">
                          {" "}
                          <Link className="nav-link collapsed" to="/process">
                            <div className="col-md-4 arrow_style ">
                              <i className="bi bi-arrow-right "></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>
      </section>
      <section>
        <h4 className="color-jcit">Les Sociétés</h4>
        <div className="row">
          {company.map((item, key) => {
            if (key < 7) {
              return (
                <div className="col-md-3">
                  <div className="card p-3 fw-bold style_process_title  text-center">
                    <Link
                      className="color-jcit"
                      to={`/company/details/${item.id}`}
                    >
                      {item.name}
                    </Link>
                  </div>
                </div>
              );
            }
            if (key === 7) {
              return (
                <>
                  <div className="col-md-3">
                    <div className=" card-style">
                      <div className="row">
                        <div className="col-md-8">
                          {" "}
                          <Link className="nav-link collapsed" to="/company">
                            <button
                              type="button"
                              className="btn ms-2 color-white"
                            >
                              Tout les Sociétés
                            </button>
                          </Link>
                        </div>
                        <div className="col-md-4">
                          {" "}
                          <Link className="nav-link collapsed" to="/company">
                            <div className="col-md-4 arrow_style">
                              <i className="bi bi-arrow-right "></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>
      </section>
      <section>
        <h4 className="color-jcit">Liste des missions</h4>
        <div className="row">
          {mission.map((item, key) => {
            if (key < 7) {
              return (
                <div className="col-md-3">
                  <div className="card p-3 fw-bold style_process_title  text-center">
                    <Link
                      className="color-jcit"
                      to={`/missions/details/${item.id}`}
                    >
                      {item.title}
                    </Link>
                  </div>
                </div>
              );
            }
            if (key === 7) {
              return (
                <>
                  <div className="col-md-3">
                    <div className=" card-style">
                      <div className="row">
                        <div className="col-md-8">
                          {" "}
                          <Link className="nav-link collapsed" to="/missions">
                            <button
                              type="button"
                              className="btn ms-2 color-white"
                            >
                              Tout les missions
                            </button>
                          </Link>
                        </div>
                        <div className="col-md-4">
                          {" "}
                          <Link className="nav-link collapsed" to="/missions">
                            <div className="col-md-4 arrow_style">
                              <i className="bi bi-arrow-right "></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>
      </section>
    </div>
  );
};
export default Home;
