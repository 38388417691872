import React from "react";
import { Link } from "react-router-dom";

export function FollowUp({ allData }) {
  let tab = [];
  for (const elements in allData) {
    const element = allData[elements];
    tab.push(
      <div className="container-fluid col-3 shadow p-3 mb-5 bg-light rounded m-3">
        <div className="row justify-content-center">
          <div className="card text-center bg-light">
            <h6 className="bold mb-1 " id="head1">
              Mission:
            </h6>
            <Link className=" " to={`/missions/details/${element.mission._id}`}>
              <h2 className="bold mb-3 color-jcit" id="head2">
                {element.mission.title}
              </h2>
            </Link>

            <p>Nombre de jours travailes: {element.days}</p>
            <p>Nombre d'heures travailes: {element.hours}</p>
            <p>Nombre de jours rates: {element.daysnotworked}</p>
            <p>Nombre d'heures rates: {element.hoursnotworked}</p>
          </div>
        </div>
      </div>
    );
  }
  return <div className="row d-flex justify-content-center">{tab}</div>;
}
