import React from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";

function CadidatToEmplyee({
  setaddEmployeeModalFromChild,
  candidatId,
  getAllCandidats,
}) {
  const [nirpp, setNirpp] = React.useState();
  const [company, setCompany] = React.useState();
  const [job, setJob] = React.useState();
  const [startDate, setStartDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );
  const [typeOfContract, setTypeOfContract] = React.useState();
  const [salary, setSalary] = React.useState();
  const [fname, setFname] = React.useState();
  const [lname, setLname] = React.useState();
  const [email, setEmail] = React.useState();
  const [phone, setPhone] = React.useState();
  const [avatar, setAvatar] = React.useState();
  const [user, setUser] = React.useState();
  const [birthday, setBirthday] = React.useState();
  const [birthPlace, setBirthPlace] = React.useState();
  const [city, setCity] = React.useState();
  const [address, setAddress] = React.useState();
  const [cp, setCp] = React.useState();
  const [gender, setGender] = React.useState();
  const [isEnabled, setIsEnabled] = React.useState();
  const [status, setStatus] = React.useState();
  const [diplome, setDiplome] = React.useState();
  const [skills, setSkills] = React.useState();
  const [type, setType] = React.useState();
  const [langues, setLangues] = React.useState();
  const [experience, setExperience] = React.useState();
  const [diplomeDate, setDiplomeDate] = React.useState();
  const [companies, setCompanies] = React.useState();

  const initFormErrors = {
    nirpp: [""],
    company: [""],
    job: [""],
    startDate: [""],
    typeOfContract: [""],
    salary: [""],
    fname: [""],
    lname: [""],
    email: [""],
    phone: [""],
    avatar: [""],
    user: [""],
    birthday: [""],
    birthPlace: [""],
    city: [""],
    address: [""],
    cp: [""],
    gender: [""],
    isEnabled: [""],
    status: [""],
    diplome: [""],
    type: [""],
    langues: [""],
    experience: [""],
    diplomeDate: [""],
  };
  const [formerrors, setFormErrors] = React.useState(initFormErrors);

  React.useEffect(() => {
    getCandidat();
    getAllCompanies();
  }, [candidatId]);
  async function getAllCompanies() {
    axios
      .get(`${baseURL}/companies`)
      .then((response) => {
        if (response.data.error) return;
        setCompanies(response.data.data);
      })
      .catch((err) => {});
  }
  async function getCandidat() {
    axios
      .get(`${baseURL}/candidates/${candidatId}`)
      .then((resp) => {
        if (resp.data.error) return;
        const data = resp.data.data;
        setFname(data.firstname);
        setLname(data.lastname);
        setEmail(data.email);
        setPhone(data.phone);
        setAvatar(data.avatarid);
        setUser(data.user);
        setBirthday(data.birthday);
        setBirthPlace(data.birthPlace);
        setCity(data.city);
        setAddress(data.address);
        setCp(data.cp);
        setGender(data.gender);
        setIsEnabled(data.isEnabled);
        setStatus(data.status);
        setDiplome(data.diplome);
        setSkills(data.skills);
        setLangues(data.langues);
        setExperience(data.experience);
        setDiplomeDate(data.diplomeDate);
        setType("employee");
        setJob(data.type.name);
      })
      .catch((err) => {});
  }
  function sendData() {
    swal({
      title: "voulez vous Ajouter cette candidat en tant qu'emplye  ? ",
      icon: "warning",
      buttons: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .post(`${baseURL}/employees/createfromcandidate`, {
            nirpp: nirpp,
            company: company,
            job: job,
            startDate: startDate,
            typeOfContract: typeOfContract,
            salary: salary,
            fname: fname,
            lname: lname,
            email: email,
            phone: phone.toString(),
            avatar: avatar._id,
            user: user,
            birthday: birthday,
            birthPlace: birthPlace,
            city: city._id,
            address: address,
            cp: cp,
            gender: gender,
            isEnabled: isEnabled,
            status: status,
            diplome: diplome,
            type: type,
            langues: langues,
            experience: experience,
            diplomeDate: diplomeDate,
            skills: skills,
          })
          .then((resp) => {
            setFormErrors(initFormErrors);
            if (resp.data.error) return;
            swal(resp.data.message, {
              icon: "success",
              timer: 2500,
            });
            getAllCandidats();
            setaddEmployeeModalFromChild("");
          })
          .catch((error) => {
            console.log(error);
            swal({
              title: "une erreur s'est produite   ",
              icon: "error",
              timer: 6000,
              dangerMode: true,
              buttons: ["resseyer", "quitter"],
            }).then((confirmed) => {
              if (confirmed) {
                setaddEmployeeModalFromChild("");
              }
            });
            setFormErrors(error.response.data);
          });
      }
    });
  }

  return (
    <div>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col-md-12 mt-2">
                <input
                  type="text"
                  className="form-control"
                  id="nirpp"
                  placeholder="Nirpp"
                  onChange={(e) => setNirpp(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.nirpp}</span>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <input
                  type="text"
                  className="form-control"
                  id="salary"
                  placeholder="Salary"
                  onChange={(e) => setSalary(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.salary}</span>
                </div>
              </div>

              <div className="col-md-12 mt-2">
                <input
                  type="date"
                  className="form-control"
                  id="startDate"
                  placeholder="Date début de mission"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.startDate}</span>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <select
                  id="companyId"
                  onChange={(e) => {
                    setCompany(e.target.value);
                  }}
                  className="form-select"
                >
                  <option>Sélectionner une societe</option>
                  {companies != null &&
                    companies.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {`${item.name} /${item.owner}`}
                        </option>
                      );
                    })}
                </select>
                <div className="input-error">
                  <span className="text-danger">{formerrors.company}</span>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <select
                  id="typeOfContract"
                  onChange={(e) => {
                    setTypeOfContract(e.target.value);
                  }}
                  className="form-select"
                >
                  <option>Sélectionner un type de contrat</option>

                  <option value={"cdd"}>cdd</option>
                  <option value={"cdi"}>cdi</option>
                  <option value={"internship"}>internship</option>
                </select>
                <div className="input-error">
                  <span className="text-danger">{formerrors.company}</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setaddEmployeeModalFromChild("")}
              >
                Annuler
              </button>
              <button
                type="button"
                onClick={sendData}
                className="btn btn-primary"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default CadidatToEmplyee;
