import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { useContext } from "react";
const SideBar = () => {
  const { user, setUser } = useContext(UserContext);
  return (
    <aside id="sidebar" className="sidebar sidebar_img">
      <ul className="sidebar-nav " id="sidebar-nav">
        <li className="nav-item">
          <Link className="nav-link collapsed" to="/">
            <i className="bi bi-house"></i>
            <span>Acceuil </span>
          </Link>
        </li>
        {user != null && user.userpermissions.includes("users") ? (
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/users">
              <i className="bi bi-people"></i>
              <span>Les utlisateurs </span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user != null && user.userpermissions.includes("users") ? (
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/roles">
              <i className="ri-shield-user-line"></i>
              <span>Les roles </span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user != null && user.userpermissions.includes("candidatTypes") ? (
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/types-candidats">
              <i className="ri-group-line"></i>
              <span>Types des candidats </span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user != null && user.userpermissions.includes("candidats") ? (
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/candidats">
              <i className="ri-user-search-line"></i>
              <span>Les candidats </span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user != null && user.userpermissions.includes("candidats") ? (
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/missions">
              <i className="bi bi-person-lines-fill"></i>
              <span>les missions</span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user != null && user.userpermissions.includes("company") ? (
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/company">
              <i className="bi bi-building"></i>
              <span>Les Sociétés </span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user != null && user.userpermissions.includes("employees") ? (
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/employees">
              <i className="ri-user-2-fill"></i>
              <span>Les employés </span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user != null && user.userpermissions.includes("fournisseur") ? (
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/supplier">
              <i className="ri-group-line"></i>
              <span>Les fournisseurs </span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user != null && user.userpermissions.includes("process") ? (
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/process">
              <i className="ri-group-line"></i>
              <span>Les processes d'entretien </span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {/* {user != null && user.userpermissions.includes("employees") ? (
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/employees/Home/">
              <i className="bi bi-house"></i>
              <span>employees home </span>
            </Link>
          </li>
        ) : (
          ""
        )} */}
      </ul>
    </aside>
  );
};

export default SideBar;
