import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import CandidatsFormStep3 from "./CandidatsFormStep3";
import CandidatsFormStep1 from "./CandidatsFormStep1";
import { CandidatContext } from "../../pages/candidat/CreateCandidats";
import { baseURL } from "../../config/config";
import Select from "react-select";

const initFormErrors = {
  status: [""],
  city: [""],
  address: [""],
  cp: [""],
};

const CandidatsFormStep2 = ({ setChildComponent }) => {
  const msg = "Ce champ est obligatoire";
  const [countries, setCountries] = React.useState([]);
  const [cities, setCities] = useState([]);
  const { newCandidat, setNewCandidat } = useContext(CandidatContext);
  const [errorMessage, setErrorMessage] = useState(initFormErrors);

  function getCountries() {
    axios.get(`${baseURL}/apiData/countries`).then((resp) => {
      if (resp.data.error) return;
      resp.data.data.map((item, index) => {
        return setCountries((prevState) => [
          ...prevState,
          { value: item.id, label: item.name },
        ]);
      });
    });
  }

  function getCities(countryid) {
    axios.get(`${baseURL}/apiData/cities/${countryid}`).then((resp) => {
      if (resp.data.error) return;
      resp.data.data.map((item, index) => {
        return setCities((prevState) => [
          ...prevState,
          { value: JSON.stringify(item), label: item.name },
        ]);
      });
    });
  }

  useEffect(() => {
    getCountries();
  }, []);

  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
   
    if (newCandidat.address == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        address: msg,
      }));
    }
    if (newCandidat.city == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        city: msg,
      }));
    }
    if (newCandidat.cp == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        cp: msg,
      }));
    }
    if (error === false) {
      setChildComponent(
        <CandidatsFormStep3 setChildComponent={setChildComponent} />
      );
    }
  }

  const statusOptions = [
    { value: "single", label: "Célibataire" },
    { value: "in_relationship", label: "En couple" },
    { value: "married", label: "Marié" },
    { value: "divorced", label: "Divorcé" },
    { value: "pacs", label: "Pacsé" },
  ];

  return (
    <div>
      <div className="progress" style={{ height: "10px" }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: "66%" }}
          aria-valuenow="66"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="card">
        <div className="card-body pt-5">
          <form className="row g-3">
            <div className="row">
              <div className="col-md-6">
                <label>Etat Civil *</label>
                <Select
                  options={statusOptions}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      status: e.value,
                    }));
                  }}
                />
                <div className="input-error">
                  <span className="text-danger"> {errorMessage.status}</span>
                </div>
              </div>
              <div className="col-md-6 pb-3">
                <label>Adresse *</label>
                <input
                  type="text"
                  value={newCandidat.address}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      address: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger"> {errorMessage.address}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label>pays *</label>
                <Select
                  options={countries}
                  isSearchable
                  onChange={(e) => {
                    getCities(e.value);
                  }}
                />
              </div>
              <div className="col-md-6">
                <label>Ville *</label>
                <Select
                  options={cities}
                  isSearchable
                  onChange={(e) => {
                    const selectedCity = JSON.parse(e.value);
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      city: selectedCity.id,
                      cityname: selectedCity.name,
                    }));
                  }}
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.city}</span>
                </div>
              </div>

              <div className="col-md-6">
                <label>Code postal *</label>
                <input
                  type="number"
                  value={newCandidat.cp}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      cp: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.cp}</span>
                </div>
              </div>
            </div>

            <div className="text-end">
              <button
                type="button"
                className="btn btn-outline-secondary  me-2"
                onClick={() => {
                  setChildComponent(
                    <CandidatsFormStep1 setChildComponent={setChildComponent} />
                  );
                }}
              >
                Retour
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={validate}
              >
                Continuer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CandidatsFormStep2;
