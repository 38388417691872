import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/loader";
import { baseURL } from "../../config/config";
import InfiniteScroll from "react-infinite-scroll-component";
import Employeeslist from "../../components/employeesForm/EmployeesList";
import EmptyData from "../../components/emptyData";
const Employees = () => {
  const [employees, setEmployees] = React.useState([]);
  const [loadedEmployees, setLoadedEmployees] = React.useState([]);
  const [data, setData] = React.useState();
  const [hasMore, setHasMore] = React.useState(true);
  const [filtredEmployees, setFiltredEmployees] = React.useState(null);

  async function getAllEmployees() {
    axios.get(`${baseURL}/employees/employees/all`).then((response) => {
      if (response.data.error) return;
      let respData = response.data.data;
      setEmployees(respData);
      setLoadedEmployees(respData.slice(0, loadedEmployees.length + 6));
      setFiltredEmployees(response.data.data);
    });
  }

  function getLoadedEmployeeList() {
    let loadedEmployeeLength = 0;
    if (employees.length - loadedEmployees.length < 6 && employees.length > 0) {
      loadedEmployeeLength = employees.length;
      setHasMore(false);
    } else {
      loadedEmployeeLength = loadedEmployees.length + 6;
    }
    setLoadedEmployees(employees.slice(0, loadedEmployeeLength));
  }
  useEffect(() => {
    if (employees.length === 0) {
      setData(<EmptyData />);
    }
  }, [employees]);
  useEffect(() => {
    getAllEmployees();
  }, []);
  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Les employés</h1>
          </div>
          <div className="col-md-4">
            <Link to="/ ">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2"
              >
                Retour
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">Employés</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4">
            <Link to="/employees/create">
              <button
                type="button"
                className="btn btn-primary btn-rounded float-end text-sm"
              >
                <i className="bi bi-plus-lg"></i> Ajouter un employé
              </button>
            </Link>
          </div>
        </div>
      </div>
      <section className="section">
        {" "}
        <InfiniteScroll
          dataLength={loadedEmployees.length}
          next={getLoadedEmployeeList}
          hasMore={hasMore}
        >
          <div className="row">
            {loadedEmployees.map((employee, key) => {
              return (
                <Employeeslist
                  key={key}
                  employee={employee}
                  getAllEmployees={getAllEmployees}
                />
              );
            })}
          </div>
        </InfiniteScroll>
      </section>
    </div>
  );
};

export default Employees;
