import React from "react";
import axios from "axios";
import swal from "sweetalert";
import AddRole from "../../components/role/addRole";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import { baseURL } from "../../config/config";

const UpdateUser = () => {
  const [roles, setRoles] = React.useState();
  const [addRoleModal, setaddRoleModal] = React.useState();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [role, setRole] = React.useState({ role: "", id: "" });
  const [avatar, setAvatar] = React.useState();
  const [socialSecurityNumber, setSocialSecurityNumber] = React.useState("");
  const [user, setUser] = React.useState();
  const history = useHistory();
  const { userId } = useParams();
  const initFormErrors = {
    name: [""],
    email: [""],
    phone: [""],
    address: [""],
    socialSecurityNumber: [""],
  };
  const [formerrors, setFormErrors] = React.useState(initFormErrors);

  React.useEffect(() => {
    if (userId === undefined) return;
    axios.get(`${baseURL}/users/${userId}`).then((resp) => {
      if (resp.data.error) return;
      const userData = resp.data.data;
      setName(userData.name);
      setEmail(userData.email);
      setAddress(userData.address);
      setPhone(userData.phone);
      setRole(userData.role._id);
      setSocialSecurityNumber(userData.nirpp);
      setUser(userData);
    });
    getRoles();
  }, [userId]);

  function getRoles() {
    axios.get(`${baseURL}/roles`).then((resp) => {
      if (resp.data.error) return;
      setRoles(resp.data.data);
    });
  }

  function sendData() {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("avatar", avatar);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("role_id", role);
    formData.append("phone", phone);
    formData.append("nirpp", socialSecurityNumber);

    axios
      .put(`${baseURL}/users/${userId}`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setTimeout(() => {
          history.push("/users");
        }, 2400);
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }

  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Modifier un Utilisateur</h1>
          </div>
          <div className="col-md-4">
            <Link to="/users">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2"
              >
                Retour
              </button>
            </Link>
          </div>
        </div>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"}>
                <i className="bi bi-house-door"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/users"}>Utilisateurs</Link>
            </li>
            <li className="breadcrumb-item active">Modifier un Utilisateur</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12"></div>
          <div className="card">
            <div className="card-body pt-3">
              <form className="row g-3">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    value={name != null && name}
                    placeholder="Nom"
                    onChange={(e) => setName(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.name}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <input
                    type="number"
                    className="form-control"
                    value={socialSecurityNumber != null && socialSecurityNumber}
                    placeholder="Numéro de securité social "
                    onChange={(e) => setSocialSecurityNumber(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.nirpp}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group mb-3">
                    <select
                      id="inputState"
                      className="form-select"
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <option selected>Sectionner un rôle ...</option>
                      {roles != null &&
                        roles.map((item, key) => {
                          return (
                            <option
                              selected={role != null && role === item.id}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                    <span className="input-group-text p-0" id="basic-addon1">
                      <button
                        type="button"
                        className="btn btn-"
                        onClick={() =>
                          setaddRoleModal(
                            <AddRole
                              setAddRoleModalFromChild={setaddRoleModal}
                              setRolesList={setRoles}
                            />
                          )
                        }
                      >
                        <i className="bi bi-plus-lg"></i>
                      </button>
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Adresse"
                    value={address != null && address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.address}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Téléphone"
                    value={phone != null && phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.phone}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email != null && email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.email}</span>
                  </div>
                </div>

                <div className="col-md-4">
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setAvatar(e.target.files[0])}
                  />
                </div>

                <div className="text-end">
                  <Link to="/users">
                    <button
                      type="button"
                      className="btn btn-outline-secondary  me-2"
                    >
                      Annuler
                    </button>
                  </Link>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={sendData}
                  >
                    Enregistrer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {addRoleModal}
      </section>
    </div>
  );
};
export default UpdateUser;
