import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../config/config";
import { useParams } from "react-router";
import CandidatDetailsComponent from "../../components/candidat/CandidatDetailsComponent";
import ExperienceDetails from "../../components/experience/ExperienceDetails";
import CreateNote from "../../components/candidat/candidatNote";
import swal from "sweetalert";

const CandidatDetails = () => {
  const [candidat, setCandidat] = React.useState([]);
  const { candidatId } = useParams();
  const [city1, setCity1] = useState("");
  const [addExperienceModal, setAddExperienceModal] = React.useState();
  const [childComponent, setChildComponent] = React.useState();
  const [activeDetails, setActiveDetails] = React.useState("active");
  const [activeExperience, setActiveExperience] = React.useState("");
  const [addNote, setAddNote] = React.useState();

  async function getCandidatById() {
    if (candidatId === undefined) return;
    axios
      .get(`${baseURL}/candidates/${candidatId}`)
      .then((response) => {
        if (response.data.error) return;
        setCity1(response.data.data.city.name);
        setCandidat(response.data.data);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getCandidatById();
  }, []);

  useEffect(() => {
    if (activeDetails === "active") {
      setChildComponent(<CandidatDetailsComponent candidat={candidat} />);
    } else if (activeExperience === "active") {
      <ExperienceDetails candidat_id={candidatId} />;
    }
  }, [candidat]);

  async function enableDisable(candidatId, isEnabled) {
    let message =
      isEnabled == "yes"
        ? "voulez vous désactiver ce candidat?"
        : "Voulez vous activer ce candidat?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/candidates/toggleStatus/${candidatId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getCandidatById();
            }
          });
      }
    });
  }

  let tel = `tel:+${candidat.phone}`;
  let mailto = `mailto:${candidat.email}`;

  return (
    <div>
      <div className="pagetitle row">
        <div className="col-md-8">
          <h1>Détails du candidat</h1>
        </div>
        <div className="col-md-4">
          <Link to="/candidats">
            <button
              type="button"
              className="btn btn-outline-secondary mb-2 float-end  me-2"
            >
              Retour
            </button>
          </Link>
        </div>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/candidats">les candidats</Link>
            </li>
          </ol>
        </nav>
      </div>

      <section className="section candidat">
        <div className="row">
          <div className="col-xl-3">
            <div className="card-candidat">
              <div className="card-body candidat-card pt-4 d-flex flex-column align-items-center text-uppercase">
                <div className="row">
                  <div className="col-10 d-flex justify-content-center">
                    <img
                      src={candidat.avatar != null && candidat.avatar}
                      className="rounded-circle"
                    />
                  </div>
                  <div className="col-1">
                    <Link
                      className="col-3 btn text-primary "
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Modifier candidat"
                      to={`/candidats/edit/${candidat._id}`}
                    >
                      <i className="bi bi-pen"></i>
                    </Link>
                    <button
                      className="col-3 btn text-primary  btn"
                      onClick={() => {
                        enableDisable(candidat._id, candidat.isEnabled);
                      }}
                    >
                      {candidat.isEnabled == "yes" ? (
                        <i
                          className="text-success bi bi-toggle2-on"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Désactiver candidat"
                        ></i>
                      ) : (
                        <i
                          className="text-danger bi bi-toggle2-off"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Activer candidat"
                        ></i>
                      )}
                    </button>
                    <button
                      className="col-3 btn text-warning"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Créer note"
                      onClick={() => {
                        setAddNote(
                          <CreateNote
                            setAddNoteModalFromChild={setAddNote}
                            candidatId={candidat._id}
                          />
                        );
                      }}
                    >
                      <i className="ri-sticky-note-line"></i>
                    </button>
                  </div>
                </div>
                <h2>
                  {candidat != null && candidat.lastname} {candidat.firstname}
                </h2>
                <h6 className="small  fw-bold">
                  {candidat.type != null && candidat.type.name}
                </h6>
              </div>

              <div className="row mb-4  d-flex flex-column align-items-center">
                <div className="col-md-12 d-flex justify-content-center ">
                  <i className="bi-envelope-fill me-1"></i>
                  <a
                    href={mailto}
                    className="color-jcit "
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Envoyer mail"
                  >
                    {candidat != null && candidat.email}
                  </a>
                </div>
                <div
                  className="col-md-10 mb-4 d-flex justify-content-center"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Appeler"
                >
                  <i className="bi-telephone-fill me-1"></i>
                  <a href={tel} className="color-jcit ">
                    {candidat != null && candidat.phone}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-9">
            <div className="card">
              <div className="card-body pt-3">
                <div className="row ps-3">
                  <ul className="nav nav-tabs nav-tabs-bordered col-10">
                    <li className="nav-item">
                      <button
                        className={`nav-link ${activeDetails}`}
                        data-bs-toggle="tab"
                        data-bs-target="#profile-overview"
                        onClick={() => {
                          setActiveDetails("active");
                          setChildComponent(
                            <CandidatDetailsComponent candidat={candidat} />
                          );
                        }}
                      >
                        Détailles
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link ${activeExperience}`}
                        data-bs-toggle="tab"
                        data-bs-target="#profile-overview"
                        onClick={() => {
                          setActiveDetails("");
                          setActiveExperience("active");
                          setChildComponent(
                            <ExperienceDetails candidat_id={candidatId} />
                          );
                        }}
                      >
                        Expériences
                      </button>
                    </li>
                  </ul>
                </div>
                {childComponent}
              </div>
            </div>
          </div>
        </div>
      </section>
      {addExperienceModal}
      {addNote}
    </div>
  );
};

export default CandidatDetails;
