import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL, MatchingServer } from "../../config/config";
import Loader from "../loader";
function TelechargerModal({ setTelechargerCandidatModalFromChild }) {
  const [file, setFile] = useState();
  const [src, setSrc] = useState();
  const [error, setError] = useState();
  const [loader, setLoader] = useState(<Loader />);
  //   function previewFile() {
  //     const reader = new FileReader();
  //     if (file) {
  //       reader.readAsDataURL(file);
  //       setSrc(reader.result);
  //     }
  //   }
  //   useEffect(() => {
  //     previewFile();
  //   }, [file]);
  function sendData() {
    if (!file) {
      setError("ce champs est obligatoire");
    } else {
      if (!error) {
        let data = new FormData();
        data.append("cv", file);
        swal("Telechargement encours", {
          html: "{ loader }",
          timer: 3000,
        });
        axios.post(`${baseURL}/cv/add`, data).then(async (resp) => {
          await axios
            .get(`${baseURL}/candidates/getcv/${resp.data.cvId}`)
            .then((res) => {
              const data = new FormData();
              const text = res.data.data[0];
              data.append("email", text.email);
              data.append("name", text.Name);
              data.append(
                "phone",
                text.Phonenumber[0] ? text.Phonenumber[0] : "0000000000"
              );
              data.append("skills", text.Skills);
              data.append("experience", text.Experience);
              data.append("education", text.Education);
              data.append("diplome", text.Degree[0] + text.Degree[1]);
              data.append("type", "Draft");
              data.append("cv", resp.data.cvId);
              console.log(data);
              axios.post(`${baseURL}/candidates/create`, data).then((res) => {
                swal("CV telecharge avec succes", {
                  icon: "success",
                  timer: 3000,
                });
              });
            });
        });
      }
    }
  }
  return (
    <div>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="d-flex fw-bold justify-content-center mb-5">
                Telecharger Un Candidat
              </h3>
              <div className="row">
                <div className="col-md-6">
                  <span>Upload CV *</span>

                  <div className="custom-file">
                    <input
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                        setError(null);
                      }}
                      className="form-control"
                    />
                  </div>

                  <div className="input-error">
                    <span className="text-danger">{error}</span>
                  </div>
                </div>
                {/* <div className="cold-md-6">
                  {file ? (
                    <iframe src={src} width="300px" height="300px" />
                  ) : null}
                </div> */}
              </div>

              <div className="modal-footer mt-4">
                <button
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setTelechargerCandidatModalFromChild("");
                  }}
                >
                  Annuler
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={sendData}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}
export default TelechargerModal;
