import React, { useContext, useState, useEffect } from "react";
import { SupplierContext } from "../../pages/supplier/CreateSupplier";

import { useHistory } from "react-router";
import axios from "axios";
import swal from "sweetalert";
import AlertComponentSupplier from "./AlertComponentSupplier";
import SupplierFormStep4 from "./SupplierFormStep4";
import { baseURL } from "../../config/config";
const initFormErrors = {
  name: [""],
  email: [""],
  phone: [""],
  address: [""],
  cp: [""],
  birthDay: [""],
  lieu_naissance: [""],
  diplome: [""],
  annéeGraduation: [""],
  type: [""],
  langues: [""],
  avatar: [""],
  nirpp: [""],
  typeOfContract: [""],
  startDate: [""],
  salary: [""],
  job: [""],
  company: [""],
};
const ConfirmSupplier = ({ setChildComponent }) => {
  const { newSupplier, setNewSupplier } = useContext(SupplierContext);
  const [pays, setPays] = useState("");

  const history = useHistory();
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  const [errorAlert, setErrorAlert] = React.useState("");

  function sendData() {
    const formData = new FormData();
    formData.append("gender", newSupplier.gender);
    formData.append("fname", newSupplier.firstname);
    formData.append("lname", newSupplier.lastname);
    formData.append("email", newSupplier.email);
    formData.append("pays", pays);
    formData.append("city", newSupplier.city);
    formData.append("address", newSupplier.address);
    formData.append("cp", newSupplier.cp);
    formData.append("phone", newSupplier.phone);
    formData.append("birthday", newSupplier.birthDay);
    formData.append("birthPlace", newSupplier.birthPlace);
    formData.append("diplomeDate", newSupplier.diplomeDate);
    formData.append("diplome", newSupplier.diplome);
    formData.append("type", newSupplier.type);
    formData.append("langues", newSupplier.langues);
    formData.append("status", newSupplier.status);
    formData.append("skills", newSupplier.skills);
    formData.append("experience", newSupplier.experience);
    formData.append("avatar", newSupplier.avatar);
    formData.append("nirpp", newSupplier.nirpp);
    formData.append("job", newSupplier.job);
    formData.append("startDate", newSupplier.startDate);
    formData.append("salary", newSupplier.salary);
    formData.append("typeOfContract", newSupplier.typeOfContract);
    formData.append("company", newSupplier.company);
    axios
      .post(`${baseURL}/employees/create`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setTimeout(() => {
          history.push("/supplier");
        }, 2400);
      })
      .catch((error) => {
        const errors = error.response.data;
        setFormErrors(errors);
      });
  }

  useEffect(() => {
    if (formerrors.email == "") return;
    setErrorAlert(<AlertComponentSupplier errors={formerrors} />);
  }, [formerrors]);

  return (
    <div>
      {" "}
      {errorAlert}
      <section className="section">
        <div className="row">
          <h1 className="text-center color-jcit fw-bolder pb-5">
            Fiche de fournisseur
          </h1>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <div className="row pb-3">
                  <div className="col-md-5">
                    <div>
                      <label className="color-jcit fw-bold">
                        Nom et Prénom :{" "}
                      </label>{" "}
                      <span>
                        {newSupplier.lastname} {newSupplier.firstname}
                      </span>
                    </div>
                    <div>
                      <label className="color-jcit fw-bold ">
                        Type de l'employé :{" "}
                      </label>{" "}
                      {newSupplier.type}
                    </div>
                  </div>
                  <div className="col-md-5">
                    {" "}
                    <div>
                      <label className="color-jcit fw-bold">Email : </label>{" "}
                      {newSupplier.email}
                    </div>
                    <div>
                      <label className="color-jcit fw-bold">Téléphone : </label>{" "}
                      {newSupplier.phone}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <h3 className="pb-2">Informations personnelles</h3>

                <div className="row pb-3">
                  <div className="col-md-2">
                    <div>
                      <label className="color-jcit fw-bold">Genre : </label>{" "}
                      {newSupplier.gender}
                    </div>{" "}
                    <div>
                      <label className="color-jcit fw-bold">NIRPP : </label>{" "}
                      {newSupplier.nirpp}
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div>
                      <label className="color-jcit fw-bold">
                        Etat civil :{" "}
                      </label>{" "}
                      {newSupplier.status}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <label className="color-jcit fw-bold">Ville : </label>{" "}
                      {newSupplier.cityName}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <label className="color-jcit fw-bold">Adresse : </label>{" "}
                      {newSupplier.address}
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div>
                      <label className="color-jcit fw-bold">
                        Code postal :{" "}
                      </label>{" "}
                      {newSupplier.cp}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <h3 className="pb-2">Informations professionnelle</h3>
                <div className="row pb-3">
                  <div className="col-md-3">
                    <div>
                      <label className="color-jcit fw-bold">
                        Expérience :{" "}
                      </label>
                      {newSupplier.experience}
                    </div>
                    <div>
                      <label className="color-jcit fw-bold">Poste : </label>
                      {newSupplier.job}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div>
                      <label className="color-jcit fw-bold">
                        Compétence :{" "}
                      </label>
                      {newSupplier.skills}
                    </div>
                    <div>
                      <label className="color-jcit fw-bold">
                        Date début travail :{" "}
                      </label>
                      {newSupplier.startDate}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div>
                      <label className="color-jcit fw-bold">Diplome:</label>
                      {newSupplier.diplome}
                    </div>
                    <div>
                      <label className="color-jcit fw-bold">Salaire : </label>
                      {newSupplier.salary}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div>
                      <label className="color-jcit fw-bold">
                        Année de diplome :{" "}
                      </label>
                      {newSupplier.diplomeDate}
                    </div>
                    <div>
                      <label className="color-jcit fw-bold">
                        Type de contrat :{" "}
                      </label>
                      {newSupplier.typeOfContract}
                    </div>
                  </div>
                  <div>
                    <div>
                      <label className="color-jcit fw-bold">langue : </label>
                      {newSupplier.langues}
                    </div>
                  </div>
                  <div>
                    <div>
                      <label className="color-jcit fw-bold">
                        nom du société :{" "}
                      </label>
                      {newSupplier.companyname}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="text-end">
          <button
            type="button"
            className="btn btn-outline-secondary  me-2"
            onClick={() => {
              setChildComponent(
                <SupplierFormStep4 setChildComponent={setChildComponent} />
              );
            }}
          >
            Retour
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              sendData();
            }}
          >
            Confirmer
          </button>
        </div>
      </section>
    </div>
  );
};

export default ConfirmSupplier;
