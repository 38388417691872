import axios from "axios";
import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import EmptyData from "../../components/emptyData";
import Loader from "../../components/loader";
import { baseURL } from "../../config/config";

const Company = () => {
  const [company, setCompany] = React.useState(null);
  const [data, setData] = React.useState();

  async function getAllCompany() {
    axios
      .get(`${baseURL}/companies`)
      .then((response) => {
        if (response.data.error) return;
        setCompany(response.data.data);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    setData(<Loader />);
    getAllCompany();
  }, []);

  useEffect(() => {
    if (company == null) return;
    if (company.length > 0) {
      setData(<DataTable columns={columns} data={company} pagination />);
    } else {
      setData(<EmptyData />);
    }
  }, [company]);

  async function enableDisable(companyId, isEnabled) {
    let message =
      isEnabled == "yes"
        ? "voulez vous Désactiver cette sociètè?"
        : "Voulez vous Activer cette sociètè?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/companies/toggleStatus/${companyId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllCompany();
            }
          });
      }
    });
  }

  const columns = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "5%",
      sortable: true,
    },
    {
      name: "Nom",
      selector: (row) => (
        <div className="row">
          <Link className="color-jcit " to={`/company/details/${row.id}`}>
            <img
              src={row.logo}
              className="rounded-circle"
              width="35"
              height="35"
            />{" "}
            {row.name}
          </Link>
        </div>
      ),
      width: "15%",
      sortable: true,
    },
    {
      name: "Responsable",
      selector: (row) => row.owner,
      width: "15%",
      sortable: true,
    },
    {
      name: "Téléphone",
      selector: (row) => (
        <a
          href={`tel:${row.phone}`}
          className="color-jcit "
          data-toggle="tooltip"
          data-placement="top"
          title="Appeler"
        >
          {" "}
          <i className="bi bi-telephone-fill"></i> {row.phone}
        </a>
      ),
      width: "15%",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => (
        <a
          href={`mailto:${row.email}`}
          className="color-jcit "
          data-toggle="tooltip"
          data-placement="top"
          title="Envoyer mail"
        >
          {" "}
          <i className="bi bi-envelope-fill"></i> {row.email}
        </a>
      ),
      width: "15%",
      sortable: true,
    },
    {
      name: "Ville",
      selector: (row) => row.city,
      width: "20%",
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="row col-12">
          <Link
            className="col-3 btn text-primary me-2"
            data-toggle="tooltip"
            data-placement="top"
            title="Modifier société"
            to={`/company/update/${row.id}`}
          >
            <i className="bi bi-pen"></i>
          </Link>
          <button
            className="col-3 btn text-primary me-2 btn"
            onClick={() => {
              enableDisable(row.id, row.isEnabled);
            }}
          >
            {row.isEnabled == "yes" ? (
              <i
                className="text-success bi bi-toggle2-on"
                data-toggle="tooltip"
                data-placement="top"
                title="Désactiver société"
              ></i>
            ) : (
              <i
                className="text-danger bi bi-toggle2-off"
                data-toggle="tooltip"
                data-placement="top"
                title="Activer société"
              ></i>
            )}
          </button>
          <Link
            className="col-3 btn text-primary me-2"
            data-toggle="tooltip"
            data-placement="top"
            title="Profile société"
            to={`/company/details/${row.id}`}
          >
            <i className="ri-arrow-right-s-fill"></i>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Les Société</h1>
          </div>
          <div className="col-md-4">
            <Link to="/">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2"
              >
                Retour
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">Société</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4">
            <Link to="/company/create">
              <button
                type="button"
                className="btn btn-primary btn-rounded float-end text-sm"
              >
                <i className="bi bi-plus-lg"></i> Ajouter
              </button>
            </Link>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body  pt-3">{data}</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Company;
