import { Link } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseURL, MatchingServer } from "../../config/config";

const Employeeslist = ({ employee, getAllEmployees }) => {
  const [employeeId, setEmployeeId] = React.useState();
  const [NewUser, setNewUser] = React.useState();
  const [employeetoadd, setEmployeeToAdd] = useState("");
  const [employeusers, setemployeUsers] = useState([]);

  async function getAllemployeUsers() {
    let tab = [];
    axios
      .get(`${MatchingServer}/employeeUser/Allemployeeuses`)
      .then((response) => {
        if (response.data.error) return;
        response.data.data.map((emp) => {
          tab.push(emp.employee._id);
        });
        setemployeUsers(tab);
      })
      .catch((err) => {});
  }
  useEffect(() => {
    getAllemployeUsers();
  }, []);
  async function enableDisable(employeeId, isEnabled) {
    let message =
      isEnabled == "yes"
        ? "voulez vous Désactiver ce employé?"
        : "Voulez vous Activer ce employé?";
    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/employees/toggleStatus/${employeeId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllEmployees();
            }
          });
      }
    });
  }
  async function employeetouser(employeeId) {
    swal({
      title: "voulez vous definire ce employé comme un utilisateur ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        setEmployeeId(employeeId);
      }
    });
  }
  async function getDetailsEmployee(employeeId) {
    if (employeeId === undefined) return;
    axios.get(`${baseURL}/employees/${employeeId}`).then((response) => {
      if (response.data.error) return;
      setEmployeeToAdd(response.data.data);
    });
  }
  async function sendData() {
    const userName = employeetoadd.fname + " " + employeetoadd.lname;
    axios
      .post(`${MatchingServer}/users/create`, {
        name: userName,
        avatar: employeetoadd.avatarid,
        email: employeetoadd.email,
        address: employeetoadd.address,
        phone: employeetoadd.phone,
        nirpp: employeetoadd.nirpp.toString(),
        employeeId: employeeId,
      })
      .then((resp) => {
        if (resp.data.error) return;
        setNewUser(resp.data.data);
        swal(resp.data.message1, {
          icon: "success",
          timer: 2500,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getDetailsEmployee(employeeId);
  }, [employeeId]);

  useEffect(() => {
    sendData();
  }, [employeetoadd]);

  return (
    <div className="col-md-6 col-sm-12">
      <div className="info-box card employee-card-border mx_heigth">
        <div className="card-title d-flex justify-content-between px-4 pb-0 m-0">
          <div className="fw-bold text-dark">
            <Link
              className="color-jcit"
              to={`/employees/details/${employee.id}`}
            >
              {window.innerWidth <= 361 ? (
                <div>
                  {" "}
                  {employee.fname}
                  <br></br>
                  {employee.lname}
                </div>
              ) : (
                employee.fname + " " + employee.lname
              )}
            </Link>
          </div>
          <div>
            <Link
              to={"/employees/update/" + employee.id}
              className="btn text-primary"
              data-toggle="tooltip"
              data-placement="top"
              title="Modifier employée"
            >
              <i className="bi bi-pen"></i>
            </Link>
            <button
              type="button"
              className="btn text-primary"
              data-toggle="tooltip"
              data-placement="top"
              title="Désactiver employée"
              onClick={() => {
                setEmployeeId(employee.id);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-person-add"
                viewBox="0 0 16 16"
              >
                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
              </svg>
            </button>
            {employee.isEnabled === "yes" ? (
              <button
                type="button"
                className="btn text-success"
                data-toggle="tooltip"
                data-placement="top"
                title="Désactiver employée"
                onClick={() => {
                  enableDisable(employee.id, employee.isEnabled);
                }}
              >
                <i className="bi bi-toggle2-on"></i>
              </button>
            ) : (
              <button
                type="button"
                className="btn text-danger"
                data-toggle="tooltip"
                data-placement="top"
                title="Activer employée"
                onClick={() => {
                  enableDisable(employee.id, employee.isEnabled);
                }}
              >
                <i className="bi bi-toggle2-off"></i>
              </button>
            )}
            <Link
              className="btn text-default "
              data-toggle="tooltip"
              data-placement="top"
              title="Profile employée"
              to={`/employees/details/${employee.id}`}
            >
              <i className="ri-eye-fill"></i>
            </Link>

            {employeusers.includes(employee.id) ? (
              <button
                className=" btn text-primary "
                onClick={() => {
                  employeetouser(employee.id);
                }}
                disabled
              >
                <i
                  className="bi bi-person-check-fill"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="l'employée est un utilisateur "
                ></i>
              </button>
            ) : (
              <button
                className=" btn text-primary "
                onClick={() => {
                  employeetouser(employee.id);
                }}
              >
                <i
                  className="bi bi-person-plus-fill"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="ajouter l'employée comme un utilisateur "
                ></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-4">
              <img className="heigth_img" src={employee.avatar} width="100%" />
            </div>
            <div className="col-8">
              <div className="mb-1">
                <a
                  href={`mailto:${employee.email}`}
                  className="color-jcit "
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Envoyer mail"
                >
                  {" "}
                  <i className="bi bi-envelope-fill"></i> {employee.email}
                </a>
              </div>
              <div className="mb-1">
                <a
                  href={`tel:+${employee.phone}`}
                  className="color-jcit "
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Appeler"
                >
                  {" "}
                  <i className="bi bi-telephone-fill"></i> {employee.phone}
                </a>
              </div>
              <div className="mb-1">
                <i className="bi bi-geo-alt  me-1"></i>
                <span>{employee.address}</span>
              </div>
              <div className="mb-1">
                <i className="bi bi-briefcase me-1"></i>
                <span>
                  {employee.job} ({employee.experience}ans)
                </span>
              </div>
              <div>
                {employee.isEnabled === "yes" ? (
                  <span className="badge bg-success">Actif</span>
                ) : (
                  <span className="badge bg-danger">Inactif</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Employeeslist;
