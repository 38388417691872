import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";

function CreateNote({ setAddNoteModalFromChild, candidatId }) {
  const [note, setNote] = useState("");

  React.useEffect(() => {
    axios.get(`${baseURL}/candidates/note/${candidatId}`).then((resp) => {
      if (resp.data.error) return;
      setNote(resp.data.data.note);
    });
  }, []);

  function sendData() {
    axios
      .post(`${baseURL}/candidates/note/${candidatId}`, { note: note })
      .then((resp) => {
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setAddNoteModalFromChild("");
      });
  }
  return (
    <div>
      <div
        className="modal fade show"
        id="role_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <textarea
                type="text"
                className="form-control"
                id="role_name"
                value={note}
                placeholder="veuillez saisir une note"
                onChange={(e) => setNote(e.target.value)}
                required
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setAddNoteModalFromChild("")}
              >
                Annuler
              </button>
              <button
                type="button"
                onClick={sendData}
                className="btn btn-primary"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default CreateNote;
