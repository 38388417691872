import axios from "axios";
import React, {useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {  useParams } from "react-router";
import { baseURL } from "../../config/config";

const CompanyDetailsComponent = () => {
  const [company, setCompany] = React.useState([]);
  const [data, setData] = React.useState();
  const { companyId } = useParams();

  async function getAllCompany() {
    if (companyId === undefined) return;
    axios
      .get(`${baseURL}/companies/${companyId}`)
      .then((response) => {
        if (response.data.error) return;
        setCompany(response.data.data);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getAllCompany();
  }, []);

  useEffect(() => {
    setData(<DataTable columns={columns} data={company} pagination />);
  }, [company]);

  const columns = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "5%",
      sortable: true,
    },
  ];

  return (
    <div className="tab-content pt-2">
      <div
        className="tab-pane fade show active profile-overview"
        id="profile-overview"
      >
        <Link
          className="col-2 btn text-primary float-end"
          to={`/company/update/${companyId}`}
        >
          <i className="bi bi-pen"></i>
        </Link>

        <h5 className="card-title">Description</h5>
        <p className="small fst-italic">
          {company != null && company.description}
        </p>

        <h5 className="card-title">Détails du Société</h5>
        <div className="row col-md-12">
          <div className="row col-md-12 flex">
            <div className="row">
              <div className="col-lg-7 col-md-7 label">Siren</div>
              <div className="col-lg-5 col-md-5">
                {company != null && company.siren}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-7 col-md-7 label">Siret</div>
              <div className="col-lg-5 col-md-5">
                {company != null && company.siret}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-7 col-md-7 label">
                Numéro TVA intracommunautaire
              </div>
              <div className="col-lg-5 col-md-5">
                {company != null && company.tva_number}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-7 col-md-7 label">Numéro Rcs</div>
              <div className="col-lg-5 col-md-5">
                {company != null && company.rcs}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetailsComponent;
