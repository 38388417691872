/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import DataTable from "react-data-table-component";

import { baseURL, MatchingServer } from "../config/config";
import { Link } from "react-router-dom";
import EmptyData from "./emptyData";
import { FollowUp } from "./FollowUp";
function FollowUpTimesheet({ SetFollowUpModalFromChild, employeeId }) {
  const initFormErrors = {
    name: [""],
    etape: [""],
  };
  const [formerrors, setFormErrors] = useState(initFormErrors);
  const [missions, setMissions] = useState();
  const [data, setData] = React.useState();
  const [workDays, setWorkDays] = React.useState();
  const [allData, setAllData] = React.useState({});
  const [tableData, setTableData] = React.useState({});

  async function getMissionByEmplyeeId() {
    let id = employeeId;
    await axios
      .get(`${baseURL}/missionEmployees/missions/${id}`)
      .then((response) => {
        if (response.data.error) return;
        setMissions(response.data.data);
        getWorkDays();
        // if (workDays && response.data.data) {
        //   for (const missionKey in response.data.data) {
        //     console.log("Mapping data...");
        //     const mission = response.data.data[missionKey];
        //     console.log(mission);
        //     let hours = 0;
        //     for (const workdayKey in workDays) {
        //       console.log("Mapping workDays...");
        //       const workday = workDays[workdayKey];
        //       console.log(workday);

        //       if (workday.extendedProps.mission._id === mission.mission._id) {
        //         hours += workday.extendedProps.hour;
        //       }
        //     }

        //     setAllData((prevState) => {
        //       const newState = {
        //         ...prevState,
        //         [mission.mission.title]: {
        //           mission: mission.mission,
        //           hours: hours,
        //         },
        //       };
        //       console.log("New state:", newState);
        //       return newState;
        //     });
        //   }
        // }
      })
      .catch((err) => console.log(err));
  }
  // async function getMissionByEmplyeeId() {
  //   let id = employeeId;
  //   await axios
  //     .get(`${baseURL}/missionEmployees/missions/${id}`)
  //     .then((response) => {
  //       if (response.data.error) return;
  //       setMissions(response.data.data);
  //       getWorkDays();
  //       if (workDays && response.data.data) {
  //         for (const missionKey in response.data.data) {
  //           const mission = response.data.data[missionKey];
  //           let hours = 0;
  //           for (const workdayKey in workDays) {
  //             const workday = workDays[workdayKey];
  //             if (workday.extendedProps.mission._id === mission.mission._id) {
  //               hours += workday.extendedProps.hour;
  //             }
  //           }

  //           setAllData((prevState) => {
  //             return {
  //               ...prevState,
  //               [mission.mission.title]: {
  //                 mission: mission.mission,
  //                 hours: hours,
  //               },
  //             };
  //           });
  //         }
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }
  async function getWorkDays() {
    let id = employeeId;

    await axios
      .get(`${MatchingServer}/workdays/days/${id}`)
      .then((response) => {
        if (response.data.error) return;
        setWorkDays(response.data.data);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    if (workDays && missions) {
      for (const missionKey in missions) {
        const mission = missions[missionKey];
        let hours = 0;
        let hoursnotworked = 0;
        let days = 0;
        let daysnotworked = 0;
        for (const workdayKey in workDays) {
          const workday = workDays[workdayKey];
          if (workday.extendedProps.mission._id === mission.mission._id) {
            if (workday.extendedProps.type === "maladie") {
              hoursnotworked += 8;
              daysnotworked++;
            } else {
              if (workday.extendedProps.type === "travaile") {
                days++;
                hours += workday.extendedProps.hour;
              } else {
                daysnotworked++;
              }
            }
          }
        }

        setAllData((prevState) => {
          return {
            ...prevState,
            [mission.mission.title]: {
              mission: mission.mission,
              hours: hours,
              hoursnotworked: hoursnotworked,
              days: days,
              daysnotworked: daysnotworked,
            },
          };
        });
      }
    }
  }, [workDays]);
  useEffect(() => {
    console.log("allData", allData);
  }, [allData]);
  useEffect(() => {
    console.log("tab", tableData);
  }, [tableData]);

  useEffect(() => {
    getMissionByEmplyeeId();
  }, [employeeId]);

  return (
    <div>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="d-flex fw-bold justify-content-center mb-5">
                Suivie
              </h3>
              {allData && <FollowUp allData={allData} />}
              <div className="row"></div>
              <div className="modal-footer mt-4">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    SetFollowUpModalFromChild("");
                  }}
                >
                  fermer
                </button>

                {/* <button
                  type="button"
                  className="btn btn-primary"
                  // onClick={validate}
                >
                  Enregistrer */}
                {/* </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}
export default FollowUpTimesheet;
