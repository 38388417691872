import React, { createContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import SupplierFormStep1 from "../../components/supplierForm/SupplierFormStep1";
export const SupplierContext = createContext({
  gender: "",
  firstname: "",
  lastname: "",
  email: "",
  city: "",
  cityname: "",
  address: "",
  cp: "",
  phone: "",
  birthDay: "",
  birthPlace: "",
  diplomeDate: "",
  diplome: "",
  typename: "",
  langues: "",
  status: "",
  skills: "",
  experience: "",
  avatar: "",
  cv: "",
});

const CreateSupplier = () => {
  const [childComponent, setChildComponent] = useState();

  const [newSupplier, setNewSupplier] = useState({
    gender: "",
    firstname: "",
    lastname: "",
    email: "",
    city: "",
    cityname: "",
    address: "",
    cp: "",
    phone: "",
    birthDay: "",
    birthPlace: "",
    diplomeDate: "",
    diplome: "",
    type: "",
    typename: "",
    langues: "",
    status: "",
    skills: "",
    experience: "",
    avatar: "",
    cv: "",
  });

  useEffect(() => {
    setChildComponent(
      <SupplierFormStep1 setChildComponent={setChildComponent} />
    );
  }, []);

  const value = useMemo(
    () => ({ newSupplier, setNewSupplier }),
    [newSupplier, setNewSupplier]
  );
  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Ajouter fournisseur</h1>
          </div>
          <div className="col-md-4">
            <Link to="/supplier">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2"
              >
                Retour
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/Supplier"}>Fournisseurs</Link>
                </li>
                <li className="breadcrumb-item active">Ajouter fournisseur</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="row">
          <div>
            <div>
              <SupplierContext.Provider value={value}>
                {childComponent}
              </SupplierContext.Provider>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateSupplier;
