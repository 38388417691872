import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { SupplierContext } from "../../pages/supplier/CreateSupplier";
import SupplierFormStep1 from "./SupplierFormStep1";
import SupplierFormStep3 from "./SupplierFormStep3";
import { baseURL } from "../../config/config";
const initFormErrors = {
  status: [""],
  city: [""],
  address: [""],
  cp: [""],
  nirpp: [""],
};
const SupplierFormStep2 = ({ setChildComponent }) => {
  const msg = "Ce champ est obligatoire";
  const [countries, setCountries] = React.useState();
  const [city, setCity] = useState([]);
  const { newSupplier, setNewSupplier } = useContext(SupplierContext);
  const [errorMessage, setErrorMessage] = useState(initFormErrors);

  function getCountries() {
    axios.get(`${baseURL}/apiData/countries`).then((resp) => {
      if (resp.data.error) return;
      setCountries(resp.data.data);
    });
  }

  const handlecountry = (event) => {
    const getcountryid = event.target.value;
    getCities(getcountryid);
  };

  function getCities(countryid) {
    axios.get(`${baseURL}/apiData/cities/${countryid}`).then((resp) => {
      if (resp.data.error) return;
      setCity(resp.data.data);
    });
  }

  useEffect(() => {
    getCountries();
  }, []);

  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
    if (newSupplier.status == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        status: msg,
      }));
    }
    if (newSupplier.address == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        address: msg,
      }));
    }
    if (newSupplier.city == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        city: msg,
      }));
    }
    if (newSupplier.cp == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        cp: msg,
      }));
    }
    if (newSupplier.nirpp == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        nirpp: msg,
      }));
    }
    if (error === false) {
      setChildComponent(
        <SupplierFormStep3 setChildComponent={setChildComponent} />
      );
    }
  }
  return (
    <div>
      <div className="card">
        <div className="card-body pt-5">
          <form className="row g-3">
            <div className="row">
              <div className="col-md-6">
                <label>Etat Civil *</label>
                <select
                  id="inputEtatCivil"
                  value={newSupplier.status}
                  className="form-select"
                  onChange={(e) => {
                    setNewSupplier((prevState) => ({
                      ...prevState,
                      status: e.target.value,
                    }));
                  }}
                >
                  <option selected>{""}</option>
                  <option value="single">Célibataire</option>
                  <option value="in_relationship">En couple</option>
                  <option value="married">Marié</option>
                  <option value="divorced">Divorcé</option>
                  <option value="pacs">Pacsé</option>
                </select>
                <div className="input-error">
                  <span className="text-danger"> {errorMessage.status}</span>
                </div>
              </div>
              <div className="col-md-6 pb-3">
                <label>Adresse *</label>
                <input
                  type="text"
                  value={newSupplier.address}
                  onChange={(e) => {
                    setNewSupplier((prevState) => ({
                      ...prevState,
                      address: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger"> {errorMessage.address}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label>pays *</label>
                <div className="input-group mb-3">
                  <select
                    id="inputState"
                    value={newSupplier.countries}
                    className="form-select"
                    onChange={(e) => handlecountry(e)}
                  >
                    <option selected>Sélectionner un pays</option>
                    {countries != null &&
                      countries.map((type, index) => {
                        return (
                          <option key={index} value={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <label>Ville *</label>
                <div className="input-group mb-3">
                  <select
                    id="inputState"
                    value={newSupplier.selectedCity}
                    onChange={(e) => {
                      const selectedCity = JSON.parse(e.target.value);
                      setNewSupplier((prevState) => ({
                        ...prevState,
                        city: selectedCity.id,
                        cityName: selectedCity.name,
                      }));
                    }}
                    className="form-select"
                  >
                    <option selected>Sélectionner une ville</option>
                    {city != null &&
                      city.map((gtcity, index) => {
                        return (
                          <option key={index} value={JSON.stringify(gtcity)}>
                            {gtcity.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.city}</span>
                </div>
              </div>

              <div className="col-md-6">
                <label>Code postal *</label>
                <input
                  type="number"
                  value={newSupplier.cp}
                  onChange={(e) => {
                    setNewSupplier((prevState) => ({
                      ...prevState,
                      cp: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.cp}</span>
                </div>
              </div>

              <div className="col-md-6">
                <label>NIRPP *</label>
                <input
                  type="number"
                  value={newSupplier.nirpp}
                  onChange={(e) => {
                    setNewSupplier((prevState) => ({
                      ...prevState,
                      nirpp: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.nirpp}</span>
                </div>
              </div>
            </div>

            <div className="text-end mt-2">
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => {
                  setChildComponent(
                    <SupplierFormStep1 setChildComponent={setChildComponent} />
                  );
                }}
              >
                Retour
              </button>
              <button
                type="button"
                className="btn btn-primary ms-2"
                onClick={validate}
              >
                Continuer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SupplierFormStep2;
