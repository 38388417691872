import React from 'react'

const AlertComponentSupplier = (errors) => {
  const values = Object.values(errors);
  return (
    <div>
      {values != null
        ? values.map((item, key) => {
            return (
              <div className="input-error d-flex justify-content-end">
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <i className="bi bi-exclamation-octagon me-1"></i>

                  <span>{item}</span>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
};

export default AlertComponentSupplier
