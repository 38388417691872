import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../config/config";
import { useHistory } from "react-router";

const initFormErrors = {
  role: [""],
  p: [""],
};

const CreateRole = () => {
  const [roles, setRoles] = React.useState();
  const [permission, setPermission] = React.useState([]);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const [checked, setChecked] = React.useState([]);

  const msg = "Ce champ est obligatoire";
  const msg1 = "Il faut choisir au moins un accès";
  const checkList = [
    "users",
    "company",
    "process",
    "candidats",
    "roles",
    "candidatTypes",
    "employees",
    "fournisseur",
    "candidatProcess",
  ];

  function sendData() {
    axios
      .post(`${baseURL}/roles/create`, {
        name: roles,
        permission: JSON.stringify(permission),
      })
      .then((resp) => {
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setTimeout(() => {
          history.push("/roles");
        }, 2400);
      });
  }

  const handleCheck = (e) => {
    let updatedList = [...checked];
    if (e.target.checked) {
      updatedList = [...checked, e.target.value];
    } else {
      updatedList.splice(checked.indexOf(e.target.value), 1);
    }
    setChecked(updatedList);
    setPermission(updatedList);
  };
  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
    if (roles == null) {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        role: msg,
      }));
    }
    if (typeof roles !== "undefined") {
      if (!roles.match(/^[^-\s][a-zA-Z0-9_\s-]+$/)) {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          role: "Veuilez entrer un nom valid",
        }));
      }
    }

    if (permission.length == 0) {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        p: msg1,
      }));
    }
    if (error === false) {
      sendData();
    }
  }

  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Ajouter un Role</h1>
          </div>
          <div className="col-md-4">
            <Link to="/roles">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2"
              >
                Retour
              </button>
            </Link>
          </div>
        </div>

        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"}>
                <i className="bi bi-house-door"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/roles"}>Roles</Link>
            </li>
            <li className="breadcrumb-item active">Ajouter un Role</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12"></div>
          <div className="card">
            <div className="card-body pt-3">
              <form className="row g-3">
                <div className="col">
                  <div className="col-md-6">
                    <label>Role :</label>
                    <input
                      type="text"
                      className="form-control my-3"
                      onChange={(e) => setRoles(e.target.value)}
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.role}</span>
                    </div>
                  </div>
                  <label>Permissions :</label>
                  <div className="list-container form-check mt-3">
                    {checkList.map((item, index) => (
                      <div key={index}>
                        <input
                          className="form-check-input"
                          value={item}
                          type="checkbox"
                          onChange={handleCheck}
                        />
                        <span className="form-check-label">{item}</span>
                      </div>
                    ))}
                  </div>
                  <div className="input-error">
                    <span className="text-danger">{errorMessage.p}</span>
                  </div>
                  <div className="text-end">
                    <Link to="/roles">
                      <button
                        type="button"
                        className="btn btn-outline-secondary  me-2"
                      >
                        Annuler
                      </button>
                    </Link>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={validate}
                    >
                      Enregistrer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default CreateRole;
