import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { baseURL, MatchingServer } from "../../config/config";
import "./dndProcessMission.css";
import Tooltip from "@mui/material/Tooltip";
import { UserContext } from "../../context/UserContext";
import swal from "sweetalert";

const DndProcessMission = () => {
  const { newUser, setNewUser } = useState();
  const { user, setUser } = useContext(UserContext);

  const { missionId } = useParams();
  const [columns, setColumns] = useState({});
  const [mission, setMission] = React.useState();
  const [steps, setSteps] = React.useState();
  const [itemsListAcceptedToUser, setItemsListAcceptedToUser] =
    React.useState();
  const [employeeId, setEmployeeId] = useState();
  const [employeetoadd, setEmployeeToAdd] = useState();
  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      updateEmployeeStep(
        destItems[destination.index]._id,
        destination.droppableId
      );
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  async function getMissionById() {
    if (missionId === undefined) return;
    axios
      .get(`${baseURL}/missions/${missionId}`)
      .then((response) => {
        if (response.data.error) return;
        setMission(response.data.data);
        setSteps(JSON.parse(response.data.data.steps));
      })
      .catch((err) => {});
  }

  function getEmployeeByMissionId() {
    if (missionId === null) return;
    axios
      .get(`${baseURL}/missionEmployees/get/${missionId}`)
      .then((response) => {
        if (response.data.error) return;
        let itemslistNotAffecter = [];
        let itemslistAffecter = [];
        response.data.data.map((item) => {
          item.step.toString() === "Not affected" ||
          item.step.toString() === "0"
            ? itemslistNotAffecter.push(item)
            : itemslistAffecter.push(item);
        });
        const initColumn = {
          name: "Non Affecter",
          items: itemslistNotAffecter,
        };
        let cols = [initColumn];
        steps.map((step, key) => {
          let items = itemslistAffecter;
          let itemsSorted = [];
          items.map((item) => {
            item.step.toString() === step
              ? itemsSorted.push(item)
              : itemsSorted.push();
          });
          cols[step] = {
            name: step,
            items: itemsSorted,
          };
        });
        let itemslistAccepted = [];
        itemslistAffecter.map((item) => {
          item.step.toString() === "Affecter"
            ? itemslistAccepted.push(item)
            : itemslistAccepted.push();
        });
        setItemsListAcceptedToUser(itemslistAffecter);
        const finalColumn = {
          name: "Affecter",
          items: itemslistAccepted,
        };

        cols["Affecter"] = finalColumn;
        let itemslistRejected = [];
        itemslistAffecter.map((item) => {
          item.step.toString() === "Rejeter"
            ? itemslistRejected.push(item)
            : itemslistRejected.push();
        });
        const RejectColumn = {
          name: "Rejeter",
          items: itemslistRejected,
        };

        cols["Rejeter"] = RejectColumn;
        setColumns(cols);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function updateEmployeeStep(id, step) {
    let status = "";

    if (step === "0") {
      status = "waiting";
    } else {
      if (step === "0") {
        status = "waiting";
      } else {
        if (step === "Affecter") {
          status = "Validated";
        } else {
          if (step === "Rejeter") {
            status = "Rejected";
          } else {
            status = "in_progress";
          }
        }
      }
    }
    axios
      .post(
        `${baseURL}/missionEmployees/affectEmployeeToMissionProcess/${id}`,
        { step: step, status: status }
      )
      .then((response) => {
        if (response.data.error) return;
      })
      .catch((err) => {});
  }
  async function getDetailsEmployee() {
    if (employeeId === undefined || employeeId === "") return;
    axios.get(`${baseURL}/employees/${employeeId}`).then((response) => {
      if (response.data.error) return;
      const name = response.data.data.fname + " " + response.data.data.lname;
      swal(name, {
        title: "voulez vous Ajouter cette employe en tant que utilisateur? ",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((confirmed) => {
        if (confirmed) {
          setEmployeeToAdd(response.data.data);
          setEmployeeId("");
        } else {
          setEmployeeId("");
        }
      });
    });
  }
  async function sendData() {
    const userName = employeetoadd.fname + employeetoadd.lname;
    axios
      .post(`${MatchingServer}/users/create`, {
        name: userName,
        avatar: employeetoadd.avatarid,
        email: employeetoadd.email,
        address: employeetoadd.address,
        phone: employeetoadd.phone,
        nirpp: employeetoadd.nirpp.toString(),
        employeeId: employeeId,
      })
      .then((resp) => {
        if (resp.data.error) return;
        setEmployeeId("");

        setNewUser(resp.data.data);
        swal({
          title: "utilisateur ajoute avec succee  ",
          icon: "success",
          timer: 2500,
        });
      })
      .catch((error) => {
        swal({
          title: "une erreur s'est produite",
          icon: "error",
          timer: 2500,
        });
        console.log(error.response);
      });
  }
  useEffect(() => {
    getDetailsEmployee();
  }, [employeeId]);
  useEffect(() => {
    sendData();
  }, [employeetoadd]);
  useEffect(() => {
    getMissionById();
  }, []);
  useEffect(() => {
    if (steps === undefined) return;
    getEmployeeByMissionId();
  }, [steps]);

  return (
    <div style={{ display: "flex", height: "100%" }} className="process-list">
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(columns).map(([columnId, column], index) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                key={columnId}
              >
                <h2>{column.name}</h2>
                <div style={{ margin: 8 }}>
                  <Droppable droppableId={columnId} key={column}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              ? "lightblue"
                              : "#e3e8ee",
                            padding: 4,
                            width: 250,
                            minHeight: 500,
                          }}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable
                                key={item.employee._id}
                                draggableId={item.employee._id}
                                index={index}
                                value={item.employee._id}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <Tooltip
                                      title={
                                        itemsListAcceptedToUser.includes(
                                          item
                                        ) ? (
                                          <div
                                            style={{
                                              background:
                                                snapshot.isDraggingOver
                                                  ? "lightblue"
                                                  : "#f6f7fb",
                                              padding: 0,
                                              display: "flex",
                                              justifycontent: "center",
                                              alignitems: "center",
                                            }}
                                          >
                                            <h4>Ajouter comme utilisateur </h4>{" "}
                                            <button
                                              onClick={() =>
                                                setEmployeeId(item.employee._id)
                                              }
                                              className=" btn text-primary "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-person-add"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                                                <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
                                              </svg>
                                            </button>
                                          </div>
                                        ) : (
                                          ""
                                        )
                                      }
                                      arrow
                                    >
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="drag-item"
                                        style={{
                                          userSelect: "none",
                                          padding: 16,
                                          margin: "0 0 8px 0",
                                          minHeight: "50px",
                                          backgroundColor: snapshot.isDragging
                                            ? "#263B4A"
                                            : "white",
                                          color: snapshot.isDragging
                                            ? "#fff"
                                            : "#012970",
                                          ...provided.draggableProps.style,
                                        }}
                                      >
                                        {item.employee.fname}{" "}
                                        {item.employee.lname}
                                      </div>
                                    </Tooltip>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            </>
          );
        })}
      </DragDropContext>
    </div>
  );
};

export default DndProcessMission;
