import React, { useContext, useState, useEffect } from "react";
import { CandidatContext } from "../../pages/candidat/CreateCandidats";
import CandidatsFormStep3 from "./CandidatsFormStep3";
import { useHistory } from "react-router";
import axios from "axios";
import swal from "sweetalert";
import AlertComponent from "./AlertComponent";
import { baseURL } from "../../config/config";

const initFormErrors = {
  name: [""],
  email: [""],
  phone: [""],
  address: [""],
  cp: [""],
  birthDay: [""],
  lieu_naissance: [""],
  diplome: [""],
  annéeGraduation: [""],
  type: [""],
  langues: [""],
  avatar: [""],
};
const Confirm = ({ setChildComponent }) => {
  const { newCandidat, setNewCandidat } = useContext(CandidatContext);
  const [pays, setPays] = useState("");

  const history = useHistory();
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  const [errorAlert, setErrorAlert] = React.useState("");

  function sendData() {
    const formData = new FormData();
    formData.append("gender", newCandidat.gender);
    formData.append("firstname", newCandidat.firstname);
    formData.append("lastname", newCandidat.lastname);
    formData.append("email", newCandidat.email);
    formData.append("pays", pays);
    formData.append("city", newCandidat.city);
    formData.append("address", newCandidat.address);
    formData.append("cp", newCandidat.cp);
    formData.append("phone", newCandidat.phone);
    formData.append("birthday", newCandidat.birthDay);
    formData.append("birthPlace", newCandidat.birthPlace);
    formData.append("diplomeDate", newCandidat.diplomeDate);
    formData.append("diplome", newCandidat.diplome);
    formData.append("type", newCandidat.type);
    formData.append("langues", newCandidat.langues);
    formData.append("status", newCandidat.status);
    formData.append("skills", newCandidat.skills);
    formData.append("experience", newCandidat.experience);
    formData.append("avatar", newCandidat.avatar);
    formData.append("cv", newCandidat.cv);

    axios
      .post(`${baseURL}/candidates/create`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setTimeout(() => {
          history.push("/candidats");
        }, 2400);
      })
      .catch((error) => {
        const errors = error.response.data;
        setFormErrors(errors);
      });
  }

  useEffect(() => {
    if (formerrors.email == "") return;
    setErrorAlert(<AlertComponent errors={formerrors} />);
  }, [formerrors]);

  function validate() {}

  return (
    <div>
      {errorAlert}
      <section className="section">
        <div className="row">
          <h1 className="text-center color-jcit fw-bolder pb-5">
            Fiche de candidat
          </h1>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <div className="row pb-3">
                  <div className="col-md-5">
                    <div>
                      <label className="color-jcit fw-bold">
                        Nom et Prénom :{" "}
                      </label>{" "}
                      <span>
                        {newCandidat.lastname} {newCandidat.firstname}
                      </span>
                    </div>
                    <div>
                      <label className="color-jcit fw-bold ">
                        Type de candidat :{" "}
                      </label>{" "}
                      {newCandidat.typename}
                    </div>
                  </div>
                  <div className="col-md-5">
                    {" "}
                    <div>
                      <label className="color-jcit fw-bold">Email : </label>{" "}
                      {newCandidat.email}
                    </div>
                    <div>
                      <label className="color-jcit fw-bold">Téléphone : </label>{" "}
                      {newCandidat.phone}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <h3 className="pb-2">Informations personnelles</h3>

                <div className="row pb-3">
                  <div className="col-md-2">
                    <div>
                      <label className="color-jcit fw-bold">Genre : </label>{" "}
                      {newCandidat.gender}
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div>
                      <label className="color-jcit fw-bold">
                        Etat civil :{" "}
                      </label>{" "}
                      {newCandidat.status}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <label className="color-jcit fw-bold">Ville : </label>{" "}
                      {newCandidat.cityname}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <label className="color-jcit fw-bold">Adresse : </label>{" "}
                      {newCandidat.address}
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div>
                      <label className="color-jcit fw-bold">
                        Code postal :{" "}
                      </label>{" "}
                      {newCandidat.cp}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <h3 className="pb-2">Informations professionnelle</h3>
                <div className="row pb-3">
                  <div className="col-md-2">
                    <div>
                      <label className="color-jcit fw-bold">
                        Expérience :{" "}
                      </label>
                      {newCandidat.experience}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <label className="color-jcit fw-bold">
                        Compétence :{" "}
                      </label>
                      {newCandidat.skills}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <label className="color-jcit fw-bold">Diplome:</label>
                      {newCandidat.diplome}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <label className="color-jcit fw-bold">
                        Année de diplome :{" "}
                      </label>
                      {newCandidat.diplomeDate}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div>
                      <label className="color-jcit fw-bold me-2">
                        langue :{" "}
                      </label>
                      {JSON.parse(newCandidat.langues).map((lang, key) => {
                        return (
                          <span key={key} className="badge bg-secondary me-2">
                            {lang}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="text-end">
          <button
            type="button"
            className="btn btn-outline-secondary  me-2"
            onClick={() => {
              setChildComponent(
                <CandidatsFormStep3 setChildComponent={setChildComponent} />
              );
            }}
          >
            Retour
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              validate();
              sendData();
            }}
          >
            Confirmer
          </button>
        </div>
      </section>
    </div>
  );
};

export default Confirm;
