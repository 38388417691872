/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { baseURL } from "../config/config";
import { UserContext } from "../context/UserContext";

const Header = () => {
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    document
      .querySelector(".toggle-sidebar-btn")
      .addEventListener("click", function (e) {
        document.querySelector("body").classList.toggle("toggle-sidebar");
      });
  }, []);

  function logout() {
    axios
      .post(`${baseURL}/logout`)
      .then((response) => {
        setUser(undefined);
        localStorage.removeItem("user-data");
        history.replace("/login");
      })
      .catch((err) => {});
  }

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between">
        <a
          href="http://localhost:3000/"
          className="logo d-flex align-items-center"
        >
          <img src="../assets/img/jcit-logo.png" alt="" />
          <span className="d-none d-lg-block">JCIT-CONSEIL</span>
        </a>
        <i className="bi bi-list toggle-sidebar-btn"></i>
      </div>

      <div className="search-bar">
        <form
          className="search-form d-flex align-items-center"
          method="POST"
          action="#"
        >
          <input
            type="text"
            name="query"
            placeholder="Search"
            title="Enter search keyword"
          />
          <button type="submit" title="Search">
            <i className="bi bi-search"></i>
          </button>
        </form>
      </div>

      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          <li className="nav-item d-block d-lg-none">
            <a className="nav-link nav-icon search-bar-toggle " href="#">
              <i className="bi bi-search"></i>
            </a>
          </li>

          <li className="nav-item dropdown pe-3">
            <a
              className="nav-link nav-profile d-flex align-items-center pe-0"
              href="#"
              data-bs-toggle="dropdown"
            >
              <img
                src={`http://localhost:5000${user.avatar.path.substring(1)}`}
                alt="Profile"
                className="rounded-circle avatar"
              />
              <span className="d-none d-md-block dropdown-toggle ps-2">
                {user != null && user.name}
              </span>
            </a>

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li className="dropdown-header">
                <img
                  src={`http://localhost:5000${user.avatar.path.substring(1)}`}
                  alt="Profile"
                  className="rounded-circle avatar"
                />
                <h6>{user != null && user.name}</h6>
                <span>{user !== undefined && user.role.name}</span>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>

              <li>
                <button
                  onClick={logout}
                  className="dropdown-item d-flex align-items-center"
                >
                  <i className="bi bi-box-arrow-right"></i>{" "}
                  <span>Sign Out</span>
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
