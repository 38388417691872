/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-lone-blocks */
import axios from "axios";
import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import ChangePassword from "../../components/user/changePassword";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import Loader from "../../components/loader";

const Users = () => {
  const [users, setUsers] = React.useState([]);
  const [data, setData] = React.useState();
  const [changePasswordModal, setChangePasswordModal] = React.useState();

  async function getAllUsers() {
    axios
      .get(`${baseURL}/users`)
      .then((response) => {
        if (response.data.error) return;
        setUsers(response.data.data);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    setData(<Loader />);
    getAllUsers();
  }, []);

  useEffect(() => {
    if (users.length == 0) return;
    setData(<DataTable columns={columns} data={users} pagination />);
  }, [users]);

  async function enableDisable(user_id, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver cet utilisateur?"
        : "Voulez vous Activer cet utilisateur?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/users/toggleStatus/${user_id}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllUsers();
            }
          });
      }
    });
  }

  {
    /*const columns = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "5%",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <div className="row">
          <div className="col-4">
            <img
              src={row.avatar}
              className="rounded-circle"
              width="35"
              height="35"
            />
          </div>
          <div className="col-8">{row.name}</div>
        </div>
      ),
      width: "15%",
      sortable: true,
    },
    {
      name: "Contact",
      width: "20%",
      cell: (row) => (
        <div>
          <div className="email">
            <a
              href={`mailto:${row.email}`}
              className="color-jcit "
              data-toggle="tooltip"
              data-placement="top"
              title="Envoyer mail"
            >
              {" "}
              <i className="bi bi-envelope-fill"></i> {row.email}
            </a>
          </div>
          <div className="phone">
            <a
              href={`tel:+${row.phone}`}
              className="color-jcit "
              data-toggle="tooltip"
              data-placement="top"
              title="Appeler"
            >
              {" "}
              <i className="bi bi-telephone-fill"></i> {row.phone}
            </a>
          </div>
        </div>
      ),
    },
    {
      name: "Adresse",
      selector: (row) => row.address,
      width: "20%",
      sortable: true,
    },
    {
      name: "NIRPP ",
      selector: (row) => row.nirpp,
      width: "10%",
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role != null && row.role.name,
      width: "10%",
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="row col-12">
          <Link
            className="col-3 btn text-primary me-2"
            data-toggle="tooltip"
            data-placement="top"
            title="Modifier utilisateur"
            to={`/users/edit/${row.id}`}
          >
            <i className="bi bi-pen"></i>
          </Link>
          <button
            className="col-3 btn text-primary me-2 btn"
            onClick={() => {
              enableDisable(row.id, row.isEnabled);
            }}
          >
            {row.isEnabled == "yes" ? (
              <i
                className="text-success bi bi-toggle2-on"
                data-toggle="tooltip"
                data-placement="top"
                title="Désactiver utilisateur"
              ></i>
            ) : (
              <i
                className="text-danger bi bi-toggle2-off"
                data-toggle="tooltip"
                data-placement="top"
                title="Activer utilisateur"
              ></i>
            )}
          </button>
          <button
            className="col-3 btn text-secondary me-2"
            data-toggle="tooltip"
            data-placement="top"
            title="Changer mot de passe"
            type="button"
            onClick={() =>
              setChangePasswordModal(
                <ChangePassword
                  setChangePasswordModalFromChild={setChangePasswordModal}
                  userId={row.id}
                />
              )
            }
          >
            <i className="bi bi-lock-fill"></i>
          </button>
        </div>
      ),
    },
  ];*/
  }
  const columns = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "10%",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <div className="row">
          <div className="col-4">
            <img
              src={row.avatar}
              className="rounded-circle"
              width="35"
              height="35"
            />
          </div>
          <div className="col-8">{row.name}</div>
        </div>
      ),
      width: "20%",
      sortable: true,
    },
    {
      name: "Contact",
      width: "30%",
      cell: (row) => (
        <div>
          <div className="email">
            <a
              href={`mailto:${row.email}`}
              className="color-jcit "
              data-toggle="tooltip"
              data-placement="top"
              title="Envoyer mail"
            >
              {" "}
              <i className="bi bi-envelope-fill"></i> {row.email}
            </a>
          </div>
          <div className="phone">
            <a
              href={`tel:+${row.phone}`}
              className="color-jcit "
              data-toggle="tooltip"
              data-placement="top"
              title="Appeler"
            >
              {" "}
              <i className="bi bi-telephone-fill"></i> {row.phone}
            </a>
          </div>
        </div>
      ),
    },
    {
      name: "Adresse",
      selector: (row) => row.address,
      width: "20%",
      sortable: true,
    },
    {
      name: "NIRPP ",
      selector: (row) => row.nirpp,
      width: "15%",
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role != null && row.role.name,
      width: "15%",
      sortable: true,
    },
    {
      name: "Actions",
      width: "20%",
      cell: (row) => (
        <div className="row">
          <Link
            className="col-3 btn text-primary "
            to={`/users/edit/${row.id}`}
            data-toggle="tooltip"
            data-placement="top"
            title="Modifier utilisateur"
          >
            <i className="bi bi-pen"></i>
          </Link>
          <button
            className="col-3 btn text-primary me-2 btn"
            onClick={() => {
              enableDisable(row.id, row.isEnabled);
            }}
          >
            {row.isEnabled == "yes" ? (
              <i
                className="text-success bi bi-toggle2-on"
                data-toggle="tooltip"
                data-placement="top"
                title="Désactiver utilisateur"
              ></i>
            ) : (
              <i
                className="text-danger bi bi-toggle2-off"
                data-toggle="tooltip"
                data-placement="top"
                title="Activer utilisateur"
              ></i>
            )}
          </button>

          <button
            className="col-3 btn"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Changer mot de passe"
            type="button"
            onClick={() =>
              setChangePasswordModal(
                <ChangePassword
                  setChangePasswordModalFromChild={setChangePasswordModal}
                  userId={row.id}
                />
              )
            }
          >
            <i className="bi bi-lock-fill"></i>
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Les Utilisateurs</h1>
          </div>
          <div className="col-md-4">
            <Link to="/">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2"
              >
                Retour
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">Utilisateurs</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4">
            <Link to="/users/create">
              <button
                type="button"
                className="btn btn-primary btn-rounded float-end text-sm"
              >
                <i className="bi bi-plus-lg"></i> Ajouter
              </button>
            </Link>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body  pt-3">{data}</div>
            </div>
          </div>
        </div>
        {changePasswordModal}
      </section>
    </div>
  );
};

export default Users;
