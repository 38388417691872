import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseURL } from "../../config/config";
import swal from "sweetalert";
import "../../pages/mission/detailsMission.css";
import MissionEmployeesList from "./employeeMissionList";
import AffectEmployeeModal from "./affectEmployeeModal";

function MissionEmployees({ mission }) {
  const [errorEmployee, setErrorEmployee] = React.useState("");
  const [errorEmployeeMsg, setErrorEmployeeMsg] = React.useState("");
  const [employee, setEmployee] = React.useState();
  const [employees, setEmployees] = React.useState([]);
  const [child, setChild] = useState();
  const [refresh, setRefresh] = useState(0);
  const [affectEmployeeModal, setAffectEmployeeModal] = useState();
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }
  function affectEmployeeToMission() {
    setAffectEmployeeModal(
      <AffectEmployeeModal
        employees={employees}
        SetAffectModalFromChild={setAffectEmployeeModal}
        mission={mission}
        getAllEmployees={getAllEmployees}
      />
    );
  }

  async function getAllEmployees() {
    axios
      .get(`${baseURL}/employees/employees/all`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setEmployees(respData);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getAllEmployees();
    setChild(<MissionEmployeesList missionId={mission.id} />);
  }, []);
  return (
    <>
      <div className="row">
        <h5 className="fw-bold mb-3 col-md-4 col-12">
          Liste des employés affectés
        </h5>
        <div className="col-12 col-md-8 d-flex flex-row-reverse">
          <div className="col-md-1 me-2">
            <button
              type="button"
              className="btn btn-primary btn-sm btn-rounded text-sm"
              onClick={() => affectEmployeeToMission()}
            >
              Affecter
            </button>
          </div>
          <div className="col-md-5  me-2">
            <div className={`input-group ${errorEmployee} mb-3`}></div>
            <div className="input-error col-md-12">
              <span className="text-danger">{errorEmployeeMsg}</span>
            </div>
          </div>
        </div>
      </div>
      {affectEmployeeModal}
      {child}
    </>
  );
}

export default MissionEmployees;
