import React, { useState } from "react";
import axios from "axios";
import { MatchingServer, baseURL } from "../../config/config";
import { useEffect } from "react";
import swal from "sweetalert";
import { useRef } from "react";
import { Link } from "react-router-dom";
import Loader from "../../components/loader";
export default function AffectEmployeeModal({
  employees,
  SetAffectModalFromChild,
  mission,
  getAllEmployees,
}) {
  const initFormErrors = {
    name: [""],
    etape: [""],
  };
  const [file, setFile] = useState();
  const [upload, setUpload] = useState();
  const [select, setSelect] = useState("active");
  const [childComponent, setChildComponent] = useState();
  const [formerrors, setFormErrors] = useState(initFormErrors);
  const [missions, setMissions] = useState();
  const [errorEmployee, setErrorEmployee] = useState("");
  const [employee, setEmployee] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [errorEmployeeMsg, setErrorEmployeeMsg] = useState("");
  const uploadedImage = useRef(null);
  const [cv, setCv] = useState();
  const [foundCv, setFoundCv] = useState();
  const [allcvs, setAllCvs] = useState();
  const [image, setImage] = useState();
  // const handleImageUpload = (e) => {
  //   const [file] = e.target.files;
  //   if (file) {
  //     const reader = new FileReader();
  //     const { current } = uploadedImage;
  //     current.file = file;
  //     reader.onload = (e) => {
  //       current.src = e.target.result;
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  function affectEmployeeToMission() {
    const formData = new FormData();
    formData.append("mission", mission.id);
    formData.append("employee", employee);
    formData.append("cv", cv ? cv : file);
    axios
      .post(`${baseURL}/missionEmployees/affectEmployeeToMission`, formData)
      .then((resp) => {
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        }).then(async (value) => {
          getAllEmployees();
          SetAffectModalFromChild("");
        });
      })
      .catch((error) => {
        if (error.response.data.employee) {
          setErrorEmployee("border border-danger");
          setErrorEmployeeMsg(error.response.data.employee);
        }
      });
  }
  async function getEmployee() {
    axios
      .get(`${baseURL}/employees/${employee}`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setSelectedEmployee(respData);
      })
      .catch((err) => {});
  }
  async function getCvs() {
    axios
      .get(`${baseURL}/cv/allCvs/${employee}`)
      .then((response) => {
        if (response.data.error) return;
        setAllCvs(response.data.data);
      })
      .catch((err) => {});
  }
  function validate() {
    let errorsArray = {};
    let error = false;

    if (!error) {
      affectEmployeeToMission();
    }
    setFormErrors(errorsArray);
  }
  useEffect(() => {
    getEmployee();
    getCvs();
    setFoundCv("");
    setCv("");
    setChildComponent(
      <div className="col-6">
        <div className="col-md-10 mb-3">
          <select
            id="inputEmployee"
            defaultValue={""}
            onChange={(e) => {
              setCv(e.target.value);
              setErrorEmployee("");
              setErrorEmployeeMsg("");
            }}
            className="form-select form-select-sm"
          >
            <option selected>Sélectionner un CV</option>
            {allcvs != null &&
              allcvs.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.cv}
                  </option>
                );
              })}
          </select>
          <div className="input-error">
            <span className="text-danger"> {formerrors.hour}</span>
          </div>
        </div>
      </div>
    );
  }, [employee]);
  useEffect(() => {
    if (cv) {
      const found = allcvs.find((obj) => {
        return obj.id === cv;
      });
      setFoundCv(found);
    }
  }, [cv]);
  useEffect(() => {
    if (allcvs) {
      setChildComponent(<Loader />);
      setChildComponent(
        <div className="col-6">
          <div className="col-md-10 mb-3">
            <select
              id="inputEmployee"
              onChange={(e) => {
                setCv(e.target.value);
                setErrorEmployee("");
                setErrorEmployeeMsg("");
              }}
              className="form-select form-select-sm"
            >
              <option selected>Sélectionner un CV</option>
              {allcvs != null &&
                allcvs.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.cv}
                    </option>
                  );
                })}
            </select>
            <div className="input-error">
              <span className="text-danger"> {formerrors.hour}</span>
            </div>
          </div>
        </div>
      );
    }
  }, [allcvs]);
  return (
    <div>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body d-flex  flex-column  justify-content-center me-4 ms-4">
              <h3 className="d-flex fw-bold justify-content-center m-4">
                Affecter un employee
              </h3>
              <hr className="light-hr  " />
              <div className="row d-flex flex-column justify-content-center">
                <div className="">
                  <label>Mission :</label>
                  <h3 className="col-md-10 mb-3">
                    <Link
                      className="color-jcit "
                      to={`/missions/details/${mission.id}`}
                    >
                      {mission.title}
                    </Link>
                  </h3>
                </div>
                <label>selectioner un employee :</label>
                <div className="col-md-10 mb-3">
                  <div className="input-group mb-3">
                    <select
                      id="inputEmployee"
                      onChange={(e) => {
                        setEmployee(e.target.value);
                        setErrorEmployee("");
                        setErrorEmployeeMsg("");
                      }}
                      className="form-select form-select-sm"
                    >
                      <option selected>Sélectionner un employé</option>
                      {employees != null &&
                        employees.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.fname} {item.lname}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="input-error">
                    <span className="text-danger"> {formerrors.mission}</span>
                  </div>
                </div>

                <ul className="nav nav-tabs nav-tabs-bordered col-10 mb-4">
                  <li className="nav-item">
                    <button
                      className={`nav-link ${select}`}
                      data-bs-toggle="tab"
                      // data-bs-target="#profile-overview"
                      onClick={() => {
                        setSelect("active");
                        setUpload("");
                        setChildComponent(
                          <div className="col-6">
                            <div className="col-md-10 mb-3">
                              <select
                                id="inputEmployee"
                                onChange={(e) => {
                                  setCv(e.target.value);
                                  setErrorEmployee("");
                                  setErrorEmployeeMsg("");
                                }}
                                className="form-select form-select-sm"
                              >
                                <option selected>Sélectionner un CV</option>
                                {allcvs != null &&
                                  allcvs.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.cv}
                                      </option>
                                    );
                                  })}
                              </select>
                              <div className="input-error">
                                <span className="text-danger">
                                  {" "}
                                  {formerrors.hour}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    >
                      Choisir un CV
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${upload}`}
                      data-bs-toggle="tab"
                      // data-bs-target="#profile-overview"
                      onClick={() => {
                        setSelect("");
                        setUpload("active");
                        setChildComponent(
                          <div className="custom-file">
                            <input
                              onChange={(e) => {
                                setFile(e.target.files[0]);
                              }}
                              type="file"
                              className="form-control"
                            />
                          </div>
                        );
                      }}
                    >
                      télécharger un CV
                    </button>
                  </li>
                </ul>
                {childComponent}
                <div className="row mt-4">
                  <div className=" row justify-content-center  ">
                    {selectedEmployee ? (
                      <div className="col-8">
                        <div className="shadow bg-white card-rounded card mb-0 ">
                          <div className="row selectedEmp">
                            <div className="col-4 col-md-4">
                              <img
                                src={selectedEmployee.avatar}
                                height="90%"
                                width="100%"
                                alt="Avatar"
                                className="rounded-circle p-2"
                              />
                            </div>

                            <div className="col-8 col-md-8 px-0 pr-2 py-2 py-md-3">
                              <div className="d-flex justify-content-between px-3 px-md-4">
                                <div>
                                  <h5 className="">
                                    {selectedEmployee.fname}{" "}
                                    {selectedEmployee.lname}
                                  </h5>
                                </div>
                              </div>
                              <hr className="light-hr limitwidth " />

                              <div className="d-flex justify-content-between px-3 px-md-4 div2">
                                <div className="">
                                  <p className="grey">
                                    <i className="bi-envelope-fill me-1" />{" "}
                                    {selectedEmployee.email}
                                  </p>
                                  <p className="grey ">
                                    <i className="bi-telephone-fill me-1" />{" "}
                                    {selectedEmployee.phone}
                                  </p>
                                  <p className="grey ">
                                    <i className="bi bi-geo-alt  me-1" />{" "}
                                    {selectedEmployee.birthPlace}
                                  </p>
                                  <p className="grey ">
                                    <i className=" bi bi-briefcase me-1" />{" "}
                                    {selectedEmployee.job}{" "}
                                    {selectedEmployee.experience} ans
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-4 d-flex justify-content-center ">
                      {/* <img
                        ref={uploadedImage}
                        style={{
                          weight: "30%",
                          width: "40%",
                        }}
                      /> */}
                      {foundCv ? (
                        <iframe src={foundCv.cv} width="300px" height="300px" />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer mt-4">
                <button
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    SetAffectModalFromChild("");
                  }}
                >
                  Annuler
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={validate}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}
