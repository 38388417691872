import React, { useEffect } from "react";
import axios from "axios";
import DeleteRole from "../components/role/deleteRole";
import { Link } from "react-router-dom";
import { baseURL } from "../config/config";
import DataTable from "react-data-table-component";
import Loader from "../components/loader";

const Role = () => {
  const [addRoleModal, setaddRoleModal] = React.useState();
  const [data, setData] = React.useState();
  const [roles, setRoles] = React.useState([]);

  async function getAllRoles() {
    axios
      .get(`${baseURL}/roles`)
      .then((response) => {
        if (response.data.error) return;
        setRoles(response.data.data);
      })
      .catch((err) => {});
  }
  useEffect(() => {
    setData(<Loader />);
    getAllRoles();
  }, []);

  useEffect(() => {
    if (roles.length == 0) return;
    setData(<DataTable columns={columns} data={roles} pagination />);
  }, [roles]);

  const columns = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "20%",
      sortable: true,
    },
    {
      name: "Nom",
      selector: (row) => row.name,
      width: "60%",
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="row col-12">
          <Link
            to={"/roles/update/" + row.id}
            className="col-3 btn text-primary me-2"
            data-toggle="tooltip"
            data-placement="top"
            title="Modifier role"
          >
            <button
              type="button"
              className="col-3 btn text-primary me-2"
              data-toggle="tooltip"
              data-placement="top"
              title="Modifier role"
            >
              <i className="bi bi-pen"></i>
            </button>
          </Link>
          <button
            type="button"
            className="col-3 btn text-danger me-2 btn"
            data-toggle="tooltip"
            data-placement="top"
            title="Supprimer role"
          >
            <i
              className="bi bi-trash"
              onClick={() =>
                setaddRoleModal(
                  <DeleteRole
                    setAddRoleModalFromChild={setaddRoleModal}
                    roleId={row.id}
                    getAllRoles={getAllRoles}
                  />
                )
              }
            ></i>
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setData(<Loader />);
    getAllRoles();
  }, []);
  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Les Roles</h1>
          </div>
          <div className="col-md-4">
            <Link to="/">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2"
              >
                Retour
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">Roles</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4">
            <Link to="/roles/create">
              <button
                type="button"
                className="btn btn-primary btn-rounded float-end"
              >
                <i className="bi bi-plus-lg"></i> Ajouter
              </button>
            </Link>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body pt-3">{data}</div>
            </div>
          </div>
        </div>
        {addRoleModal}
      </section>
    </div>
  );
};

export default Role;
