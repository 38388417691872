import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
const CandidatDetailsComponent = ({ candidat }) => {
  const [data, setData] = React.useState();
  useEffect(() => {
    setData(<DataTable columns={columns} data={candidat} pagination />);
  }, [candidat]);

  const columns = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "5%",
      sortable: true,
    },
  ];

  return (
    <div className="tab-content pt-2">
      <div
        className="card-body-candidat tab-pane fade show active profile-overview"
        id="profile-overview"
      >
        <div className="row ps-3">
          <div className="col-md-4">
            {" "}
            <h5 className="card-title-candidat color-jcit ">
              Nombre d'année d'experience
            </h5>
            <p className="small ">{candidat != null && candidat.experience}</p>
          </div>
          <div className="col-md-4 ">
            <h5 className="card-title-candidat color-jcit ">Compétence</h5>
            <p className="small ">{candidat != null && candidat.skills}</p>
          </div>
          <div className="col-md-4">
            <h5 className="card-title-candidat color-jcit ">Diplome</h5>
            <p className="small ">{candidat != null && candidat.diplome}</p>
          </div>
        </div>

        <div className="row ps-3">
          <div className="col-md-4">
            <h5 className="card-title-candidat color-jcit ">
              Année de diplome
            </h5>
            <p className="small ">{candidat != null && candidat.diplomeDate}</p>{" "}
          </div>
          <div className="col-md-4">
            <h5 className="card-title-candidat color-jcit ">Langue</h5>
            <p className="small ">
              {candidat.langues != null &&
                JSON.parse(candidat.langues).map((lang, key) => {
                  return (
                    <span key={key} className="badge bg-secondary me-2">
                      {lang}
                    </span>
                  );
                })}
            </p>
          </div>
          <div className="col-md-4">
            {" "}
            <h5 className="card-title-candidat color-jcit ">Genre</h5>
            <p className="small ">{candidat != null && candidat.gender}</p>
          </div>
        </div>
        <div className="row ps-3">
          <div className="col-md-4">
            <h5 className="card-title-candidat color-jcit ">Adresse</h5>
            <p className="small ">
              {candidat != null && candidat.address}
            </p>{" "}
          </div>
          <div className="col-md-4">
            {" "}
            <h5 className="card-title-candidat color-jcit ">Ville</h5>
            {candidat.city != null && candidat.city.name}
          </div>
          <div className="col-md-4">
            <h5 className="card-title-candidat color-jcit ">Code postale</h5>
            <p className="small ">{candidat != null && candidat.cp}</p>
          </div>
        </div>
        <div className="row ps-3">
          <div className="col-md-4">
            <h5 className="card-title-candidat color-jcit ">
              date de naissance
            </h5>
            <p className="small ">{candidat != null && candidat.birthday}</p>
          </div>
          <div className="col-md-4">
            <h5 className="card-title-candidat color-jcit ">
              Lieu de naissance
            </h5>
            <p className="small ">{candidat != null && candidat.birthPlace}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidatDetailsComponent;
