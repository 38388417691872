import React from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";

function CreateMission({
  setAddMissionModalFromChild,
  managers,
  companyId,
  specification,
}) {
  const [title, setTitle] = React.useState();
  const [description, setDescription] = React.useState();
  const [period, setPeriod] = React.useState();
  const [startDate, setStartDate] = React.useState(new Date());
  const [manager, setManager] = React.useState();
  const [companyid, setcompanyid] = React.useState(companyId);
  const [companies, setcompanies] = React.useState();
  const [allmanagers, setallmanagers] = React.useState(managers);
  const initFormErrors = {
    title: [""],
    description: [""],
    period: [""],
    startDate: [""],
    manager: [""],
    company: [""],
  };
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  function sendData() {
    let data = [];
    if (specification) {
      data = {
        title: title,
        description: description,
        period: period,
        startDate: startDate,
        company_id: companyid,
        manager: manager,
      };
    } else {
      data = {
        title: title,
        description: description,
        period: period,
        startDate: startDate,
        company_id: companyid,
        manager: manager,
      };
    }
    axios
      .post(`${baseURL}/missions/create`, {
        data,
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setAddMissionModalFromChild("");
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }
  async function getallManagerByCompanyId(companyId) {
    if (companyId === undefined) return;
    axios
      .get(`${baseURL}/managers/${companyId}`)
      .then((response) => {
        if (response.data.error) return;
        setallmanagers(response.data.data);
      })
      .catch((err) => {});
  }
  async function getAllCompany() {
    axios.get(`${baseURL}/companies/`).then((response) => {
      if (response.data.error) return;
      setcompanies(response.data.data);
    });
  }
  React.useEffect(() => {
    getAllCompany();
  }, []);
  React.useEffect(() => {
    getallManagerByCompanyId(companyid);
  }, [companyid]);
  return (
    <div>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col-md-12 mt-2">
                <label>Titre :</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.title}</span>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <label>Description :</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="description"
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.description}</span>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <label>Periode de travail :</label>
                <input
                  type="text"
                  className="form-control"
                  id="period"
                  onChange={(e) => setPeriod(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.period}</span>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <label>Date début de mission :</label>
                <input
                  type="date"
                  className="form-control"
                  id="startDate"
                  value={startDate.toLocaleDateString("en-CA")}
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.startDate}</span>
                </div>
              </div>

              {specification ? (
                <div className="col-md-12 mt-2">
                  <label>les sociétés:</label>
                  <select
                    id="companyId"
                    // value={companyid != null && companyid}
                    onChange={(e) => {
                      setcompanyid(e.target.value);
                    }}
                    className="form-select"
                  >
                    <option>Sélectionner une company</option>
                    {companies != null &&
                      companies.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {`${item.name} /${item.owner}`}
                          </option>
                        );
                      })}
                  </select>
                  <div className="input-error">
                    <span className="text-danger">{formerrors.company}</span>
                  </div>
                </div>
              ) : null}

              <div className="col-md-12 mt-2">
                <label>Résponsable :</label>
                <select
                  id="managerId"
                  onChange={(e) => {
                    setManager(e.target.value);
                  }}
                  className="form-select"
                >
                  <option selected>Sélectionner un résponsable</option>

                  {allmanagers != null &&
                    allmanagers.map((item, index) => {
                      return (
                        <option key={index} value={item._id}>
                          {/* {console.log(item._id)} */}
                          {`${item.lname} ${item.fname}`}
                        </option>
                      );
                    })}
                </select>
                <div className="input-error">
                  <span className="text-danger">{formerrors.manager}</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setAddMissionModalFromChild("")}
              >
                Annuler
              </button>
              <button
                type="button"
                onClick={sendData}
                className="btn btn-primary"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default CreateMission;
