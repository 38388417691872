import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL, localUrl } from "../../config/config";
function ExperienceLink({ setAddLinkExperienceModalFromChild, candidatId }) {
  const [link, setLink] = useState("");
  const [copy, setCopy] = useState(false);
  function getLink() {
    axios
      .get(`${baseURL}/link/generate/${candidatId}`, {})
      .then((resp) => {
        if (resp.data.error) return;
        setLink(localUrl + resp.data.data.link);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    getLink();
  }, []);
  return (
    <div>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 mt-2">
                  <div className="row mb-3">
                    <div className="col-10 pe-0">
                      <input
                        type="text"
                        value={link}
                        className="form-control h-100 m-0"
                        id="t"
                        required
                      />
                    </div>
                    <div className="col-2">
                      <button
                        type="button"
                        className={` rounded-1 btn ${
                          copy ? "border-success" : "border-primary"
                        }`}
                        onClick={() => {
                          navigator.clipboard.writeText(link);
                          setCopy(true);
                        }}
                      >
                        <i
                          className={`ri ri-file-copy-2-line ${
                            copy ? "text-success" : "text-primary"
                          }`}
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setAddLinkExperienceModalFromChild("")}
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default ExperienceLink;
