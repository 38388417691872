import React, { useEffect } from "react";
import axios from "axios";
import { baseURL } from "../../config/config";
import { Link, useHistory, useParams } from "react-router-dom";
import Loader from "../../components/loader";
import UpdateExperience from "../../components/experiences/updateExperiences";
import DeleteExperience from "../../components/experiences/deleteExperiences";
import CreateExperience from "../../components/experiences/createExperiences";
const AddExperience = () => {
  const [experience, setExperience] = React.useState([]);
  const [addExperienceModal, setAddExperienceModal] = React.useState();
  const [candidatId, setCandidatId] = React.useState();
  const { linkId } = useParams();
  const history = useHistory();

  function getCandidat() {
    axios.get(`${baseURL}/link/candidat/${linkId}`).then((response) => {
      if (response.data.error) return;
      let today = new Date();
      let expiredDate = new Date(response.data.data.expiredDate);
      if (today.getTime() > expiredDate.getTime()) {
        history.push("/expired");
      }
      setCandidatId(response.data.data.candidat);
    });
  }

  function getExperienceByCandidatId() {
    axios
      .get(`${baseURL}/experience/candidat/${candidatId}`)
      .then((response) => {
        if (response.data.error) return;
        setExperience(response.data.data);
      });
  }

  useEffect(() => {
    getCandidat();
  }, []);

  useEffect(() => {
    if (candidatId === undefined) return;
    getExperienceByCandidatId();
  }, [candidatId]);

  return (
    <div className="container">
      <div className="pagetitle row container">
        <header
          id="header"
          className="header container fixed-top d-flex align-items-center ps-5 pe-5"
        >
          <div className="d-flex align-items-center justify-content-between col-4">
            <a href="" className="logo d-flex align-items-center">
              <img src="../../assets/img/jcit-logo.png" alt="" />
              <span className="d-none d-lg-block">JCIT-CONSEIL</span>
            </a>
          </div>
          <div className="d-flex justify-content-end mb-3 col-8 pt-3">
            {" "}
            <a
              href="#"
              className="text-decoration-none btn btn-primary"
              onClick={() =>
                setAddExperienceModal(
                  <CreateExperience
                    setAddExperienceModalFromChild={setAddExperienceModal}
                    getExperienceByCandidatId={getExperienceByCandidatId}
                    candidatId={candidatId}
                  />
                )
              }
            >
              Ajouter expérience
            </a>
          </div>
        </header>
      </div>
      <section className="section pt-5">
        <div className="row pt-5">
          {experience.map((experience, key) => {
            return (
              <div className="col-md-12 col-sm-12">
                <div
                  className="info-box card employee-card-border "
                  style={{ boxShadow: "1px 5px 10px #888888" }}
                >
                  <div className="card-title row d-flex justify-content-between px-4 pb-0 m-0">
                    <div className=" text-msit fs-4 col-10 font-italic">
                      {experience.post_title}
                    </div>
                    <div className="text-end col-2">
                      <button
                        className="btn text-primary"
                        type="button"
                        onClick={() =>
                          setAddExperienceModal(
                            <UpdateExperience
                              setAddExperienceModalFromChild={
                                setAddExperienceModal
                              }
                              getExperienceByCandidatId={
                                getExperienceByCandidatId
                              }
                              experienceId={experience.id}
                              candidatId={candidatId}
                            />
                          )
                        }
                      >
                        <i className="bi bi-pen"></i>
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          setAddExperienceModal(
                            <DeleteExperience
                              setAddManagerModalFromChild={
                                setAddExperienceModal
                              }
                              getExperienceByCandidatId={
                                getExperienceByCandidatId
                              }
                              experienceId={experience.id}
                            />
                          )
                        }
                        className="btn text-danger"
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    </div>
                  </div>
                  <hr />
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12 row">
                        <div className="col-md-6">
                          <div className="mb-1">
                            <label className="text-secondary">
                              Type de contrat :{" "}
                            </label>
                            <span className="fw-bold">
                              {" "}
                              {experience.contract_type}
                            </span>
                          </div>
                          <div className="mb-1">
                            <label className="text-secondary">
                              Nom de socièté :{" "}
                            </label>
                            <span className="fw-bold">
                              {" "}
                              {experience.company}
                            </span>
                          </div>
                          <div className="mb-1">
                            <label className="text-secondary">Secteur : </label>
                            <span className="fw-bold">
                              {" "}
                              {experience.sector}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-1">
                            <label className="text-secondary">Adresse : </label>
                            <span className="fw-bold">
                              {experience.address}
                            </span>
                          </div>
                          <div className="mb-1">
                            <label className="text-secondary">
                              Date de début :{" "}
                            </label>
                            <span className="fw-bold">
                              {" "}
                              {experience.start_date}
                            </span>
                          </div>
                          <div className="mb-1">
                            <label className="text-secondary">
                              Date de fin :{" "}
                            </label>
                            <span className="fw-bold">
                              {" "}
                              {experience.end_date}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-1">
                            <label className="text-secondary">
                              Description :{" "}
                            </label>
                            <span className="fw-bold">
                              {" "}
                              {experience.description}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {addExperienceModal}
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default AddExperience;
