import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import Select from "react-select";

const initFormErrors = {
  role: [""],
  p: [""]
}

function AddRole({ setAddRoleModalFromChild, setRolesList }) {

  const msg = "Ce champ est obligatoire";
  const msg1 = "Il faut choisir au moins un accès";
  const [roles, setRoles] = React.useState();
  const [permission, setPermission] = React.useState();
  const [errorMessage, setErrorMessage] = useState(initFormErrors);

  const Autorisations = [
    { value: "Acceuil", label: "Accueil" },
    { value: "Les utilisateurs", label: "Les Utilisateurs" },
    { value: "Les roles", label: "Les Roles" },
    { value: "Les candidats", label: "Les Candidats" },
    { value: "Les Sociétés", label: "Les Sociétés" },
    { value: "Les employés", label: "Les Employés" },
    { value: "Les fornisseurs", label: "Les Fornisseurs" },
    { value: "Les processes d'entretien", label: "Les Processes d'entretien" },
  ];

  function sendData() {
    axios.post(`${baseURL}/roles/create`, { name: roles, permission: JSON.stringify(permission) }).then((resp) => {
      if (resp.data.error) return;
      swal(resp.data.message, {
        icon: "success",
        timer: 2500,
      });
      setRolesList((prev) => {
        return [
          ...prev,
          {
            name: resp.data.data.name,
            id: resp.data.data._id,
          },
        ];
      });
      setAddRoleModalFromChild("");
    });
  }
  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
    if (roles == null) {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        role: msg,
      }));
    }
    if (typeof roles !== "undefined") {
      if (!roles.match(/^[a-zA-Z]+$/)) {
        error = false;
        setErrorMessage((prevState) => ({
          ...prevState,
          role: "Veuilez entrer un nom valid",
        }));
      }
    }

    if (permission == null) {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        p: msg1,
      }));
    }
    if (error === false) {
      sendData();
    }
  }
  return (
    <div>
      <div
        className="modal fade show"
        id="role_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <input
                type="text"
                className="form-control"
                id="role_name"
                placeholder="veuillez saisir un role"
                onChange={(e) => setRoles(e.target.value)}
                required
              />
              <div className="input-error">
                <span className="text-danger">{errorMessage.role}</span>
              </div>
              <Select
                placeholder={"Selectionner les accès"}
                options={Autorisations}
                required
                isMulti
                onChange={(e) => {
                  let autorisations = [];
                  e.forEach((permis, key) => {
                    autorisations.push(permis.value);
                  }); setPermission(autorisations)
                }}
              />
              <div className="input-error">
                <span className="text-danger">{errorMessage.p}</span>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setAddRoleModalFromChild("")}
              >
                Annuler
              </button>
              <button
                type="button"
                onClick={validate}
                className="btn btn-primary"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default AddRole;
