import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useHistory, useParams } from "react-router";
import { baseURL } from "../../config/config";
import AddTypes from "../../components/condidatesTypes/AddTypes";
import { Link } from "react-router-dom";
import Select from "react-select";

const initFormErrors = {
  lastname: [""],
  firstname: [""],
  email: [""],
  phone: [""],
  address: [""],
  cp: [""],
  birthDay: [""],
  lieu_naissance: [""],
  diplome: [""],
  anneeGraduation: [""],
  type: [""],
};

const UpdateCandidats = () => {
  const [types, setTypes] = React.useState([]);
  const [loadingCity, setLoadingCity] = useState(false);
  const [addTypesModal, setaddTypesModal] = React.useState();
  const [countries, setCountries] = React.useState([]);
  const [city, setCity] = useState([]);
  const [gender, setGender] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [pays, setPays] = useState("");
  const [cities, setCities] = useState([]);
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [cp, setCp] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [lieu_naissance, setLieu_naissance] = useState("");
  const [diplome, setDiplome] = useState("");
  const [anneeGraduation, setAnneeGraduation] = useState("");
  const [avatar, setAvatar] = useState("");
  const [cv, setCv] = useState("");
  const [type, setType] = useState("");
  const history = useHistory();
  const { candidatId } = useParams();

  const [experience, setExperience] = useState("");
  const [status, setStatus] = useState("");
  const [skills, setSkills] = useState("");
  const [langues, setLangues] = useState("");

  const [candidats, setcandidats] = useState("");
  const [errorMessage, setErrorMessage] = useState(initFormErrors);

  React.useEffect(() => {
    if (candidatId === undefined) return;
    axios.get(`${baseURL}/candidates/${candidatId}`).then((resp) => {
      if (resp.data.error) return;
      const candidatsData = resp.data.data;
      setFirstname(candidatsData.firstname);
      setGender(candidatsData.gender);
      setLastname(candidatsData.lastname);
      setEmail(candidatsData.email);
      setAddress(candidatsData.address);
      setCv(candidatsData.cv);
      setPhone(candidatsData.phone);
      setCp(candidatsData.cp);
      setAvatar(candidatsData.avatar);
      setDiplome(candidatsData.diplome);
      setAnneeGraduation(candidatsData.diplomeDate);
      setBirthDay(candidatsData.birthday);
      setLieu_naissance(candidatsData.birthPlace);
      setCity(candidatsData.city._id);
      setPays(candidatsData.city.country);
      setStatus(candidatsData.status);
      setSkills(candidatsData.skills);
      setLangues(candidatsData.langues);
      setExperience(candidatsData.experience);
      setType(candidatsData.type._id);
      setcandidats(candidatsData);
    });
  }, [candidatId]);

  function getCountries() {
    axios.get(`${baseURL}/apiData/countries`).then((resp) => {
      if (resp.data.error) return;
      resp.data.data.map((item, index) => {
        return setCountries((prevState) => [
          ...prevState,
          { value: item.id, label: item.name },
        ]);
      });
    });
  }

  useEffect(() => {
    if (pays === "") return;
    setCities([]);
    getCities(pays);
  }, [pays]);

  function getCities(countryid) {
    axios.get(`${baseURL}/apiData/cities/${countryid}`).then((resp) => {
      if (resp.data.error) return;
      resp.data.data.map((item, index) => {
        return setCities((prevState) => [
          ...prevState,
          { value: item.id, label: item.name },
        ]);
      });
      setLoadingCity(false);
    });
  }

  useEffect(() => {
    getTypes();
  }, []);

  function getTypes() {
    axios.get(`${baseURL}/candidatTypes`).then((resp) => {
      if (resp.data.error) return;
      resp.data.data.map((item, index) => {
        return setTypes((prevState) => [
          ...prevState,
          { value: item.id, label: item.name },
        ]);
      });
    });
  }

  useEffect(() => {
    getCountries();
  }, []);

  function sendData() {
    const formData = {
      gender: gender,
      firstname: lastname,
      lastname: firstname,
      email: email,
      pays: pays,
      city: city,
      address: address,
      cp: cp,
      phone: phone.toString(),
      birthday: birthDay,
      birthPlace: lieu_naissance,
      diplomeDate: anneeGraduation,
      diplome: diplome,
      type: type,
      langues: langues,
      status: status,
      skills: skills,
      experience: experience,
    };

    axios
      .put(`${baseURL}/candidates/${candidatId}`, formData)
      .then((resp) => {
        setErrorMessage(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setTimeout(() => {
          history.push("/candidats");
        }, 2400);
      })
      .catch((error) => {
        setErrorMessage(error.response.data);
      });
  }

  const statusOptions = [
    { value: "single", label: "Célibataire" },
    { value: "in_relationship", label: "En couple" },
    { value: "married", label: "Marié" },
    { value: "divorced", label: "Divorcé" },
    { value: "pacs", label: "Pacsé" },
  ];

  const languesOptions = [
    { value: "français", label: "Français" },
    { value: "english", label: "Anglais" },
    { value: "arabic", label: "Arabe" },
    { value: "spanish", label: "Espagnole" },
    { value: "deutsh", label: "Allemand" },
    { value: "Italien", label: "Italien" },
  ];

  return (
    <div>
      <section className="section">
        <div className="row">
          <h1 className="text-center color-jcit fw-bolder pb-5">
            Modifier Fiche de candidat
          </h1>
          <div className="row">
            <div className="col-md-8">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>
                      <i className="bi bi-house-door"></i>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/candidats"}>Candidats</Link>
                  </li>
                  <li className="breadcrumb-item active">candidat</li>
                </ol>
              </nav>
            </div>
            <div className="col-md-4">
              <Link to="/candidats">
                <button
                  type="button"
                  className="btn btn-outline-secondary mb-2 float-end  me-2"
                >
                  Retour
                </button>
              </Link>
            </div>
          </div>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <div className="row pb-3">
                  <div className="col-md-4">
                    <label className="color-jcit fw-bold">Vous étes :</label>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="male"
                        value="male"
                        checked={gender === "male"}
                        onChange={(e) => setGender(e.target.value)}
                      />
                      <label className="form-check-label">Homme</label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="myCheck"
                        value="femele"
                        checked={gender === "femele"}
                        onChange={(e) => setGender(e.target.value)}
                      />
                      <label className="form-check-label">Femme</label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="myCheck"
                        value="other"
                        checked={gender === "other"}
                        onChange={(e) => setGender(e.target.value)}
                      />

                      <label className="form-check-label">Autre</label>
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.gender}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="color-jcit fw-bold">Nom * :</label>
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      onChange={(e) => setFirstname(e.target.value)}
                      value={firstname}
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.lastname}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label className="color-jcit fw-bold">Prénom * :</label>
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      onChange={(e) => setLastname(e.target.value)}
                      value={lastname}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.firstname}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label className="color-jcit fw-bold">Email * : </label>
                    <input
                      type="email"
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger"> {errorMessage.email}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="color-jcit fw-bold">Téléphone * : </label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger"> {errorMessage.phone}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <h3 className="pb-2">Informations personnelles</h3>

                <div className="row pb-3">
                  <div className="col-md-4">
                    <div>
                      <label className="color-jcit fw-bold">
                        Etat civil * :
                      </label>
                      <Select
                        value={statusOptions.filter(
                          (option) => option.value === status
                        )}
                        options={statusOptions}
                        onChange={(e) => {
                          setStatus(e.value);
                        }}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.status}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="color-jcit fw-bold">pays *</label>
                    <Select
                      value={countries.filter(
                        (option) => option.value === pays
                      )}
                      options={countries}
                      isSearchable
                      onChange={(e) => {
                        setLoadingCity(true);
                        setPays(e.value);
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="color-jcit fw-bold">Ville *</label>
                    <Select
                      value={cities.filter((option) => option.value === city)}
                      options={cities}
                      isLoading={loadingCity}
                      isSearchable
                      onChange={(e) => {
                        setCity(e.value);
                      }}
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.city}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-jcit fw-bold">Adresse * : </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.address}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-jcit fw-bold">
                        Code postal * :
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => setCp(e.target.value)}
                        value={cp}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger"> {errorMessage.cp}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="box">
                      <div className="date-picker">
                        <label className="color-jcit fw-bold">
                          Date de naissance *
                        </label>
                        <input
                          className="form-control"
                          type="date"
                          id="datePicker"
                          onChange={(e) => setBirthDay(e.target.value)}
                          value={birthDay}
                          required
                        />
                      </div>
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.birthDay}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <h3 className="pb-2">Informations professionnelle</h3>
                <div className="row pb-3">
                  <div className="col-md-4">
                    <div>
                      <label className="color-jcit fw-bold">
                        Expérience * :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setExperience(e.target.value)}
                        value={experience}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.experience}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-jcit fw-bold">
                        Compétence * :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSkills(e.target.value)}
                        value={skills}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.skills}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-jcit fw-bold">Diplome * :</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setDiplome(e.target.value)}
                        value={diplome}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.diplome}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-jcit fw-bold">
                        Année de diplome * :
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => setAnneeGraduation(e.target.value)}
                        value={anneeGraduation}
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.diplomeDate}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div>
                      <label className="color-jcit fw-bold">langue * : </label>
                      <Select
                        options={languesOptions}
                        value={
                          langues !== "" &&
                          languesOptions.filter((option) =>
                            JSON.parse(langues).includes(option.value)
                          )
                        }
                        isMulti
                        onChange={(e) => {
                          let lg = [];
                          e.forEach((lang, key) => {
                            lg.push(lang.value);
                          });
                          setLangues(JSON.stringify(lg));
                        }}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.langues}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-jcit fw-bold">
                        Type de candidat *
                      </label>
                      <div className="row">
                        <Select
                          value={types.filter(
                            (option) => option.value === type
                          )}
                          options={types}
                          className="col-md-10"
                          isSearchable
                          onChange={(e) => {
                            setType(e.value);
                          }}
                        />
                        <span
                          className="col-md-2 input-group-text p-0"
                          id="basic-addon1"
                        >
                          <button
                            type="button"
                            className="btn btn-"
                            onClick={() =>
                              setaddTypesModal(
                                <AddTypes
                                  setAddTypesModalFromChild={setaddTypesModal}
                                  setTypesList={setTypes}
                                />
                              )
                            }
                          >
                            <i className="bi bi-plus-lg"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.type}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="text-end">
          <Link to="/candidats">
            <button
              type="button"
              className="btn btn-outline-secondary  me-2"
              onClick={() => {
                history.goBack();
              }}
            >
              Annuler
            </button>
          </Link>
          <button type="button" className="btn btn-primary" onClick={sendData}>
            Enregistrer
          </button>
        </div>
      </section>
      {addTypesModal}
    </div>
  );
};

export default UpdateCandidats;
