import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import Loader from "../../components/loader";
import EmptyData from "../../components/emptyData";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import CreateNote from "../../components/candidat/candidatNote";
import CadidatToEmplyee from "../../components/candidat/CadidatToEmplyee";
import TelechargerModal from "../../components/candidatsForm/telechargerModal";
import CreateCV from "../../components/candidat/createCV";
const Candidats = () => {
  const [candidats, setcandidats] = React.useState([]);
  const [filtredCandidats, setFiltredCandidats] = React.useState(null);
  const [data, setData] = React.useState();
  const [addNote, setAddNote] = React.useState();
  const [addEmployeeModal, setaddEmployeeModal] = React.useState();
  const [telechargerCandidatModal, setTelechargerCandidatModal] =
    React.useState();

  async function getAllCandidats() {
    axios
      .get(`${baseURL}/candidates`)
      .then((response) => {
        if (response.data.error) return;
        setcandidats(response.data.data);
        setFiltredCandidats(response.data.data);
      })
      .catch((err) => {});
  }
  useEffect(() => {
    setData(<Loader />);
    getAllCandidats();
  }, []);

  useEffect(() => {
    if (filtredCandidats == null) return;
    if (filtredCandidats.length > 0) {
      setData(
        <DataTable columns={columns} data={filtredCandidats} pagination />
      );
    } else {
      setData(<EmptyData />);
    }
  }, [filtredCandidats]);

  async function enableDisable(candidatId, isEnabled) {
    let message =
      isEnabled == "yes"
        ? "voulez vous désactiver ce candidat?"
        : "Voulez vous activer ce candidat?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/candidates/toggleStatus/${candidatId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllCandidats();
            }
          });
      }
    });
  }

  const columns = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "4%",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <div>
          <Link to={`/candidats/details/${row.id}`} className="color-jcit">
            {" "}
            <img
              src={row.avatar}
              className="rounded-circle"
              width="35"
              height="35"
            />{" "}
            {row.lastname} {row.firstname}
          </Link>
        </div>
      ),
      width: "15%",
      sortable: true,
    },
    {
      name: "Coordonnées",
      selector: (row) => (
        <div>
          <div className=" col-md-12">
            <p className="p-0 m-0 col-md-12">
              <a
                href={`mailto:${row.email}`}
                className="color-jcit "
                data-toggle="tooltip"
                data-placement="top"
                title="Envoyer mail"
              >
                {" "}
                <i className="bi bi-envelope-fill"></i> {row.email}
              </a>
            </p>
            <p className="p-0 m-0 col-md-12">
              {" "}
              <a
                href={`tel:+${row.phone}`}
                className="color-jcit "
                data-toggle="tooltip"
                data-placement="top"
                title="Appeler"
              >
                <i className="bi bi-telephone-fill"></i> {row.phone}
              </a>
            </p>
          </div>
        </div>
      ),
      width: "20%",
      sortable: true,
    },

    {
      name: "Adresse",
      selector: (row) => (
        <div>
          <div>{row.address}</div>
          <div>{row.city.name}</div>
        </div>
      ),
      width: "15%",
      sortable: true,
    },
    {
      name: "Type de candidat",
      selector: (row) => (
        <div>
          <div>{row.type.name}</div>
          <div> {row.experience} ans d'éxpériences</div>
        </div>
      ),
      width: "15%",
      sortable: true,
    },

    {
      name: "Compétences",
      selector: (row) => row.skills,
      width: "11%",
      sortable: true,
    },
    {
      name: "Actions",
      width: "15%",
      cell: (row) => (
        <div className="row">
          <Link
            className="col-3 btn text-primary "
            to={`/candidats/edit/${row.id}`}
            data-toggle="tooltip"
            data-placement="top"
            title="Modifier candidat"
          >
            <i className="bi bi-pen"></i>
          </Link>
          <button
            className="col-3 btn text-primary  btn"
            onClick={() => {
              enableDisable(row.id, row.isEnabled);
            }}
          >
            {row.isEnabled == "yes" ? (
              <i
                className="text-success bi bi-toggle2-on"
                data-toggle="tooltip"
                data-placement="top"
                title="Désactiver candidat"
              ></i>
            ) : (
              <i
                className="text-danger bi bi-toggle2-off"
                data-toggle="tooltip"
                data-placement="top"
                title="Activer candidat"
              ></i>
            )}
          </button>
          <Link
            className="col-3 btn text-default "
            to={`/candidats/details/${row.id}`}
            data-toggle="tooltip"
            data-placement="top"
            title="Profile candidat"
          >
            <i className="ri-eye-fill"></i>
          </Link>

          <button
            className="col-3 btn text-warning"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Créer note"
            onClick={() => {
              setAddNote(
                <CreateNote
                  setAddNoteModalFromChild={setAddNote}
                  candidatId={row.id}
                />
              );
            }}
          >
            <i className="ri-sticky-note-line"></i>
          </button>
          <div className="hstack gap-3"></div>
          <button
            className="col-3 btn text-primary "
            onClick={() =>
              setaddEmployeeModal(
                <CadidatToEmplyee
                  setaddEmployeeModalFromChild={setaddEmployeeModal}
                  candidatId={row.id}
                  getAllCandidats={getAllCandidats}
                />
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-person-add"
              viewBox="0 0 16 16"
            >
              <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
              <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
            </svg>
          </button>
          {/* <button
            className="col-3 btn text-success"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Créer note"
            onClick={() => {
              setAddNote(
                <CreateCV
                  setAddNoteModalFromChild={setAddNote}
                  candidatId={row.id}
                />
              );
            }}
          >
            <i className="bi bi-file-person"></i>
          </button> */}
        </div>
      ),
    },
  ];
  function handleTelecharger() {
    setTelechargerCandidatModal(
      <TelechargerModal
        setTelechargerCandidatModalFromChild={setTelechargerCandidatModal}
      />
    );
  }
  function filterCandidats(searchText) {
    const items = candidats;
    const results = items.filter(
      (item) =>
        (item.skills &&
          item.skills.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.firstname &&
          item.firstname.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.lastname &&
          item.lastname.toLowerCase().includes(searchText.toLowerCase()))
    );
    setFiltredCandidats(results);
  }

  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Les Candidats</h1>
          </div>
          <div className="col-md-4">
            <Link to="/">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2"
              >
                Retour
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">Candidats</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-6">
                {/* <button
                  type="button"
                  className="btn btn-primary btn-rounded float-end text-sm"
                  onClick={handleTelecharger}
                >
                  <i className="bi bi-plus-lg"></i> Telecharger via cv
                </button> */}
              </div>
              <div className="col-6">
                <Link to="/Ajouter_candidats">
                  <button
                    type="button"
                    className="btn btn-primary btn-rounded float-end text-sm"
                  >
                    <i className="bi bi-plus-lg"></i> Ajouter un candidat
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header d-flex justify-content-end">
                <div className="col-md-3">
                  <input
                    type="text"
                    onChange={(e) => filterCandidats(e.target.value)}
                    className="form-control"
                    placeholder="filtrer par les compétences"
                  />
                </div>
              </div>
              <div className="card-body  pt-3">{data}</div>
            </div>
          </div>
        </div>
      </section>
      {addNote}
      {addEmployeeModal}
      {telechargerCandidatModal}
    </div>
  );
};

export default Candidats;
