import React, { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { baseURL } from "../../config/config";
import AddTypes from "../../components/condidatesTypes/AddTypes";
import EditTypes from "../../components/condidatesTypes/EditTypes";
import EmptyData from "../../components/emptyData";
import Loader from "../../components/loader";

const CondidatsTypes = () => {
  const [addTypesModal, setaddTypesModal] = React.useState();
  const [types, setTypes] = React.useState(null);
  const [data, setData] = React.useState();

  async function getAllTypes() {
    axios.get(`${baseURL}/candidatTypes`).then((response) => {
      if (response.data.error) return;
      setTypes(response.data.data);
    });
  }

  useEffect(() => {
    setData(<Loader />);
    getAllTypes();
  }, []);

  useEffect(() => {
    if (types == null) return;
    if (types.length > 0) {
      setData(<DataTable columns={columns} data={types} pagination />);
    } else {
      setData(<EmptyData />);
    }
  }, [types]);
  function toggle(type_id) {
    axios
      .get(`${baseURL}/candidatTypes/toggleStatus/${type_id}`)
      .then((response) => {
        if (response.data.error) return;
        getAllTypes();
      });
  }

  const columns = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "15%",
      sortable: true,
    },
    {
      name: "Types des condidats",
      selector: (row) => row.name,
      width: "65%",
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="row">
          <div className="col-6">
            <button type="button" className="btn text-primary me-2">
              <i
                className="bi bi-pen"
                onClick={() =>
                  setaddTypesModal(
                    <EditTypes
                      setAddTypesModalFromChild={setaddTypesModal}
                      typesId={row.id}
                      getAllTypes={getAllTypes}
                    />
                  )
                }
              ></i>
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-outline"
              onClick={() => {
                toggle(row.id);
              }}
            >
              {row.isEnabled === "yes" ? (
                <i className="bi bi-eye"></i>
              ) : (
                <i className="bi bi-eye-slash"></i>
              )}
            </button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Les Types des candidats</h1>
          </div>
          <div className="col-md-4">
            <Link to="/">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2"
              >
                Retour
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">Types des Candidats</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4">
            <button
              type="button"
              className="btn btn-primary btn-rounded float-end"
              onClick={() =>
                setaddTypesModal(
                  <AddTypes
                    setAddTypesModalFromChild={setaddTypesModal}
                    setTypesList={setTypes}
                  />
                )
              }
            >
              <i className="bi bi-plus-lg"></i> Ajouter
            </button>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body pt-3">{data}</div>
            </div>
          </div>
        </div>
        {addTypesModal}
      </section>
    </div>
  );
};
export default CondidatsTypes;
