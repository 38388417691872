import React from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";

function ChangePassword({ setChangePasswordModalFromChild, userId }) {
  const [password, setPassword] = React.useState();
  const [confirmPassword, setConfirmPassword] = React.useState();

  const initFormErrors = {
    password: [""],
    confirmPassword: [""],
  };
  const [formerrors, setFormErrors] = React.useState(initFormErrors);

  function sendData() {
    axios
      .post(`${baseURL}/users/changePassword/${userId}`, {
        password: password,
        confirm_password: confirmPassword,
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setTimeout(() => {
          setChangePasswordModalFromChild("");
        }, 2400);
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }

  return (
    <div>
      <div
        className="modal fade show"
        id="password_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <input
                type="password"
                className="form-control"
                id="new_password"
                placeholder="Nouveau Mot de Passe"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div className="input-error">
                <span className="text-danger">{formerrors.password}</span>
              </div>
            </div>
            <div className="modal-body">
              <input
                type="password"
                className="form-control"
                id="confirm_password"
                placeholder="Confirmation Mot de Passe"
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <div className="input-error">
                <span className="text-danger">
                  {formerrors.confirm_password}
                </span>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setChangePasswordModalFromChild("")}
              >
                Annuler
              </button>
              <button
                type="button"
                onClick={sendData}
                className="btn btn-primary"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default ChangePassword;
