import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import Loader from "../components/loader";
import EmptyData from "../components/emptyData";
import swal from "sweetalert";
import { baseURL, MatchingServer } from "../config/config";
import { UserContext } from "../context/UserContext";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import listPlugin from "@fullcalendar/list";
import { useRef } from "react";
import AddEventModal from "../components/EmplyeeUser/EmplyeeUseraddevent";
import frLocale from "@fullcalendar/core/locales/fr";
import FollowUpTimesheet from "../components/followUpTimesheet";
const EmplyeeHome = () => {
  const [userData, setUserData] = React.useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const { user, setUser } = useContext(UserContext);
  const [data, setData] = React.useState();
  const [addNote, setAddNote] = React.useState();
  const [missionData, setMissionData] = React.useState([]);
  const [mission, setMission] = React.useState([]);
  const [addEmployeeModal, setaddEmployeeModal] = React.useState();

  const [userId, setUserId] = useState([]);
  const [calender, setCalender] = useState([]);
  const [eventsDb, setEventsDb] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventModal, SetEventModal] = useState();

  const calendarRef = useRef(null);

  const [followUpModal, setFollowUpsModal] = useState("");

  async function GetEmpsUser() {
    const id = user.id;
    axios
      .get(`${MatchingServer}/employeeUser/getuser/${id}`)
      .then((response) => {
        if (response.data.error) return;

        setUserData(response.data.data.user);
        setEmployeeData(response.data.data.employee);
      });
  }

  useEffect(() => {
    getMissionByEmplyeeId();
    // getAllWorkDays();
    getWorkDays();
  }, [employeeData]);
  function handleDateClick(info) {
    SetEventModal(
      <AddEventModal
        missions={mission}
        SetEventModalFromChild={SetEventModal}
        employeeData={employeeData._id}
        getWorkDays={getWorkDays}
        info={info}
      />
    );
  }

  function getWorkDays() {
    const employeeId = employeeData._id;
    axios
      .get(`${MatchingServer}/workdays/days/${employeeId}`)
      .then((response) => {
        if (response.data.error) return;
        setEventsDb(response.data.data);
      });
  }

  function MyEvent({ title, type, hour, mission }) {
    return (
      <div className="FullCalevent" data-type={type} data-hour={hour}>
        <p>Tache: {title}</p>
        <p>Type: {type}</p>
        <p> mission: {mission.title}</p>
        <p> Nbr d'heur: {hour}</p>
      </div>
    );
  }
  function eventContent(info) {
    return (
      <MyEvent
        title={info.event.title}
        type={info.event.extendedProps.type}
        hour={info.event.extendedProps.hour}
        mission={info.event.extendedProps.mission}
      />
    );
  }
  function eventBackgroundColor(info) {
    const type = info.event.extendedProps.type;
    if (type === "jour de travaile" || type === "travaile") {
      info.el.style.background = "#3788d8";
    } else if (type === "maladie" || type === "jour maladie") {
      info.el.style.background = "#ff00009c";
    } else {
      info.el.style.background = "#bebebe";
    }
  }
  function gettodat() {
    let endDAte = new Date();
    endDAte.setDate(endDAte.getDate() + 1);
    endDAte = endDAte.toISOString().split("T")[0];
    const validRange = {
      // start: "2023-03-03",
      end: endDAte,
    };
    return validRange;
  }
  function handleFollowUpClick() {
    setFollowUpsModal(
      <FollowUpTimesheet
        employeeId={employeeData._id}
        SetFollowUpModalFromChild={setFollowUpsModal}
      />
    );
  }
  function handleEvenClick(info) {
    SetEventModal(
      <AddEventModal
        SetEventModalFromChild={SetEventModal}
        employeeData={employeeData._id}
        getWorkDays={getWorkDays}
        info={info}
      />
    );
  }

  useEffect(() => {
    setEvents(eventsDb);
  }, [eventsDb]);
  useEffect(() => {
    setCalender(
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        locales={[frLocale]}
        validRange={gettodat}
        weekends={true}
        droppable={true}
        events={events}
        eventContent={eventContent}
        eventDidMount={eventBackgroundColor}
        dayMaxEvents={2}
        dateClick={handleDateClick}
        eventClick={handleEvenClick}
      />
    );
  }, [events]);
  useEffect(() => {
    setUserId(user.id);
    setData(<Loader />);
    GetEmpsUser();
  }, []);

  useEffect(() => {
    if (mission == null) return;
    if (mission.length > 0) {
      setMissionData(
        <DataTable columns={columnsMission} data={mission} pagination />
      );
    } else {
      setMissionData(<EmptyData />);
    }
  }, [mission]);

  async function getMissionByEmplyeeId() {
    const emplyeeId = employeeData._id;
    if (emplyeeId === null) return;
    axios
      .get(`${baseURL}/missionEmployees/missions/${emplyeeId}`)
      .then((response) => {
        if (response.data.error) return;
        setMission(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const columnsMission = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "6%",
      sortable: true,
    },
    {
      name: "Titre",
      selector: (row) => (
        <div>
          <div>{row.mission.title}</div>
        </div>
      ),
      width: "18%",
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (
        <div className="widthDescriptionMission">
          <div>{row.mission.description}</div>
        </div>
      ),
      width: "10rem",
      sortable: true,
    },
    {
      name: "Date debut de mission",
      selector: (row) => (
        <div>
          <div>{row.mission.startDate}</div>
        </div>
      ),
      width: "18%",
      sortable: true,
    },
    {
      name: "Période",
      selector: (row) => (
        <div>
          <div>{row.mission.period}</div>
        </div>
      ),
      width: "12%",
      sortable: true,
    },
    {
      name: "Résponsable",
      selector: (row) => (
        <div>
          <div>
            {row.mission.manager != null && row.mission.manager.fname}{" "}
            {row.mission.manager != null && row.mission.manager.lname}
          </div>
        </div>
      ),
      width: "13%",
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="row col-12">
            <Link
              className="col-3 btn text-primary me-2"
              to={`/missions/details/${row.mission._id}`}
            >
              <i className="ri-arrow-right-s-fill"></i>
            </Link>
          </div>
        );
      },
      width: "9%",
    },
  ];

  return (
    <>
      <h1>Bonjour {user.name}</h1>
      <div>
        <div className="pagetitle row">
          <div className="col-md-8 pt-3">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">Acceuil</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4 align-items-right">
            <Link to="/">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2"
              >
                Retour
              </button>
            </Link>
          </div>
        </div>
        <section className="section profile  ">
          <div className="row">
            <div className="col-xl-4 pt-5">
              <div className="card">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center text-uppercase ">
                  <img
                    src={`http://localhost:5000${
                      user != null && user.avatar.path.substring(1)
                    }`}
                    alt="Profile"
                    className="rounded-circle"
                  />
                  <h2 className="text-center">{user != null && user.name}</h2>
                  <div className="row text-lowercase col-md-12 mt-4 flex">
                    <div className="row">
                      <div className="col-md-1 label">
                        <i className="bi bi-envelope-fill"></i>{" "}
                      </div>
                      <div className="col-md-10">
                        <a
                          href={`mailto:${user.email}`}
                          className="color-jcit "
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Envoyer mail"
                        >
                          {" "}
                          {user != null && user.email}
                        </a>{" "}
                      </div>

                      <div
                        className="col-md-10"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Envoyer mail"
                      ></div>
                    </div>

                    <div className="row">
                      <div className="col-md-1 label">
                        <i className="bi bi-telephone-fill"></i>{" "}
                      </div>
                      <div className="col-md-10">
                        <a
                          href={`tel:+${user.phone}`}
                          className="color-jcit "
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Appeler"
                        >
                          {" "}
                          {user != null && user.phone}{" "}
                        </a>{" "}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-1 label">
                        <i className="bi bi-geo-alt  me-1"></i>
                      </div>
                      <div className="col-md-10">
                        {user != null && user.address}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-1 label">
                        <i className=" bi bi-briefcase me-1"></i>
                      </div>
                      <div className="col-md-10">
                        {employeeData != null && employeeData.job}{" "}
                        {employeeData.experience}
                        ans
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-1 label">
                        <i className=" bi bi-gift me-1"></i>
                      </div>
                      <div className="col-md-10">
                        {employeeData != null && employeeData.birthday}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-secondary mb-2 float-end  me-2"
                      onClick={handleFollowUpClick}
                    >
                      Suivie
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8" style={{ height: "500 px" }}>
              <div className="pagetitle row">
                <h1>Les Mission</h1>
              </div>
              <section className="section">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header d-flex ">
                        Mission affectees
                      </div>
                      <div className="card-body  pt-3">{missionData}</div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
        {followUpModal}
        {addNote}
        {addEmployeeModal}
        {eventModal}
        <div className="card">
          <div className="card-body" id="mycalendar">
            {calender}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmplyeeHome;
