import React, { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { baseURL } from "../../config/config";

const CreateCompany = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [owner, setOwner] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [logo, setLogo] = React.useState("");
  const [webSite, setWebSite] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [cities, setCities] = React.useState();
  const [city, setCity] = React.useState("");
  const [country, setCountry] = React.useState();
  const [countries, setCountries] = React.useState();
  const [description, setDescription] = React.useState("");
  const [siren, setSiren] = React.useState("");
  const [siret, setSiret] = React.useState("");
  const [tvaNumber, setTvaNumber] = React.useState("");
  const [rcs, setRcs] = React.useState("");
  const history = useHistory();
  const initFormErrors = {
    name: [""],
    email: [""],
    phone: [""],
    owner: [""],
    webSite: [""],
    address: [""],
    city: [""],
    logo: [""],
    country: [""],
    siren: [""],
    siret: [""],
    tva_number: [""],
    rcs: [""],
  };
  const [formerrors, setFormErrors] = React.useState(initFormErrors);

  function getCities() {
    axios.get(`${baseURL}/apiData/cities/${country}`).then((resp) => {
      if (resp.data.error) return;
      setCities(resp.data.data);
    });
  }
  useEffect(() => {
    if (country === undefined) return;
    getCities();
  }, [country]);

  function getCountries() {
    axios.get(`${baseURL}/apiData/countries`).then((resp) => {
      if (resp.data.error) return;
      setCountries(resp.data.data);
    });
  }

  useEffect(() => {
    getCountries();
  }, []);

  function sendData() {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("logo", logo);
    formData.append("email", email);
    formData.append("owner", owner);
    formData.append("phone", phone);
    formData.append("website", webSite);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("description", description);
    formData.append("siren", siren);
    formData.append("siret", siret);
    formData.append("tva_number", tvaNumber);
    formData.append("rcs", rcs);
    axios
      .post(`${baseURL}/companies/create`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setTimeout(() => {
          history.push("/company");
        }, 2400);
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }

  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Ajouter une société</h1>
          </div>
          <div className="col-md-4">
            <Link to="/company">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2"
              >
                Retour
              </button>
            </Link>
          </div>
        </div>

        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"}>
                <i className="bi bi-house-door"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/company"}>Société</Link>
            </li>
            <li className="breadcrumb-item active">Ajouter une société</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12"></div>
          <div className="card">
            <div className="card-body pt-3">
              <form className="row g-3">
                <div className="col-md-6">
                  <label>Nom</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setName(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.name}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Responsable</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setOwner(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.owner}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Téléphone</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.phone}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.email}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Site Web</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setWebSite(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.website}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Adresse</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.address}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Description</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {formerrors.description}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Siren</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setSiren(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.siren}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Siret</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setSiret(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.siret}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Numéro TVA intracommunautaire</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setTvaNumber(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.tva_number}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <label>RCS</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setRcs(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.rcs}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Pays</label>
                  <select
                    id="inputCountry"
                    className="form-select"
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option selected>Sectionner un Pays ...</option>
                    {countries != null &&
                      countries.map((country, key) => {
                        return (
                          <option key={key} value={country.id}>
                            {country.name}
                          </option>
                        );
                      })}
                  </select>
                  <div className="input-error">
                    <span className="text-danger">{formerrors.country}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Ville</label>
                  <select
                    id="inputState"
                    className="form-select"
                    onChange={(e) => setCity(e.target.value)}
                  >
                    <option selected>Sectionner une ville ...</option>
                    {cities != null &&
                      cities.map((item, key) => {
                        return (
                          <option key={key} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  <div className="input-error">
                    <span className="text-danger">{formerrors.city}</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <label>Logo</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setLogo(e.target.files[0])}
                  />
                </div>

                <div className="text-end">
                  <Link to="/company">
                    <button
                      type="button"
                      className="btn btn-outline-secondary  me-2"
                    >
                      Annuler
                    </button>
                  </Link>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={sendData}
                  >
                    Enregistrer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default CreateCompany;
