import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useHistory, useParams } from "react-router";
import { baseURL } from "../../config/config";
import { Link } from "react-router-dom";
import Select from "react-select";
const initFormErrors = {
  lastname: [""],
  firstname: [""],
  email: [""],
  phone: [""],
  address: [""],
  cp: [""],
  birthDay: [""],
  lieu_naissance: [""],
  diplome: [""],
  annéeGraduation: [""],
  type: [""],
  langues: [""],
  avatar: [""],
  nirpp: [""],
  typeOfContract: [""],
  startDate: [""],
  salary: [""],
  job: [""],
  company: [""],
  skills: [""],
  pays: [""],
  city: [""],
  birthPlace: [""],
};
const UpdateSupplier = () => {
  const history = useHistory();
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  const [birthPlace, setBirthPlace] = React.useState("");
  const [gender, setGender] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [lieu_naissance, setLieu_naissance] = useState("");
  const [status, setStatus] = useState("");
  const [address, setAddress] = useState("");
  const [countries, setCountries] = React.useState();
  const [pays, setPays] = useState("");
  const [city, setCity] = useState([]);
  const [city1, setCity1] = useState("");
  const [cp, setCp] = useState("");
  const [nirpp, setNirpp] = useState("");
  const [experience, setExperience] = useState("");
  const [skills, setSkills] = useState("");
  const [langues, setLangues] = useState("");
  const [diplome, setDiplome] = useState("");
  const [diplomeDate, setDiplomeDate] = useState("");
  const [type, setType] = useState("");

  const [typeOfContract, setTypeOfContract] = useState("");
  const [salary, setSalary] = useState("");
  const [startDate, setStartDate] = useState("");

  const [supplier, setSupplier] = useState("");
  const [job, setJob] = useState("");
  const { supplierId } = useParams();
  const [errorMessage, setErrorMessage] = useState(initFormErrors);

  function getCities(countryid) {
    axios.get(`${baseURL}/apiData/cities/${countryid}`).then((resp) => {
      if (resp.data.error) return;
      setCity(resp.data.data);
    });
  }
  function getCountries() {
    axios.get(`${baseURL}/apiData/countries`).then((resp) => {
      if (resp.data.error) return;
      setCountries(resp.data.data);
    });
  }
  useEffect(() => {
    if (pays === "") return;
    getCities(pays);
  }, [pays]);

  React.useEffect(() => {
    if (supplierId === undefined) return;
    axios.get(`${baseURL}/employees/${supplierId}`).then((resp) => {
      if (resp.data.error) return;
      const supplierData = resp.data.data;
      setFname(supplierData.fname);
      setGender(supplierData.gender);
      setLname(supplierData.lname);
      setEmail(supplierData.email);
      setAddress(supplierData.address);
      setNirpp(supplierData.nirpp);
      setPhone(supplierData.phone);
      setCp(supplierData.cp);
      setDiplome(supplierData.diplome);
      setDiplomeDate(supplierData.diplomeDate);
      setBirthDay(supplierData.birthday);
      setLieu_naissance(supplierData.birthPlace);
      setPays(supplierData.city.country);
      setStatus(supplierData.status);
      setSkills(supplierData.skills);
      setLangues(supplierData.langues);
      setExperience(supplierData.experience);
      setType(supplierData.type);
      setTypeOfContract(supplierData.typeOfContract);
      setJob(supplierData.job);
      setSalary(supplierData.salary);
      setCity1(supplierData.city._id);
      setStartDate(supplierData.startDate);

      setSupplier(supplierData);
      setBirthPlace(supplierData.birthPlace);
    });
    getCountries();
  }, [supplierId]);

  function sendData() {
    const formData = {
      gender: gender,
      fname: fname,
      lname: lname,
      email: email,
      pays: pays,
      city: city1,
      address: address,
      cp: cp,
      phone: phone,
      birthday: birthDay,
      birthPlace: lieu_naissance,
      diplomeDate: diplomeDate,
      diplome: diplome,
      type: type,
      langues: langues,
      status: status,
      skills: skills,
      experience: experience,
      nirpp: nirpp,
      typeOfContract: typeOfContract,
      salary: salary,
      job: job,
      startDate: startDate,
      birthPlace: birthPlace,
    };
    axios
      .put(`${baseURL}/employees/${supplierId}`, formData)
      .then((resp) => {
        setErrorMessage(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setTimeout(() => {
          history.push("/supplier");
        }, 2400);
      })
      .catch((error) => {
        console.log(error);
        const errors = error.response.data;
        setErrorMessage(errors);
      });
  }

  const languesOptions = [
    { value: "français", label: "Français" },
    { value: "english", label: "Anglais" },
    { value: "arabic", label: "Arabe" },
    { value: "spanish", label: "Espagnole" },
    { value: "deutsh", label: "Allemand" },
    { value: "Italien", label: "Italien" },
  ];
  return (
    <div>
      <h1 className="text-center color-jcit fw-bolder pb-5">
        Modifier Fiche de fournisseur
      </h1>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Les fournisseurs</h1>
          </div>
          <div className="col-md-4">
            <Link to="/supplier">
              <button
                type="button"
                className="btn btn-outline-secondary float-end  me-2"
              >
                Retour
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">fournisseur</li>
                <li className="breadcrumb-item active">modifier fournisseur</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="row">
          <section className="styleSection1">
            <div className="card-body pt-4 border ">
              {" "}
              <div className="row">
                <div className="col-md-4">
                  <label className="color-jcit fw-bold">Vous étes :</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="male"
                      value="male"
                      checked={gender === "male"}
                      onChange={(e) => setGender(e.target.value)}
                    />
                    <label className="form-check-label">Homme</label>
                  </div>{" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="myCheck"
                      value="femele"
                      checked={gender === "femele"}
                      onChange={(e) => setGender(e.target.value)}
                    />
                    <label className="form-check-label">Femme</label>
                  </div>{" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="myCheck"
                      value="other"
                      checked={gender === "other"}
                      onChange={(e) => setGender(e.target.value)}
                    />

                    <label className="form-check-label">Autre</label>
                  </div>
                  <div className="input-error">
                    <span className="text-danger"> {errorMessage.gender}</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <label className="color-jcit fw-bold">Nom * :</label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    onChange={(e) => setFname(e.target.value)}
                    value={fname}
                  />
                  <div className="input-error">
                    <span className="text-danger"> {errorMessage.lname}</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <label className="color-jcit fw-bold">Prénom * :</label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    onChange={(e) => setLname(e.target.value)}
                    value={lname}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger"> {errorMessage.name}</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <label className="color-jcit fw-bold">Email * : </label>
                  <input
                    type="email"
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger"> {errorMessage.email}</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <label className="color-jcit fw-bold">Téléphone * : </label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger"> {errorMessage.phone}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 mt-4">
            <div className="card-body ">
              <fieldset className="border1 p-4">
                <legend className="float-none w-auto p-2">
                  <h3 className="pb-2 textcard_supplier">
                    Informations personnelles
                  </h3>
                </legend>
                <div className="row">
                  {" "}
                  <div className="row pb-3">
                    <div className="col-md-4">
                      <div>
                        <label className="color-jcit fw-bold">
                          Etat civil * :
                        </label>
                        <select
                          id="inputEtatCivil"
                          className="form-select"
                          onChange={(e) => setStatus(e.target.value)}
                          value={status}
                        >
                          <option selected>{""}</option>
                          <option value="single">Célibataire</option>
                          <option value="in_relationship">En couple</option>
                          <option value="married">Marié</option>
                          <option value="divorced">Divorcé</option>
                          <option value="pacs">Pacsé</option>
                        </select>
                      </div>
                      <div className="input-error">
                        <span className="text-danger">
                          {" "}
                          {errorMessage.status}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label className="color-jcit fw-bold">pays *</label>
                      <div className="input-group mb-3">
                        <select
                          id="inputState"
                          className="form-select"
                          value={pays}
                          onChange={(e) => setPays(e.target.value)}
                        >
                          <option selected></option>
                          {countries != null &&
                            countries.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="input-error">
                        <span className="text-danger">{errorMessage.pays}</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label className="color-jcit fw-bold">Ville *</label>
                      <div className="input-group mb-3">
                        <select
                          id="inputState"
                          className="form-select"
                          value={city1}
                          onChange={(e) => setCity1(e.target.value)}
                        >
                          <option selected></option>
                          {city != null &&
                            city.map((gtcity, index) => {
                              return (
                                <option key={index} value={gtcity.id}>
                                  {gtcity.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="input-error">
                        <span className="text-danger">{errorMessage.city}</span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div>
                        <label className="color-jcit fw-bold">
                          Adresse * :{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setAddress(e.target.value)}
                          value={address}
                        />
                      </div>
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.address}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row pb-4">
                    <div className="col-md-4">
                      <div>
                        <label className="color-jcit fw-bold">
                          Code postal * :
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setCp(e.target.value)}
                          value={cp}
                        />
                      </div>
                      <div className="input-error">
                        <span className="text-danger"> {errorMessage.cp}</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="box">
                        <div className="date-picker">
                          <label className="color-jcit fw-bold">
                            Date de naissance *
                          </label>
                          <input
                            className="form-control"
                            type="date"
                            id="datePicker"
                            onChange={(e) => setBirthDay(e.target.value)}
                            value={birthDay}
                          />
                        </div>
                      </div>
                      <div className="input-error">
                        <span className="text-danger">
                          {" "}
                          {errorMessage.birthDay}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className="color-jcit fw-bold">
                          lieu de naissance * :
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setBirthPlace(e.target.value)}
                          value={birthPlace}
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {" "}
                            {errorMessage.birthPlace}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </section>
          <section className="styleSection1 mt-4">
            <div className="card-body pt-4 ">
              <fieldset className="border1 p-4">
                <legend className="float-none w-auto p-2">
                  <h3 className="pb-2 textcard_supplier">
                    Informations professionnelle
                  </h3>
                </legend>
                <div className="row pb-3">
                  {" "}
                  <div className="col-md-4">
                    <div>
                      <label className="color-jcit fw-bold">Diplome * :</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setDiplome(e.target.value)}
                        value={diplome}
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {" "}
                          {errorMessage.diplome}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-jcit fw-bold">
                        Année de diplome * :
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => setDiplomeDate(e.target.value)}
                        value={diplomeDate}
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {" "}
                          {errorMessage.diplomeDate}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-jcit fw-bold">langue * : </label>
                      <Select
                        options={languesOptions}
                        value={
                          langues !== "" &&
                          languesOptions.filter((option) =>
                            JSON.parse(langues).includes(option.value)
                          )
                        }
                        isMulti
                        onChange={(e) => {
                          let lg = [];
                          e.forEach((lang, key) => {
                            lg.push(lang.value);
                          });
                          setLangues(JSON.stringify(lg));
                        }}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.langues}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-jcit fw-bold">NIRPP * :</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => setNirpp(e.target.value)}
                        value={nirpp}
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {" "}
                          {errorMessage.nirpp}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-md-4">
                    <div>
                      <label className="color-jcit fw-bold">
                        type de contrat * :{" "}
                      </label>
                      <select
                        className="form-select"
                        name="typeOfContract"
                        onChange={(e) => setTypeOfContract(e.target.value)}
                        value={typeOfContract}
                      >
                        <option selected></option>
                        <option value="cdd">CDD</option>
                        <option value="cdi">CDI</option>
                        <option value="internship">INTERNSHIP</option>
                      </select>
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.typeOfContract}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-jcit fw-bold">poste * :</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setJob(e.target.value)}
                        value={job}
                      />
                      <div className="input-error">
                        <span className="text-danger"> {errorMessage.job}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="box">
                      <div className="date-picker">
                        <label className="color-jcit fw-bold">
                          Date de début *
                        </label>
                        <input
                          className="form-control"
                          type="date"
                          onChange={(e) => setStartDate(e.target.value)}
                          value={startDate}
                        />
                      </div>
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.startDate}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="color-jcit fw-bold">
                      Type de l'employé *
                    </label>
                    <select
                      className="form-select"
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                      name="type"
                      value={type}
                    >
                      <option selected></option>
                      <option value="supplier">Fournisseur</option>
                      <option value="employee">Employé</option>
                    </select>

                    <div className="input-error">
                      <span className="text-danger">{errorMessage.type}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="box">
                      <div className="date-picker">
                        <label className="color-jcit fw-bold">salaire *</label>
                        <input
                          className="form-control"
                          type="number"
                          onChange={(e) => setSalary(e.target.value)}
                          value={salary}
                        />
                      </div>
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.salary}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-jcit fw-bold">
                        Expérience * :
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => setExperience(e.target.value)}
                        value={experience}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.experience}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-jcit fw-bold">
                        Compétence * :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSkills(e.target.value)}
                        value={skills}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.skills}
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </section>
          <div className="row pb-4"></div>
        </div>
        <div className="text-end">
          <Link to="/supplier">
            <button type="button" className="btn btn-outline-secondary  me-2">
              Annuler
            </button>
          </Link>
          <button type="button" className="btn btn-primary" onClick={sendData}>
            Enregistrer
          </button>
        </div>
      </section>
    </div>
  );
};

export default UpdateSupplier;
