import axios from "axios";
import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import EmptyData from "../../components/emptyData";
import Loader from "../../components/loader";
import { baseURL } from "../../config/config";
import EditMission from "../../components/mission/updateMission";
import CreateMission from "../../components/mission/createMission";
const Missions = () => {
  const [mission, setMission] = React.useState(null);
  const [data, setData] = React.useState();
  const [addMissionModal, setAddMissionModal] = React.useState();
  const [Filtredmission, setFiltredmission] = React.useState(null);
  async function getAllMission() {
    axios
      .get(`${baseURL}/missions/allMissions`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setMission(respData);
      })
      .catch((err) => {});
  }
  async function getMissionById(missionId) {
    axios
      .get(`${baseURL}/missions/${missionId}`)
      .then((response) => {
        if (response.data.error) return;
        setMission(response.data.data);
      })
      .catch((err) => {});
  }
  useEffect(() => {
    setData(<Loader />);
    getAllMission();
  }, []);
  useEffect(() => {
    setData(<Loader />);
    getAllMission();
  }, [addMissionModal]);
  // {
  //   console.log("test1", companyId);
  // }
  useEffect(() => {
    if (mission == null) return;
    if (mission.length > 0) {
      setData(<DataTable columns={columns} data={mission} pagination />);
    } else {
      setData(<EmptyData />);
    }
  }, [mission]);
  useEffect(() => {
    if (Filtredmission == null) return;
    if (Filtredmission.length > 0) {
      setData(<DataTable columns={columns} data={Filtredmission} pagination />);
    } else {
      setData(<EmptyData />);
    }
  }, [Filtredmission]);

  async function enableDisable(missionId, isEnabled) {
    let message =
      isEnabled == "yes"
        ? "voulez vous Désactiver cette mission?"
        : "Voulez vous Activer cette mission?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/missions/toggleStatus/${missionId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;

            if (!usersInfo.error) {
              getAllMission();
            }
          });
      }
    });
  }
  function filtermissionbydescription(searchText) {
    const items = mission;
    const results = items.filter(
      (item) =>
        item.description &&
        item.description.toLowerCase().includes(searchText.toLowerCase())
    );
    setFiltredmission(results);
  }

  const columns = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "5%",
      height: "10rem",
      sortable: true,
    },
    {
      name: "titre",
      selector: (row) => (
        <div className="row">
          {/* style={{ height: "5rem" }} */}
          <Link className="color-jcit " to={`/missions/details/${row.id}`}>
            {row.title}
          </Link>
        </div>
      ),
      width: "15%",
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (
        <div className="widthDescriptionMission">
          <div>{row.description}</div>
        </div>
      ),
      width: "10rem",
      sortable: true,
    },
    {
      name: "Date debut de mission",
      selector: (row) => (
        <div>
          <div>{row.startDate}</div>
        </div>
      ),
      width: "18%",
      sortable: true,
    },
    {
      name: "Période",
      selector: (row) => (
        <div>
          <div>{row.period}</div>
        </div>
      ),
      width: "10%",
      sortable: true,
    },
    {
      name: "sociéte",
      selector: (row) => (
        <div>{row.company ? <div> {row.company.name}</div> : null}</div>
      ),
      width: "10%",
      sortable: true,
    },
    {
      name: "Résponsable",
      selector: (row) => (
        <div>
          <div>
            {row.manager != null && row.manager.fname}{" "}
            {row.manager != null && row.manager.lname}
          </div>
        </div>
      ),
      width: "13%",
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="row col-12">
            <button
              className="col-3 btn text-primary me-2"
              onClick={() =>
                setAddMissionModal(
                  <EditMission
                    setAddMissionModalFromChild={setAddMissionModal}
                    missionId={row.id}
                    getMissionByCompanyId={getAllMission}
                    specification="mission"
                  />
                )
              }
            >
              <i className="bi bi-pen"></i>
            </button>
            <button
              className="col-3 btn text-primary me-2 btn"
              onClick={() => {
                enableDisable(row.id, row.isEnabled);
              }}
            >
              {" "}
              {row.isEnabled == "yes" ? (
                <i
                  className="text-success bi bi-toggle2-on"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Désactiver mission"
                ></i>
              ) : (
                <i
                  className="text-danger bi bi-toggle2-off"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Activer mission"
                ></i>
              )}
            </button>
            <Link
              className="col-3 btn text-primary me-2"
              data-toggle="tooltip"
              data-placement="top"
              title="details mission"
              to={`/missions/details/${row.id}`}
            >
              <i className="ri-arrow-right-s-fill"></i>
            </Link>
          </div>
        );
      },
      width: "15%",
    },
  ];

  return (
    <div>
      <div className="pagetitle">
        <h1>Les missions</h1>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">mission</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4">
            <button
              type="button"
              className="btn btn-primary btn-rounded float-end text-sm"
              onClick={() =>
                setAddMissionModal(
                  <CreateMission
                    setAddMissionModalFromChild={setAddMissionModal}
                    specification="mission"
                  />
                )
              }
            >
              <i className="bi bi-plus-lg"></i> Ajouter une mission
            </button>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header d-flex justify-content-end">
                <div className="col-md-3">
                  <input
                    type="text"
                    onChange={(e) => filtermissionbydescription(e.target.value)}
                    className="form-control"
                    placeholder="filtrer par description"
                  />
                </div>
              </div>
              <div className="card-body  pt-3">{data}</div>
            </div>
          </div>
          {addMissionModal}
        </div>
      </section>
    </div>
  );
};

export default Missions;
