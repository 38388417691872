import "./App.css";
import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import Home from "./pages/home";
import Header from "./components/header";
import SideBar from "./components/sidebar";
import Footer from "./components/footer";
import Users from "./pages/user/users";
import Role from "./pages/role";
import CreateUser from "./pages/user/createUser";
import UpdateUser from "./pages/user/updateUser";
import Login from "./pages/Login";
import { UserContext } from "./context/UserContext";
import Company from "./pages/company/Company";
import CreateCompany from "./pages/company/CreateCompany";
import CompanyDetails from "./pages/company/CompanyDetails";
import UpdateCompany from "./pages/company/UpdateCompany";
import Candidats from "./pages/candidat/Candidats";
import CreateCandidats from "./pages/candidat/CreateCandidats";
import CondidatsTypes from "./pages/candidat/CondidatsTypes";
import CreateEmployees from "./pages/employee/createEmployees";
import Employees from "./pages/employee/employees";
import CandidatDetails from "./pages/candidat/CandidatDetails";
import UpdateEmployees from "./pages/employee/updateEmployees";
import Supplier from "./pages/supplier/Supplier";
import CreateSupplier from "./pages/supplier/CreateSupplier";
import EmployeesDetails from "./pages/employee/detailsEmployees";
import SupplierDetails from "./pages/supplier/SupplierDetails";
import Process from "./pages/process/Process";
import Missions from "./pages/mission/Missions";
import MissionDetails from "./pages/mission/detailsMission";
import UpdateSupplier from "./components/supplier_component/UpdateSupplier";
import UpdateCandidats from "./pages/candidat/UpdateCandidats";
import Entretien from "./components/Entretien";
import dndProcessMission from "./pages/mission/dndProcessMission";
import AddExperience from "./pages/experience/experience";
import ExpiredLink from "./pages/expiredLink/expiredLink";
import CreateRole from "./pages/CreateRole";
import EditRole from "./pages/EditRole";

import UnauthorizedPage from "./components/UnauthorizedPage";
import { useContext } from "react";
import axios from "axios";
import { baseURL } from "./config/config";
import { PermissionsContext } from "./context/PermissionsContext";
import EmplyeeHome from "./pages/emplyeeHome";
axios.defaults.withCredentials = true;
function App() {
  const [user, setUser] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const currentUrl = window.location.pathname.split("/");

  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  async function getUserPermissions() {
    axios.get(`${baseURL}/checkAuth`).then((response) => {
      if (response.data.error) return;
      if (response.data.data === null) {
        setIsLoggedIn(true);
      } else {
        setUser(response.data.data);
      }
    });
  }

  useEffect(() => {
    getUserPermissions();
  }, []);

  return (
    <>
      {currentUrl[1] === "experience" && currentUrl[2] === "link" ? (
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/experience/link/:linkId"
              component={AddExperience}
            ></Route>
            <Route exact path="/expired" component={ExpiredLink}></Route>
          </Switch>
        </BrowserRouter>
      ) : (
        <UserContext.Provider value={value}>
          <div>
            <BrowserRouter>
              <Switch>
                <React.Fragment>
                  <Route exact path="/login" component={Login}></Route>

                  {value.user == null ? (
                    isLoggedIn ? (
                      <Redirect to="/login" />
                    ) : (
                      ""
                    )
                  ) : (
                    <div>
                      <Header />
                      <SideBar />
                      <main id="main" className="main">
                        <Route exact path="/" component={Home}></Route>
                        <Route exact path="/users" component={Users}></Route>
                        <Route exact path="/roles" component={Role}></Route>
                        <Route
                          exact
                          path="/roles/create"
                          component={CreateRole}
                        ></Route>
                        <Route
                          exact
                          path="/roles/update/:roleId"
                          component={EditRole}
                        ></Route>
                        <Route
                          exact
                          path="/types-candidats"
                          component={CondidatsTypes}
                        ></Route>
                        <Route
                          exact
                          path="/candidats"
                          component={Candidats}
                        ></Route>
                        <Route
                          exact
                          path="/candidats/edit/:candidatId"
                          component={UpdateCandidats}
                        ></Route>
                        <Route
                          exact
                          path="/candidats/details/:candidatId"
                          component={CandidatDetails}
                        ></Route>
                        <Route
                          exact
                          path="/Ajouter_candidats"
                          component={CreateCandidats}
                        ></Route>
                        <Route
                          exact
                          path="/users/create"
                          component={CreateUser}
                        ></Route>
                        <Route
                          exact
                          path="/users/edit/:userId"
                          component={UpdateUser}
                        ></Route>
                        <Route
                          exact
                          path="/company/create"
                          component={CreateCompany}
                        ></Route>
                        <Route
                          exact
                          path="/Company"
                          component={Company}
                        ></Route>
                        <Route
                          exact
                          path="/Company/details/:companyId"
                          component={CompanyDetails}
                        ></Route>
                        <Route
                          exact
                          path="/Company/update/:companyId"
                          component={UpdateCompany}
                        ></Route>
                        <Route
                          exact
                          path="/employees/create"
                          component={CreateEmployees}
                        ></Route>
                        <Route
                          exact
                          path="/employees"
                          component={Employees}
                        ></Route>
                        <Route
                          exact
                          path="/employees/update/:employeeId"
                          component={UpdateEmployees}
                        ></Route>
                        <Route
                          exact
                          path="/supplier"
                          component={Supplier}
                        ></Route>
                        <Route
                          exact
                          path="/supplier/create"
                          component={CreateSupplier}
                        ></Route>
                        <Route
                          exact
                          path="/supplier/edit/:supplierId"
                          component={UpdateSupplier}
                        ></Route>
                        <Route
                          exact
                          path="/employees/suppliers/details/:supplierId"
                          component={SupplierDetails}
                        ></Route>
                        <Route
                          exact
                          path="/employees/details/:employeeId"
                          component={EmployeesDetails}
                        ></Route>
                        <Route
                          exact
                          path="/employees/Home/"
                          component={EmplyeeHome}
                        ></Route>
                        <Route
                          exact
                          path="/process"
                          component={Process}
                        ></Route>
                        <Route
                          exact
                          path="/entretien/:processId"
                          component={Entretien}
                        ></Route>
                        <Route
                          exact
                          path="/missions"
                          component={Missions}
                        ></Route>
                        <Route
                          exact
                          path="/missions/details/:missionId"
                          component={MissionDetails}
                        ></Route>
                        <Route
                          exact
                          path="/missions/process/:missionId"
                          component={dndProcessMission}
                        ></Route>
                        <Route
                          exact
                          path="/error_page"
                          component={UnauthorizedPage}
                        ></Route>
                      </main>
                      <Footer />
                    </div>
                  )}
                </React.Fragment>
              </Switch>
            </BrowserRouter>
          </div>
        </UserContext.Provider>
      )}
    </>
  );
}
export default App;
