import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="copyright">
        &copy; Copyright{" "}
        <strong>
          <span>JCIT-CONSEIL</span>
        </strong>
        . All Rights Reserved
      </div>
      <div className="credits">
        Developed by{" "}
        <a target={"_blank"} href="https://jcitconseil.fr/">
          JCIT-CONSEIL
        </a>
      </div>
    </footer>
  );
};

export default Footer;
