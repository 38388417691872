import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { baseURL } from "../config/config";
import { UserContext } from "../context/UserContext";

const Login = () => {
  const history = useHistory();
  const [passwordType, setPasswordType] = useState("password");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const { user, setUser } = useContext(UserContext);
  const [errMsg, setErrMsg] = useState({
    email: "",
    password: "",
    status: "",
  });

  function initErrorMessage() {
    setErrMsg({
      email: "",
      password: "",
      status: "",
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    initErrorMessage();
    try {
      await axios
        .post(`${baseURL}/login`, { email, password })
        .then((response) => {
          setUser(response.data.data);
        });
    } catch (error) {
      if (error.response.data.message) {
        setErrMsg(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (user === undefined) return;

    history.replace("/");
  }, [user]);

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const togglePassword = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  return (
    <>
      <main className="background-login-page bg-login">
        <div className="container ">
          <section className="section register  d-flex flex-column align-items-center  ">
            <div className="container">
              <div className="row resp_login">
                <div className=" d-flex flex-column align-items-center ">
                  <div className="card mb-3 margin-form">
                    <p aria-live="assertive"></p>
                    <div className="card-body">
                      <div className="d-flex justify-content-center  py-4">
                        <div className="logo d-flex align-items-center w-auto ">
                          <img src="./assets/img/jcit-logo.png" />
                          <span className="jcit-conseil">JCIT-CONSEIL</span>
                        </div>
                      </div>
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Connectez-vous
                        </h5>
                        <p className="text-center small">
                          Entrer votre Email & mot de Passe
                        </p>
                      </div>
                      <p className="text-danger" aria-live="assertive">
                        {errMsg.status != null && errMsg.status}
                      </p>
                      <form
                        className="row g-3 needs-validation"
                        onSubmit={handleSubmit}
                      >
                        <div className="col-12">
                          <label className="form-label">Email</label>
                          <div className="input-group has-validation">
                            <input
                              type="text"
                              id="username"
                              name="username"
                              className="form-control "
                              autoComplete="off"
                              onChange={onChangeEmail}
                              placeholder="Email"
                              required
                            />
                          </div>
                          <p className="text-danger" aria-live="assertive">
                            {errMsg.email != null && errMsg.email}
                          </p>
                        </div>
                        <div className="col-12">
                          <label className="form-label">Mot de Passe</label>
                          <div className="row">
                            <div className="input-group">
                              <input
                                type={passwordType}
                                onChange={onChangePassword}
                                name="password"
                                className="form-control"
                                placeholder="Mot de passe"
                              />
                              <div className="input-group-btn">
                                <button
                                  className="btn btn-outline-primary"
                                  type="button"
                                  onClick={togglePassword}
                                >
                                  {passwordType === "password" ? (
                                    <i className="bi bi-eye-slash"></i>
                                  ) : (
                                    <i className="bi bi-eye"></i>
                                  )}
                                </button>
                              </div>
                            </div>
                            <p className="text-danger" aria-live="assertive">
                              {errMsg.password != null && errMsg.password}
                            </p>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="remember"
                              value="true"
                              id="rememberMe"
                            />
                            <label className="form-check-label">
                              Se souvenir de moi
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                          >
                            Connexion
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
export default Login;
