import axios from "axios";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { baseURL } from "../../config/config";
import Loader from "../../components/loader";
import EditMission from "../../components/mission/updateMission";
import EmptyData from "../../components/emptyData";
import DataTable from "react-data-table-component";
import swal from "sweetalert";

const EmployeesDetails = () => {
  const [employee, setEmployee] = React.useState([]);
  const { employeeId } = useParams();
  const [addMissionModal, setAddMissionModal] = React.useState();
  const [missionData, setMissionData] = React.useState([]);
  const [mission, setMission] = React.useState([]);
  const [EmployeeId, setEmployeeId] = React.useState([]);

  async function getDetailsEmployee() {
    if (employeeId === undefined) return;
    axios.get(`${baseURL}/employees/${employeeId}`).then((response) => {
      if (response.data.error) return;
      setEmployee(response.data.data);
    });
  }
  async function getMission() {
    if (employeeId === null) return;
    axios
      .get(`${baseURL}/missionEmployees/missions/${employeeId}`)
      .then((response) => {
        if (response.data.error) return;
        setMission(response.data.data);
      })
      .catch((err) => {});
  }

  async function enableDisableMission(missionId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver cette mission?"
        : "Voulez vous Activer cette mission?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/missions/toggleStatus/${missionId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getMission();
            }
          });
      }
    });
  }
  useEffect(() => {
    if (mission == null) return;
    if (mission.length > 0) {
      setMissionData(
        <DataTable columns={columnsMission} data={mission} pagination />
      );
    } else {
      setMissionData(<EmptyData />);
    }
  }, [mission]);
  useEffect(() => {
    setMissionData(<Loader />);
    getMission();
    setEmployeeId(employeeId);
  }, []);
  const columnsMission = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "6%",
      sortable: true,
    },
    {
      name: "Titre",
      selector: (row) => (
        <div>
          <div>{row.mission.title}</div>
        </div>
      ),
      width: "18%",
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (
        <div className="widthDescriptionMission">
          <div>{row.mission.description}</div>
        </div>
      ),
      width: "10rem",
      sortable: true,
    },
    {
      name: "Date debut de mission",
      selector: (row) => (
        <div>
          <div>{row.mission.startDate}</div>
        </div>
      ),
      width: "18%",
      sortable: true,
    },
    {
      name: "Période",
      selector: (row) => (
        <div>
          <div>{row.mission.period}</div>
        </div>
      ),
      width: "15%",
      sortable: true,
    },
    {
      name: "Résponsable",
      selector: (row) => (
        <div>
          <div>
            {row.mission.manager != null && row.mission.manager.fname}{" "}
            {row.mission.manager != null && row.mission.manager.lname}
          </div>
        </div>
      ),
      width: "13%",
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="row col-12">
            <button
              className="col-3 btn text-primary me-2"
              onClick={() =>
                setAddMissionModal(
                  <EditMission
                    setAddMissionModalFromChild={setAddMissionModal}
                    missionId={row.mission._id}
                    getMissions={getMission}
                  />
                )
              }
            >
              <i className="bi bi-pen"></i>
            </button>
            <button
              className="col-3 btn text-primary me-2 btn"
              onClick={() => {
                enableDisableMission(row.mission._id, row.mission.isEnabled);
              }}
            >
              {row.mission.isEnabled === "yes" ? (
                <i className="text-success bi bi-toggle2-on"></i>
              ) : (
                <i className="text-danger bi bi-toggle2-off"></i>
              )}
            </button>

            <Link
              className="col-3 btn text-primary me-2"
              to={`/missions/details/${row.mission._id}`}
            >
              <i className="ri-arrow-right-s-fill"></i>
            </Link>
          </div>
        );
      },
      width: "15%",
    },
  ];
  useEffect(() => {
    getDetailsEmployee();
  }, []);

  return (
    <div>
      <div className="pagetitle row">
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-8">
              <h1>Détails de l'employé</h1>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <Link to="/process">
            <button
              type="button"
              className="btn btn-outline-secondary mb-2 float-end  me-2"
            >
              Retour
            </button>
          </Link>
        </div>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/employees">Les employés</Link>
            </li>
          </ol>
        </nav>
      </div>

      <section className="section profile">
        <div className="row">
          <div className="col-xl-4">
            <div className="card">
              <div className="card-body profile-card pt-4 d-flex flex-column align-items-center text-uppercase ">
                <img
                  src={employee != null && employee.avatar}
                  alt="Profile"
                  className="rounded-circle"
                />
                <h2 className="text-center">
                  {employee != null && employee.fname}{" "}
                  {employee != null && employee.lname}
                </h2>
                <div className="row text-lowercase col-md-12 mt-4 flex">
                  <div className="row">
                    <a
                      href={`mailto:${employee.email}`}
                      className="color-jcit "
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Envoyer mail"
                    >
                      {" "}
                      <i className="bi bi-envelope-fill"></i>{" "}
                      {employee != null && employee.email}
                    </a>
                    <div
                      className="col-md-10"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Envoyer mail"
                    ></div>
                  </div>

                  <div className="row">
                    <a
                      href={`tel:+${employee.phone}`}
                      className="color-jcit "
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Appeler"
                    >
                      {" "}
                      <i className="bi bi-telephone-fill"></i>{" "}
                      {employee != null && employee.phone}
                    </a>
                  </div>

                  <div className="row">
                    <div className="col-md-1 label">
                      <i className="bi bi-geo-alt  me-1"></i>
                    </div>
                    <div className="col-md-10">
                      {employee != null && employee.address}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-1 label">
                      <i className=" bi bi-briefcase me-1"></i>
                    </div>
                    <div className="col-md-10">
                      {employee != null && employee.job} ({employee.experience}
                      ans)
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-1 label">
                      <i className=" bi bi-gift me-1"></i>
                    </div>
                    <div className="col-md-10">
                      {employee != null && employee.birthday}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="card">
              <div className="card-body pt-3">
                <h5 className="card-title">Compétences : </h5>
                <p className="small fst-italic">
                  {employee != null && employee.skills}
                </p>

                <h5 className="card-title">Détails de l'employé : </h5>
                <div className="row col-md-12">
                  <div className="row col-md-12 flex">
                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-jcit">
                        Dernier diplome obtenue
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null && employee.diplome}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-jcit">
                        Année de diplome
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null && employee.diplomeDate}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-jcit">
                        Type de l'employé
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null && employee.type}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-jcit">
                        NIRPP
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null && employee.nirpp}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-jcit">
                        Ville
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee.city != null && employee.city.name}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-jcit">
                        Code postal
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null && employee.cp}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-jcit">
                        Type de contrat
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null && employee.typeOfContract}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-jcit">
                        Date début de travail
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null && employee.startDate}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-jcit">
                        Salaire
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null && employee.salary}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-jcit">
                        <h5 className="card-title-candidat color-jcit ">
                          Langue
                        </h5>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <p className="small ">
                          {employee.langues != null &&
                            JSON.parse(employee.langues).map((lang, key) => {
                              return (
                                <span
                                  key={key}
                                  className="badge bg-secondary me-2"
                                >
                                  {lang}
                                </span>
                              );
                            })}
                        </p>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-jcit">
                        Genre
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null &&
                        employee.gender &&
                        employee.gender === "male"
                          ? "Homme"
                          : employee.gender === "femele"
                          ? "Femme"
                          : employee.gender === "other"
                          ? "Autre"
                          : ""}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-jcit">
                        Lieu de naissance
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null && employee.birthPlace}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-jcit">
                        Etat civil
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null &&
                        employee.status &&
                        employee.status === "single"
                          ? "Célibataire"
                          : employee.status === "married"
                          ? "Marié"
                          : employee.status === "in_relationship"
                          ? "En Couple"
                          : employee.status === "divorced"
                          ? "Divorcé"
                          : "Pacsé"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="pagetitle">
        <h1>Les Mission</h1>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">Mission</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4">
            <button
              type="button"
              className="btn btn-primary btn-rounded float-end text-sm"
            >
              <i className="bi bi-plus-lg"></i> Ajouter une mission
            </button>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header d-flex justify-content-end">
                <div className="col-md-3"></div>
              </div>
              <div className="card-body  pt-3">{missionData}</div>
            </div>
          </div>
          {addMissionModal}
        </div>
      </section>
    </div>
  );
};

export default EmployeesDetails;
