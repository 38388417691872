import React from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
function UpdateExperience({
  setAddExperienceModalFromChild,
  getExperienceByCandidatId,
  experienceId,
  candidatId,
}) {
  const [company, setCompany] = React.useState();
  const [post_title, setPost_title] = React.useState();
  const [sector, setSector] = React.useState();
  const [description, setDescription] = React.useState();
  const [contract_type, setContract_type] = React.useState();
  const [address, setAdresse] = React.useState();
  const [start_date, setStart_date] = React.useState();
  const [end_date, setEnd_date] = React.useState();

  const initFormErrors = {
    companyname: [""],
    titlePoste: [""],
    secteur: [""],
    description: [""],
    typeContrat: [""],
    adress: [""],
    date_deb: [""],
    date_fin: [""],
  };
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  React.useEffect(() => {
    axios.get(`${baseURL}/experience/${experienceId}`).then((resp) => {
      if (resp.data.error) return;
      const data = resp.data.data;
      setCompany(data.company);
      setPost_title(data.post_title);
      setSector(data.sector);
      setDescription(data.description);
      setContract_type(data.contract_type);
      setAdresse(data.address);
      setStart_date(data.start_date);
      setEnd_date(data.end_date);
    });
  }, []);
  function sendData() {
    axios
      .put(`${baseURL}/experience/${experienceId}`, {
        contract_type: contract_type,
        post_title: post_title,
        company: company,
        sector: sector,
        address: address,
        description: description,
        start_date: start_date,
        end_date: end_date,
        candidat: candidatId,
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getExperienceByCandidatId();
        setAddExperienceModalFromChild("");
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }

  return (
    <div>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6 mt-2">
                  {" "}
                  <label> titre de poste *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="titre de post"
                    value={post_title != null && post_title}
                    onChange={(e) => setPost_title(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.post_title}</span>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <label>Type de contrat *</label>
                  <select
                    className="form-select"
                    name="Type de contrat"
                    value={contract_type != null && contract_type}
                    onChange={(e) => setContract_type(e.target.value)}
                  >
                    <option selected></option>
                    <option value="fulltime">À plein temps</option>
                    <option value="parialtime">temps partiel</option>
                    <option value="independent">indépendant</option>
                    <option value="freelancer">freelancer</option>
                    <option value="cdd">cdd</option>
                    <option value="internship">stage</option>
                    <option value="alternation">alternance</option>
                    <option value="seasonal">saisonnier</option>
                  </select>
                  <div className="input-error">
                    <span className="text-danger">
                      {" "}
                      {formerrors.contract_type}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                {" "}
                <div className="col-md-6 mt-2">
                  <label> Nom de société *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyName"
                    value={company != null && company}
                    onChange={(e) => setCompany(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.company}</span>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <label> Secteur *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="secteur"
                    value={sector != null && sector}
                    onChange={(e) => setSector(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.sector} </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-2">
                  <label> Adresse *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Address"
                    value={address != null && address}
                    onChange={(e) => setAdresse(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.address}</span>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <label> Déscription *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="déscription"
                    value={description != null && description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {formerrors.description}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                {" "}
                <div className="col-md-6 mt-2">
                  <label> Date de début *</label>
                  <input
                    type="date"
                    className="form-control"
                    id="date_d"
                    value={start_date != null && start_date}
                    onChange={(e) => setStart_date(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.start_date}</span>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <label> Date de fin *</label>
                  <input
                    type="date"
                    className="form-control"
                    id="date_f"
                    value={end_date != null && end_date}
                    onChange={(e) => setEnd_date(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.end_date}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setAddExperienceModalFromChild("")}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={sendData}
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default UpdateExperience;
