import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
const initFormErrors = {
  name: [""],
  etape: [""],
};

function CreateProcess({ setAddProcessModalFromChild, getAllProcess }) {
  const [processName, setProcessName] = useState([]);
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  const [processSteps, setProcessSteps] = useState([""]);
  const [messageErreur, setMessageErreur] = useState([]);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const list = [...processSteps];
    list[index] = value;
    setProcessSteps(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...processSteps];
    list.splice(index, 1);
    setProcessSteps(list);
  };

  const handleAddClick = () => {
    setProcessSteps([...processSteps, ""]);
  };

  function sendData() {
    axios
      .post(`${baseURL}/process/create`, {
        name: processName,
        steps: JSON.stringify(processSteps),
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getAllProcess();
        setAddProcessModalFromChild("");
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }

  function validate() {
    let error = false;
    let errorsArray = [];
    processSteps.map((value, index) => {
      if (value === "") {
        error = true;
        errorsArray.push("Ce champs est obligatoire");
      } else {
        errorsArray.push("");
      }
    });
    if (error == false) {
      sendData();
    }
    setMessageErreur(errorsArray);
  }

  return (
    <div>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="d-flex fw-bold justify-content-center mb-5">
                Créer un process
              </h3>
              <div className="row">
                <div className="col-md-10 mb-3">
                  <input
                    type="text"
                    name="ProcessName"
                    className="form-control"
                    placeholder="Nom de process"
                    onChange={(e) => setProcessName(e.target.value)}
                  />{" "}
                  <div className="input-error">
                    <span className="text-danger"> {formerrors.name}</span>
                  </div>
                </div>

                {processSteps.map((step, key) => {
                  return (
                    <div className="row" key={key}>
                      <div className="col-md-11 mb-3">
                        {" "}
                        <input
                          type="text"
                          className="form-control"
                          name="Etape"
                          value={step}
                          onChange={(e) => handleInputChange(e, key)}
                          placeholder={`Etape ${key + 1}`}
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {messageErreur[key] != null && messageErreur[key]}
                          </span>
                        </div>
                        {processSteps.length - 1 === key && (
                          <button
                            type="button"
                            onClick={handleAddClick}
                            className="btn button_steps_bgradd ms-2"
                          >
                            <i className="bi bi-plus button_steps_add">
                              {" "}
                              ajouter autre etape
                            </i>
                          </button>
                        )}
                      </div>
                      <div className="col-md-1">
                        {" "}
                        {processSteps.length !== 1 && (
                          <button
                            type="button"
                            className="btn button_steps_bgrdash p-1"
                            onClick={() => handleRemoveClick(key)}
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-4"> </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="modal-footer mt-4">
                <button
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                  onClick={() => setAddProcessModalFromChild("")}
                >
                  Annuler
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={validate}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}
export default CreateProcess;
