import axios from "axios";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { baseURL } from "../../config/config";
import swal from "sweetalert";
const SupplierDetails = (get) => {
  const [supplier, setSupplier] = React.useState([]);
  const { supplierId } = useParams();
  async function getDetailsSupplier() {
    if (supplierId === undefined) return;
    axios
      .get(`${baseURL}/employees/${supplierId}`)
      .then((response) => {
        if (response.data.error) return;
        setSupplier(response.data.data);
      })
      .catch((err) => {});
  }
  async function enableDisable(supplierId, isEnabled) {
    let message =
      isEnabled == "yes"
        ? "voulez vous Désactiver ce fournisseur?"
        : "Voulez vous Activer ce fournisseur?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/employees/toggleStatus/${supplierId}`, {})
          .then((resp) => {
            getDetailsSupplier();
          });
      }
    });
  }

  useEffect(() => {
    getDetailsSupplier();
  }, []);

  let tel = `tel:+${supplier.phone}`;
  let mailto = `mailto:${supplier.email}`;
  return (
    <div>
      <div className="pagetitle row">
        <div className="col-md-8">
          <h1>Détails de fournisseur</h1>
        </div>
        <div className="col-md-4">
          <Link to="/supplier">
            <button
              type="button"
              className="btn btn-outline-secondary mb-2 float-end  me-2"
            >
              Retour
            </button>
          </Link>
        </div>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/supplier">Les fournisseurs</Link>
            </li>
          </ol>
        </nav>
      </div>
      <section className="section profile">
        <div className="row">
          <div className="col-md-5">
            <fieldset className="border_supplier_details p-4 mt-4">
              <div className="profile-card">
                <div className="row">
                  <div className="col-lg-9">
                    {" "}
                    <h4 className="fw-bold color-jcit">
                      {supplier != null && supplier.fname}
                      {"  "}
                      {supplier != null && supplier.lname}
                    </h4>
                  </div>
                  <div className="col-md-3 d-flex justify-content-end">
                    <div className="col-md-6 d-flex justify-content-end">
                      <div>
                        <Link to={`/supplier/edit/${supplierId}`}>
                          <button type="button" className="btn text-primary">
                            <i
                              className="bi bi-pen"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Modifier fournisseur"
                            ></i>
                          </button>
                        </Link>
                        <button
                          className="col-3 btn text-primary me-2 btn"
                          onClick={() => {
                            enableDisable(supplier._id, supplier.isEnabled);
                          }}
                        >
                          {supplier.isEnabled == "yes" ? (
                            <i
                              className="text-success bi bi-toggle2-on"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Désactiver fournisseur"
                            ></i>
                          ) : (
                            <i
                              className="text-danger bi bi-toggle2-off"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Activer fournisseur"
                            ></i>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    {" "}
                    <img
                      src={supplier != null && supplier.avatar}
                      className="rounded-circle"
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="row text-lowercase mt-4 flex">
                      <div className="row">
                        <div className="col-md-1 label ">
                          <i className="bi-envelope me-1"></i>
                        </div>
                        <div className="col-md-10">
                          <a
                            className="color-jcit"
                            href={mailto}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Envoyer mail"
                          >
                            {supplier != null && supplier.email}
                          </a>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-1 label ">
                          <i className="bi-telephone me-1"></i>
                        </div>
                        <div className="col-md-10">
                          <a
                            className="color-jcit"
                            href={tel}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Appeler"
                          >
                            {supplier != null && supplier.phone}
                          </a>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-1 label">
                          <i className="bi bi-geo-alt  me-1"></i>
                        </div>
                        <div className="col-md-10">
                          {supplier != null && supplier.address}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-1 label">
                          <i className=" bi bi-briefcase me-1"></i>
                        </div>
                        <div className="col-md-10">
                          {supplier != null && supplier.job} (
                          {supplier.experience}
                          ans)
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-1">
                          <i className=" bi bi-gift"></i>
                        </div>
                        <div className="col-md-9">
                          {supplier != null && supplier.birthday}(
                          {supplier.birthPlace})
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col-sm-12">
                <fieldset className="border_supplier_details p-4">
                  <legend className="float-none w-auto p-2">
                    <h6 className="f-w-600 color-jcit">Information</h6>
                  </legend>
                  <div className="card1-block">
                    <div className="row">
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-jcit">Email</p>
                        <h6 className="text-muted f-w-400">
                          {supplier != null && supplier.email}
                        </h6>
                      </div>
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-jcit">Phone</p>
                        <h6 className="text-muted f-w-400">
                          {supplier != null && supplier.phone}
                        </h6>
                      </div>
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-jcit">Etat civil</p>
                        <h6 className="text-muted f-w-400">
                          {supplier != null && supplier.status}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-jcit">Salaire</p>
                        <h6 className="text-muted f-w-400">
                          {supplier != null && supplier.salary}
                        </h6>
                      </div>
                      <div className="col-md-4">
                        <h5 className="card-title-candidat color-jcit ">
                          Langue
                        </h5>
                        <p className="small ">
                          {supplier.langues != null &&
                            JSON.parse(supplier.langues).map((lang, key) => {
                              return (
                                <span
                                  key={key}
                                  className="badge bg-secondary me-2"
                                >
                                  {lang}
                                </span>
                              );
                            })}
                        </p>
                      </div>
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-jcit">Diplome</p>
                        <h6 className="text-muted f-w-400">
                          {supplier != null && supplier.diplome}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-jcit">address</p>
                        <h6 className="text-muted f-w-400">
                          {supplier != null && supplier.address}
                        </h6>
                      </div>
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-jcit">ville</p>
                        <h6 className="text-muted f-w-400">
                          {supplier.city != null && supplier.city.name}
                        </h6>
                      </div>
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-jcit">poste</p>
                        <h6 className="text-muted f-w-400">
                          {supplier != null && supplier.job}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-jcit">Expérience</p>
                        <h6 className="text-muted f-w-400">
                          {supplier != null && supplier.experience}
                        </h6>
                      </div>
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-jcit">Code postal</p>
                        <h6 className="text-muted f-w-400">
                          {supplier != null && supplier.cp}
                        </h6>
                      </div>
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-jcit">
                          type de contrat
                        </p>
                        <h6 className="text-muted f-w-400">
                          {supplier != null && supplier.typeOfContract}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-jcit">NIRPP</p>
                        <h6 className="text-muted f-w-400">
                          {supplier != null && supplier.nirpp}
                        </h6>
                      </div>
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-jcit">type</p>
                        <h6 className="text-muted f-w-400">
                          {supplier != null &&
                          supplier.type &&
                          supplier.type == "supplier"
                            ? "fournisseur"
                            : ""}
                        </h6>
                      </div>
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-jcit">
                          Date de début
                        </p>
                        <h6 className="text-muted f-w-400">
                          {supplier != null && supplier.startDate}
                        </h6>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SupplierDetails;
