import React, { useContext, useState } from "react";
import EmployeesFormStep2 from "./EmployeesFormStep2";
import { EmployeeContext } from "../../pages/employee/createEmployees";
import EmployeesFormStep4 from "./EmployeesFormStep4";
import Select from "react-select";

const initFormErrors = {
  diplome: [""],
  annéeGraduation: [""],
  type: [""],
  langues: [""],
  skills: [""],
  experience: [""],
};

const EmployeesFormStep3 = ({ setChildComponent }) => {
  const msg = "Ce champ est obligatoire";
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const { newEmployee, setNewEmployee } = useContext(EmployeeContext);

  function validate() {
    let error = false;
    error = false;
    setErrorMessage(initFormErrors);
    if (newEmployee.experience == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        experience: msg,
      }));
    }
    if (newEmployee.skills == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        skills: msg,
      }));
    }
    if (newEmployee.langues == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        langues: msg,
      }));
    }
    if (newEmployee.diplome == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        diplome: msg,
      }));
    }
    if (newEmployee.type == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        type: msg,
      }));
    }
    if (error === false) {
      setChildComponent(
        <EmployeesFormStep4 setChildComponent={setChildComponent} />
      );
    }
  }
  const languesOptions = [
    { value: "français", label: "Français" },
    { value: "english", label: "Anglais" },
    { value: "arabic", label: "Arabe" },
    { value: "spanish", label: "Espagnole" },
    { value: "deutsh", label: "Allemand" },
    { value: "Italien", label: "Italien" },
  ];
  return (
    <div>
      <div className="card">
        <div className="card-body pt-5">
          <form className="row g-3">
            <div className="row">
              <div className="col-md-6">
                <label>Expérience *</label>
                <input
                  type="number"
                  value={newEmployee.experience}
                  onChange={(e) => {
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      experience: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.experience}</span>
                </div>
              </div>

              <div className="col-md-6">
                <label>
                  Compétences *{" "}
                  <span className="textSkills">separation par ( ; )</span>
                </label>
                <input
                  type="text"
                  value={newEmployee.skills}
                  onChange={(e) => {
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      skills: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <label> </label>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.skills}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label>langues *</label>
                <Select
                  options={languesOptions}
                  isMulti
                  onChange={(e) => {
                    let langues = [];
                    e.forEach((lang, key) => {
                      langues.push(lang.value);
                    });
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      langues: JSON.stringify(langues),
                    }));
                  }}
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.langues}</span>
                </div>
              </div>

              <div className="col-md-6 pb-3">
                <label>Dernier diplome obtenue *</label>
                <input
                  type="text"
                  value={newEmployee.diplome}
                  onChange={(e) => {
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      diplome: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.diplome}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label>Année de diplome *</label>
                <input
                  type="number"
                  value={newEmployee.diplomeDate}
                  onChange={(e) => {
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      diplomeDate: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">
                    {errorMessage.diplomeDate}
                  </span>
                </div>
              </div>

              <div className="col-md-6">
                <label>Type de l'employé *</label>
                <select
                  className="form-select"
                  value={newEmployee.type}
                  onChange={(e) => {
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      type: e.target.value,
                    }));
                  }}
                  name="type"
                >
                  <option selected></option>
                  <option value="supplier">Fournisseur</option>
                  <option value="employee">Employé</option>
                </select>

                <div className="input-error">
                  <span className="text-danger">{errorMessage.type}</span>
                </div>
              </div>
            </div>
            <div className="text-end">
              <button
                type="button"
                className="btn btn-outline-secondary  me-2"
                onClick={() => {
                  setChildComponent(
                    <EmployeesFormStep2 setChildComponent={setChildComponent} />
                  );
                }}
              >
                Retour
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={validate}
              >
                Continuer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmployeesFormStep3;
