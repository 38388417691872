import React from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";

function EditManager({
  setAddManagerModalFromChild,
  getManagerByCompanyId,
  managerId,
}) {
  const [lastName, setLastName] = React.useState();
  const [firstName, setFirstName] = React.useState();
  const [phone, setPhone] = React.useState();
  const [email, setEmails] = React.useState();
  const [designation, setDesignation] = React.useState();
  const [company, setCompany] = React.useState();
  const initFormErrors = {
    lname: [""],
    fname: [""],
    email: [""],
    phone: [""],
    designation: [""],
  };
  const [formerrors, setFormErrors] = React.useState(initFormErrors);

  React.useEffect(() => {
    axios.get(`${baseURL}/managers/details/${managerId}`).then((resp) => {
      if (resp.data.error) return;
      const data = resp.data.data;
      setFirstName(data.fname);
      setLastName(data.lname);
      setPhone(data.phone);
      setEmails(data.email);
      setDesignation(data.designation);
      setCompany(data.company);
    });
  }, []);

  function sendData() {
    axios
      .put(`${baseURL}/managers/${managerId}`, {
        fname: firstName,
        lname: lastName,
        phone: phone,
        email: email,
        designation: designation,
        company_id: company,
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getManagerByCompanyId();
        setAddManagerModalFromChild("");
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }
  return (
    <div>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col-md-12 mt-2">
                <input
                  type="text"
                  className="form-control"
                  id="lname"
                  placeholder="Nom"
                  value={lastName != null && lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.lname}</span>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <input
                  type="text"
                  className="form-control"
                  id="fname"
                  placeholder="Prénom"
                  value={firstName != null && firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.fname}</span>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <input
                  type="number"
                  className="form-control"
                  id="phone"
                  placeholder=" Téléphone"
                  value={phone != null && phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.phone}</span>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email"
                  value={email != null && email}
                  onChange={(e) => setEmails(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.email}</span>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <input
                  type="text"
                  className="form-control"
                  id="designation"
                  placeholder="Désignation"
                  value={designation != null && designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.designation}</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setAddManagerModalFromChild("")}
              >
                Annuler
              </button>
              <button
                type="button"
                onClick={sendData}
                className="btn btn-primary"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default EditManager;
